import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-show-help',
  templateUrl: './show-help.component.html',
  styleUrls: ['./show-help.component.scss'],
})
export class ShowHelpComponent implements OnInit {
  @Input() text: string;
  @Input() linkText: string;
  @Input() link: string;

  constructor() { }

  ngOnInit() {}

}
