import { Component, Input, OnInit } from '@angular/core';
import { AuthUser } from '@medmonitor/authlib';
import { TranslateService } from '@ngx-translate/core';
import { Subscription } from 'rxjs';
import { CommonNewDocumentComponent } from '../../common-new-document/common-new-document.component';
import { AlertController, LoadingController, ModalController } from '@ionic/angular';
import { TitleInfo } from '../../model/title-info';
import { TitleService } from '../../services/data.service';
import { FileShowService } from '../../services/file-show.service';
import { FileUploadService } from '../../services/file-upload.service';
import { NiceDate } from '../../util/dateutil';
import { SafeImage } from '../../util/safe-image';

@Component({
  selector: 'app-edituser',
  templateUrl: './edituser.component.html',
  styleUrls: ['./edituser.component.scss']
})
export class EdituserComponent implements OnInit {
  @Input() tenant: string;
  @Input() user: AuthUser;
  @Input() disableAdmin: boolean;
  @Input() short = false;
  fileDates: string[] = [];
  path: string;
  titles: TitleInfo[];
  private _sub: Subscription;

  constructor(private modalCtrl: ModalController,
    private loadingCtrl: LoadingController,
    private alertCtrl: AlertController,
    public translate: TranslateService,
    private fileUploads: FileUploadService,
    private titleService: TitleService,
    private showFile: FileShowService) {
  }

  ngOnInit() {
    this.path = `${ this.user.tenant }/users/`;
    if (!!this.user.docDescs && this.user.docDescs.length > 0) {
      this.getDates().then();
    }
    this._sub = this.titleService.data.subscribe(t => {
      this.titles = t;
    });
    this.fileUploads.setCurrentData(this.user.docs, this.user.docDescs);
    this.fileUploads.setUploadPath(this.path);
  }

  onCancel() {
    this.modalCtrl.dismiss(null, 'cancel').then();
  }

  onDelete() {
    this.modalCtrl.dismiss(null, 'delete').then();
  }

  onSave() {
    // user data is bound already
    this.modalCtrl.dismiss(null, 'save').then();
  }

  onDeleteDoc(i: number) {
    this.alertCtrl.create({
      header: this.translate.instant('Delete'),
      message: this.translate.instant('Delete Document') + '?',
      buttons: [{text: this.translate.instant('Yes'), role: 'yes'},
        {text: this.translate.instant('No'), role: 'no'}]
    }).then(al => {
      al.present().then();
      return al.onDidDismiss();
    }).then(res => {
      if (res.role === 'yes') {
        this.fileUploads.deleteFile(this.user.docs[i], i);
        this.user.docs = this.fileUploads.getCurrentNames();
        this.user.docDescs = this.fileUploads.getCurrentDescriptions();
        this.fileDates.splice(i, 1);
      }
    });
  }

  newDocument() {
    this.modalCtrl.create({
      component: CommonNewDocumentComponent,
      canDismiss: true
    }).then(el => {
      el.present().then();
      return el.onDidDismiss();
    }).then(() => {
      this.user.docs = this.fileUploads.getCurrentNames();
      this.user.docDescs = this.fileUploads.getCurrentDescriptions();
      this.fileDates.push(this.translate.instant('New Document'));
    }).catch(err => {
      console.log('ERROR', err);
    });
  }

  async showLink(i: number) {
    if (this.fileDates[i] !== 'ERROR' && this.fileDates[i] !== this.translate.instant('New Document')) {
      SafeImage.OpenFile(this.path + this.user.docs[i], this.loadingCtrl, this.showFile);
    }
  }

  async getDates() {
    this.fileDates = [];
    for (const doc of this.user.docs) {
      try {
        const dateStr = await this.fileUploads.getFileUpdated(this.path + doc);
        const d = new Date(dateStr);
        this.fileDates.push(NiceDate(d));
      } catch (err) {
        console.log(err);
        this.fileDates.push('ERROR');
      }
    }
  }

  getAddDoc() {
    let order = true;
    if (this.translate.currentLang === 'en') {
      order = false;
    }
    const doc = this.translate.instant('Document');
    const add = this.translate.instant('Add');
    if (order) {
      return doc + ' ' + add;
    } else {
      return add + ' ' + doc;
    }
  }

  getFileDate(i: number) {
    return this.fileDates[i];
  }
}
