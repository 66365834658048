import { ChangeDetectorRef, Component, Input, OnInit } from '@angular/core';
import { DocListComponent } from '../documents/doc-list/doc-list.component';
import { Doc, DocKind } from '../model/document';

@Component({
  selector: 'app-doc-selection',
  templateUrl: './doc-selection.component.html',
  styleUrls: ['./doc-selection.component.scss'],
})
export class DocSelectionComponent implements OnInit {
  @Input() docSelection: Map<string, boolean>;
  @Input() docs: Doc[];
  allSelected = false;

  constructor(private ref: ChangeDetectorRef) { }

  ngOnInit() {}

  toggle(lid: string) {
    this.docSelection.set(lid, !this.docSelection.get(lid));
    this.ref.markForCheck();
    this.ref.detectChanges();
  }

  all() {
    this.allSelected = !this.allSelected;
    for (const key of this.docSelection.keys()) {
      this.docSelection.set(key, this.allSelected);
    }
  }

  getIcon(type: DocKind) {
    return DocListComponent.ICON_MAP.get(type);
  }
}

