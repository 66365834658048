import { ChangeDetectorRef, Component, OnDestroy, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { OfficeStatusService } from '../services/officestatus.service';
import { AlertController } from '@ionic/angular';
import { OfficeStatus } from '../model/office-status';
import { Subscription } from 'rxjs';
import { ListsService } from '../services/lists.service';
import { format } from 'date-fns';
import { ShowAlert } from '../util/show-alert';

@Component({
  selector: 'app-datestatus',
  templateUrl: './datestatus.component.html',
  styleUrls: ['./datestatus.component.scss']
})
export class DateStatusComponent implements OnInit, OnDestroy {
  statusChange = 'open';
  officeNextStatus: string;
  nextStatus: OfficeStatus;
  userSub: Subscription;
  private _nOpen: number;
  private _nClose: number;
  private _showAlert: ShowAlert;
  private _nOpenAlerted: number;
  private _nCloseAlerted: number;

  constructor(public translate: TranslateService,
    private statusService: OfficeStatusService,
    private listService: ListsService,
    private ref: ChangeDetectorRef,
    private alertCtrl: AlertController) {
    this._showAlert = new ShowAlert(alertCtrl, translate);
  }

  ngOnInit() {
    this.userSub = this.statusService.status.subscribe(stat => {
      if (stat) {
        this.statusChange = stat;
        this.officeStatusChange();
        this.ref.detectChanges();
      }
    });
    this.userSub.add(this.listService.lists.subscribe(lists => {
      if (!!lists) {
        this._nOpen = 0;
        this._nClose = 0;
        for (const l of lists) {
          if (l.type === 'open') {
            this._nOpen++;
            this._nOpenAlerted += l.alert ? 1 : 0;
          } else if (l.type === 'close') {
            this._nClose++;
            this._nCloseAlerted += l.alert ? 1 : 0;
          }
        }
      }
    }));
  }

  ngOnDestroy() {
    this.userSub?.unsubscribe();
  }

  async changeStatus() {
    // status only available if we do have open/close lists.
    if (this._nOpen === 0 || this._nClose === 0) {
      await this._showAlert.withHeader('No open/close list').withMessage('OpenCloseMsg')
        .addOkButton().show();
      return;
    }
    if (this.nextStatus === 'opening' || this.nextStatus === 'closing') {
      const choice = await this._showAlert.withHeader(this.nextStatus === 'opening' ? 'Open Office' : 'Close Office')
        .withMessage('Are you certain?')
        .addButton(this.nextStatus === 'opening' ? 'Yes, open' : 'Yes, close', 'yes', 'btnalert')
        .addNoButton()
        .show();
      if (choice === 'yes') {
        this.statusService.setStatus(this.nextStatus);
      }
    } else {
      if ((this.nextStatus === 'open' && this._nOpenAlerted === 0) ||
        (this.nextStatus === 'closed' && this._nCloseAlerted === 0)) {
        const choice = await this._showAlert.withHeader(this.nextStatus === 'open' ? 'Force Open' : 'Force Close')
        .withMessage('Are you certain?')
        .addButton('Yes', 'yes', 'btnalert')
        .addNoButton()
        .show();
        if (choice === 'yes') {
          this.statusService.setStatus(this.nextStatus);
        }
      } else {
        await this._showAlert.withHeader('Work on Lists').withMessage('DoOpenCloseOnTabletMsg')
        .addOkButton().show();
      }
    }
  }

  getDate() {
    return format(new Date(), 'PPP');
  }

  private officeStatusChange() {
    if (this.statusChange === 'open') {
      this.officeNextStatus = 'Close Office';
      this.nextStatus = 'closing';
    } else if (this.statusChange === 'closed') {
      this.officeNextStatus = 'Open Office';
      this.nextStatus = 'opening';
    } else {
      this.officeNextStatus = 'Complete Lists';
      this.nextStatus = this.statusChange === 'opening' ? 'open' : 'closed';
    }
  }
}
