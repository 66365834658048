import { ChangeDetectorRef, Component, OnDestroy, OnInit } from '@angular/core';
import { AuthService } from '@medmonitor/authlib';
import { Tablet, TabletUtil } from '../model/tablet';
import { TabletService } from '../services/tablet.service';
import { Subscription } from 'rxjs';
import { ListComponent } from '../lists/list/list.component';
import { ListsService } from '../services/lists.service';
import { ModalController } from '@ionic/angular';
import { TabletlistComponent } from './tabletlist/tabletlist.component';

@Component({
  selector: 'app-tabletinfo',
  templateUrl: './tabletinfo.component.html',
  styleUrls: ['./tabletinfo.component.scss']
})
export class TabletinfoComponent implements OnInit, OnDestroy {
  tablets: Tablet[];
  isAdmin = false;
  private _listData = new Map<string, { name: string; type: string; icon: string; order: string }>();
  private _sub: Subscription;
  private _listSub: Subscription;

  constructor(private tabletService: TabletService,
    private listService: ListsService,
    private ref: ChangeDetectorRef,
    private modalCtrl: ModalController,
    private authService: AuthService) {
  }

  ngOnInit() {
    this._sub = this.tabletService.data.subscribe(d => {
      // Alphabetic sorting
      if (!!d) {
        this.tablets = d.sort((a, b) => a.name.localeCompare(b.name));
        this.ref.markForCheck();
        this.ref.detectChanges();
        this._listSub = this.listService.lists.subscribe(l => {
          if (!!l && l.length > 0) {
            l.forEach(el => {
              this._listData.set(el.id,
                {
                  name: el.name,
                  type: el.type,
                  icon: ListComponent.ICON_MAP.get(el.type),
                  order: `${ ListComponent.ORDER_OF_TYPE.get(el.type) }${ el.name }`
                });
            });
            this.tablets.forEach(tablt => {
              TabletUtil.FilterInvalidIDs(tablt, [...this._listData.keys()]);
              tablt.listIds?.sort((a, b) => this._listData.get(a).order.localeCompare(this._listData.get(b).order));
            });
            this.ref.markForCheck();
            this.ref.detectChanges();
          }
        });
      }
    });
    this._sub.add(this.authService.user.subscribe(u => {
      if (!!u) {
        this.isAdmin = this.authService.isAdmin;
      }
    }));
  }

  ngOnDestroy() {
    this._sub?.unsubscribe();
    this._listSub.unsubscribe();
  }

  view(tablet: Tablet) {
    this.modalCtrl.create({
      component: TabletlistComponent,
      canDismiss: true,
      componentProps: {listData: this._listData, tablet}
    }).then(el => {
      el.present().then();
      return el.onDidDismiss();
    }).then()
    .catch();
  }
}
