import { Component, OnDestroy, OnInit } from '@angular/core';
import { FileUploadService } from '../services/file-upload.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-common-data-upload-progress',
  templateUrl: './common-data-upload-progress.component.html',
  styleUrls: ['./common-data-upload-progress.component.scss']
})
export class CommonDataUploadProgressComponent implements OnInit, OnDestroy {
  progress = 0.0;
  fileName = '';
  private _numberOfFiles = 0;
  private _countFiles = 0;
  private _sub: Subscription;

  constructor(private fileUploads: FileUploadService) { }

  ngOnInit() {
    this._numberOfFiles = this.fileUploads.numberOfFilesToUpload;
    this._sub = this.fileUploads.progressUpload.subscribe(x => {
      if (!!x.name) {
        this.fileName = x.name.substring(x.name.indexOf('_') + 1); // strip random id component
        this.progress = (this._countFiles + x.progress / 100.0) / this._numberOfFiles;
        if (x.progress === 100) {
          this._countFiles += 1;
        }
      }
    });
  }

  ngOnDestroy() {
    if (this._sub) {
      this._sub.unsubscribe();
    }
  }
}
