import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { AlertController, ModalController, PopoverController } from '@ionic/angular';
import { AuthService, AuthUser } from '@medmonitor/authlib';
import { TranslateService } from '@ngx-translate/core';
import { FileUploadService } from '../../services/file-upload.service';
import { Subscription } from 'rxjs';
import { DocumentData } from '../../model/document-data';
import { Doc, DocVersion } from '../../model/document';
import {
  CommonDataUploadProgressComponent
} from '../../common-data-upload-progress/common-data-upload-progress.component';

@Component({
  selector: 'app-doc-upload',
  templateUrl: './doc-upload.component.html',
  styleUrls: ['./doc-upload.component.scss']
})
export class DocUploadComponent implements OnInit, OnDestroy {
  static FILE_PATH = '/documents/';
  @Input() doc: Doc;
  @Input() isVersion = false;
  isLink: boolean;
  link: string;
  file: any;
  sub: Subscription;
  user: AuthUser;
  version: DocVersion;

  constructor(private modalCtrl: ModalController,
    private alertCtrl: AlertController,
    private fileUploadService: FileUploadService,
    private authService: AuthService,
    private popoverCtrl: PopoverController,
    private translate: TranslateService) { }

  ngOnInit() {
    this.isLink = this.doc.kind === 'Link';
    if (!this.isVersion) {
      this.version = this.doc.versions[0];
    } else {
      this.version = new DocVersion();
    }
    this.sub = this.authService.user.subscribe(u => {
      this.user = u;
    });
  }

  ngOnDestroy() {
    this.sub?.unsubscribe();
  }

  onCancel() {
    this.modalCtrl.dismiss().then();
  }

  onSave() {
    if (this.isLink) {
      this.version.data = this.link;
      this.dismiss();

    } else { // FILE
      this.fileUploadService.reset();
      if (this.doc.catalog) {
        this.fileUploadService.setUploadPath(DocUploadComponent.FILE_PATH);
      } else {
        this.fileUploadService.setUploadPath(`/${ this.user.tenant }` + DocUploadComponent.FILE_PATH);
      }
      const fileToUpload = new DocumentData();
      fileToUpload.file = this.file;
      this.fileUploadService.uploadNewFile(fileToUpload, this.version.data);

      let bar = null;
      this.popoverCtrl.create({
        component: CommonDataUploadProgressComponent
      }).then(el => {
        bar = el;
        return el.present();
      }).then();

      this.fileUploadService.uploadAllFiles().then(() => {
        bar?.dismiss().then();
        this.version.data = fileToUpload.name;
        this.dismiss();
      });
    }
  }

  dismiss() {
    this.version.modified = Date.now();
    if (!this.version.created) {
      this.version.created = this.version.modified;
    }
    this.version.modifiedBy = this.user.id;
    if (!this.version.createdBy) {
      this.version.createdBy = this.version.modifiedBy;
    }
    this.modalCtrl.dismiss(this.version, 'ok').then();
  }

  isValid() {
    return (this.isLink && !!this.link) || (!this.isLink && !!this.file);
  }

  setUploadFile(event) {
    const files = event.target.files;
    if (files.length > 1) {
      this.alertCtrl.create({
        header: this.translate.instant('Too many selections'),
        message: this.translate.instant('OneFileMsg'),
        buttons: [{text: 'OK', role: 'OK'}]
      }).then(el => {
        el.present().then();
        return el.onDidDismiss();
      }).then();
      return;
    }
    if (files.length > 0) {
      this.file = files.item(0);
    }
  }

}
