import { IdentityData } from '@medmonitor/authlib';

export class Stat implements IdentityData {
  id: string;
  name?: string;
  days: {[key: string]: {[key: string]: number}};
  months: {[key: string]: {[key: string]: number}};
  weeks: {[key: string]: {[key: string]: number}};
  years: {[key: string]: {[key: string]: number}};
}

export class TotalStat implements IdentityData {
  id: string;
  name?: string;
  days: {[key: string]: number};
  months: {[key: string]: number};
  weeks: {[key: string]: number};
  years: {[key: string]: number};
}

