import { Component, OnInit } from '@angular/core';
import { StorageService } from '@medmonitor/authlib';
import { TranslateService } from '@ngx-translate/core';
import setDefaultOptions from 'date-fns/setDefaultOptions';
import { de, enUS } from 'date-fns/locale';

@Component({
  selector: 'app-langselect',
  templateUrl: './langselect.component.html',
  styleUrls: ['./langselect.component.scss']
})
export class LangselectComponent implements OnInit {
  private static STORAGE_STRING = 'MMLang';

  constructor(public translate: TranslateService,
    private storage: StorageService) { }

  ngOnInit() {
    this.storage.get(LangselectComponent.STORAGE_STRING).then(lang => {
      if (!!lang) {
        this.translate.use(lang);
        this.setDateLocale(lang);
      }
    });
  }

  changeLang(l: string) {
    this.setDateLocale(l);
    this.translate.use(l);
    this.storage.set(LangselectComponent.STORAGE_STRING, l);
  }

  setDateLocale(l: string) {
    switch (l) {
      case 'de':
        setDefaultOptions({locale: de});
        break;
      default:
        setDefaultOptions({locale: enUS});
        break;
    }
  }
}
