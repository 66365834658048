import { Injectable } from '@angular/core';
import { AuthService, AuthUser } from '@medmonitor/authlib';
import { BehaviorSubject, Subscription } from 'rxjs';
import { Log, LogEvent } from '../model/log';
import { YearMonth } from '../util/dateutil';
import { MonthloaderService } from '../report/monthloader.service';
import { DatabaseReference, Database, ref, off, onValue, onChildAdded, onChildChanged } from '@angular/fire/database';

@Injectable({
  providedIn: 'root'
})
export class LogsService {
  private static DELAYED_EXEC = null;
  private _user: AuthUser;
  private _logRef: DatabaseReference;
  private _logs = new BehaviorSubject<Log[]>(null);
  private _theLogs: Log[] = [];
  private _authSub: Subscription;


  constructor(private authService: AuthService,
              private db: Database,
              private monthLoader: MonthloaderService) {
    this._authSub = this.authService.user.subscribe(u => {
      this._user = u;
      this.cancelListeners();
      if (!!u) {
        this.initializeLog(new YearMonth());
      }
    });
  }

  public get logs() {
    return this._logs.asObservable();
  }

  cancelListeners(err = null) {
    if (!!err) {
      console.log(err);
    }
    if (this._logRef) {
      off(this._logRef);
      this._logRef = null;
    }
  }

  initializeLog(newMonth: YearMonth) {
    this.cancelListeners();
    const m = newMonth.month + 1;
    const y = newMonth.year;
    const lead0 = (n: number) => (n < 10 ? '0' : '') + n.toString();
    this._theLogs.length = 0;
    this._logs.next(this._theLogs);
    this._logRef = ref(this.db, `/logs/${this._user.tenant}/months/${y}-${lead0(m)}`);
    onValue(this._logRef, dataSnap => {
      if (!dataSnap.exists()) {
        this.monthLoader.dismiss().then();
      }
    }, {onlyOnce: true});
    onChildAdded(this._logRef, (data) => {
      this._theLogs.push(this.setLogItem(data.val(), data.key));
      if (LogsService.DELAYED_EXEC) {
        clearTimeout(LogsService.DELAYED_EXEC);
      }
      LogsService.DELAYED_EXEC = setTimeout(() => {
        this._logs.next(this._theLogs);
        LogsService.DELAYED_EXEC = null;
      }, 200);
    }, this.cancelListeners);
    onChildChanged(this._logRef, (data) => {
      const i = this._theLogs.findIndex(l => l.startDate === parseInt(data.key, 10));
      if (i >= 0) {
        this._theLogs[i] = this.setLogItem(data.val(), data.key);
        this._logs.next(this._theLogs);
      }
    }, this.cancelListeners);
  }

  private setLogItem(value, key: string) {
    const events: LogEvent[] = [];
    const log = {...value};
    if (value.hasOwnProperty('eventList')) {
      Object.keys(value.eventList).forEach(k => {
        events.push(Object.assign({ts: parseInt(k, 10)}, value.eventList[k]));
      });
    }
    delete log.eventList;
    return Object.assign({startDate: parseInt(key, 10), eventList: events}, log) as Log;
  }
}
