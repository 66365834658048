import { ChangeDetectorRef, Component, Input, OnInit } from '@angular/core';
import { CategoryData } from '../model/category-data';
import { DataKind } from '../model/common-data';
import { DataCategoriesService } from '../services/data-categories.service';
import { ModalController } from '@ionic/angular';
import { CategoryNewComponent } from '../category-new/category-new.component';

@Component({
  selector: 'app-category-edit',
  templateUrl: './category-edit.component.html',
  styleUrls: ['./category-edit.component.scss']
})
export class CategoryEditComponent implements OnInit {
  @Input() kind: DataKind;
  itemTypes: CategoryData[];
  disabledReorder = true;

  constructor(private dataCategoriesService: DataCategoriesService,
    private modalCtrl: ModalController,
    private ref: ChangeDetectorRef) { }

  ngOnInit() {
    this.updateCategories();
  }

  onCancel() {
    this.modalCtrl.dismiss().then();
  }

  moveUp(index) {
    if (index > 0) {
      [this.itemTypes[index - 1].order, this.itemTypes[index].order] =
        [this.itemTypes[index].order, this.itemTypes[index - 1].order];
      this.dataCategoriesService.updateCategoryAll(this.kind, this.itemTypes);
      this.updateCategories();
    }
  }

  moveDown(index) {
    if (index < this.itemTypes.length - 1) {
      [this.itemTypes[index].order, this.itemTypes[index + 1].order] = [this.itemTypes[index + 1].order, this.itemTypes[index].order];
      this.dataCategoriesService.updateCategoryAll(this.kind, this.itemTypes);
      this.updateCategories();
    }
  }

  newItem() {
    this.showItemModal(0, null);
  }

  editItem(i: number) {
    this.showItemModal(i, this.itemTypes[i]);
  }

  showItemModal(idx: number, item: CategoryData) {
    this.modalCtrl.create({
      component: CategoryNewComponent,
      canDismiss: true,
      componentProps: {item, allItems: this.itemTypes}
    }).then(el => {
      el.present().then();
      return el.onDidDismiss();
    }).then(result => {
      if (result.role === 'save') {
        this.dataCategoriesService.addCategoryData(this.kind, result.data as CategoryData).then();
      } else if (result.role === 'edit') {
        this.dataCategoriesService.updateCategoryData(this.kind, result.data as CategoryData);
      } else if (result.role === 'delete') {
        this.dataCategoriesService.removeCategoryData(this.kind, result.data.name);
      }
      this.updateCategories();
    })
    .catch(err => {
      console.log('ERROR', err);
    });
  }

  private updateCategories() {
    this.dataCategoriesService.getCategories(this.kind).then(cat => {
      this.itemTypes = cat;
      this.ref.detectChanges();
    });
  }
}
