export const environment = {
    production: false,
    languages: ['de', 'en'],
    firebase: {
        apiKey: 'AIzaSyB7v0PmO0AX_YPnH9a4IFzIBAj46iKSqfc',
        authDomain: 'hygiene-app.firebaseapp.com',
        databaseURL: 'https://hygiene-app.firebaseio.com',
        projectId: 'hygiene-app',
        storageBucket: 'hygiene-app.appspot.com',
        messagingSenderId: '407004064473',
        appId: '1:407004064473:web:86117618c46d71a98e6cc8'
    },
    functionsHttpUrl: 'https://europe-west6-hygiene-app.cloudfunctions.net'
};
