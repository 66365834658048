import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from '@medmonitor/authlib';

@Component({
  selector: 'app-navbuttons',
  templateUrl: './navbuttons.component.html',
  styleUrls: ['./navbuttons.component.scss']
})
export class NavbuttonsComponent implements OnInit {
  @Input() admin = false;

  constructor(public authService: AuthService,
    private router: Router) {
  }

  ngOnInit() {
  }

  onLogout() {
    this.router.navigateByUrl('/auth', {replaceUrl: true}).then();
    this.authService.logout();
  }
}
