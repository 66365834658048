import { Component, Input, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { Tablet } from '../../model/tablet';

@Component({
  selector: 'app-tabletlist',
  templateUrl: './tabletlist.component.html',
  styleUrls: ['./tabletlist.component.scss']
})
export class TabletlistComponent implements OnInit {
  @Input() listData: Map<string, { name: string; type: string; icon: string; order: string }>;
  @Input() tablet: Tablet;
  listIds: string[] = [];

  constructor(private modalCtrl: ModalController) { }

  ngOnInit() {
    if (this.tablet.all) {
      for (const id of this.listData.keys()) {
        this.listIds.push(id);
      }
      this.listIds.sort((a, b) => this.listData.get(a).name.localeCompare(this.listData.get(b).name));
    } else {
      this.listIds = this.tablet.listIds;
    }
  }

  onCancel() {
    this.modalCtrl.dismiss(null, 'cancel').then();
  }

}
