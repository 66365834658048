import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { AlertController, ModalController } from '@ionic/angular';
import { NgForm } from '@angular/forms';
import { NewUser } from '@medmonitor/authlib';

@Component({
  selector: 'app-newuser',
  templateUrl: './newuser.component.html',
  styleUrls: ['./newuser.component.scss']
})
export class NewuserComponent implements OnInit {
  @ViewChild('myform', {static: true}) form: NgForm;
  @Input() tenant: string;
  @Input() tenantName: string;
  @Input() currentEmails: string[];
  @Input() short = false;

  constructor(private modalCtrl: ModalController,
    private alertCtrl: AlertController) {}

  ngOnInit() {
  }

  onCancel() {
    this.modalCtrl.dismiss(null, 'cancel').then();
  }

  onAddUser() {
    if (!this.form.valid) {
      return;
    }
    if (this.currentEmails.length === 0 && !this.form.value.admin) {
      this.alertCtrl.create({
        header: 'Not Admin',
        message: 'First user in tenant MUST be Admin',
        buttons: [{text: 'OK', role: 'cancel'}]
      })
      .then(el => el.present())
      .then();
      return;
    }
    const newUser = new NewUser(
      this.form.value.email,
      this.tenant,
      this.tenantName,
      this.form.value.admin,
      this.form.value.name,
      this.form.value.active,
      {}
    );
    this.modalCtrl.dismiss(newUser, 'save').then();
  }

  isExisting(value: string) {
    return this.currentEmails.includes(value);
  }
}
