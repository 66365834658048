export class DocSearch {
  searchText: string;
  showText: boolean;
  showLink: boolean;
  showFile: boolean;
  filterTheory: boolean;
  theory: boolean;
  filterVersioned: boolean;
  versioned: boolean;
  tags: string[];
  createdBefore: number;
  createdAfter: number;
  modifiedBefore: number;
  modifiedAfter: number;
  createdBy: string;
  modifiedBy: string;

  setDefault() {
    this.searchText = '';
    this.showText = true;
    this.showLink = true;
    this.showFile = true;
    this.filterTheory = false;
    this.theory = true;
    this.filterVersioned = false;
    this.versioned = true;
    this.tags = [];
    this.createdBefore = null;
    this.createdAfter = null;
    this.modifiedBefore = null;
    this.modifiedAfter = null;
    this.createdBy = null;
    this.modifiedBy = null;
  }
}
