import { Device } from '../model/device';
import { Material } from '../model/material';
import { DeviceService } from '../services/device.service';
import { NewListComponent } from './newlist/newlist.component';
import { ControlList } from '../model/controllist';
import { ModalController } from '@ionic/angular';
import { ListsService } from '../services/lists.service';
import { AdminListsService } from '../services/admin-lists.service';

export class EditList {
  materials: Material[];
  devices: Device[];

  constructor(
    private modalCtrl: ModalController,
    private listsService: ListsService,
    private deviceService: DeviceService,
    private adminListService: AdminListsService
  ) {}

  public showEditModal(props) {
    let deviceID = '';
    let listName = '';
    let listID = '';
    let newList: ControlList = null;
    const prevDevID = props.list?.deviceID;
    props.materials = this.materials;
    props.devs = this.devices;
    return this.modalCtrl.create({
      component: NewListComponent,
      canDismiss: true,
      componentProps: {
        ...props,
        devices: this.devices,
        materials: this.materials
      }
    }).then(el => {
      el.present().then();
      return el.onDidDismiss();
    }).then(result => {
      if (result.data) {
        delete result.data.last;  // avoid race condition when executing a list or editing it
        if (result.data.type === 'maintenance') {
          deviceID = result.data.deviceID;
          listName = result.data.name;
        }
      }
      if (result.role === 'save') {
        newList = result.data as ControlList;
        return props.isSuperUser ?
               this.adminListService.addList(result.data as ControlList) : this.listsService.addList(result.data as ControlList);
      } else if (result.role === 'edit') {
        return props.isSuperUser ? this.adminListService.updateList(result.data as ControlList) :
               this.listsService.updateList(result.data as ControlList);
      }
      return Promise.resolve('');
    }).then(lid => {
      listID = lid;
      if (!!listID && !!deviceID) {
        return this.deviceService.updateMaintList(deviceID, listID, listName);
      }
      return Promise.resolve();
    }).then(() => {
      if (!!prevDevID && deviceID !== prevDevID) {
        return this.deviceService.removeMaintList(prevDevID, listID);
      }
    }).catch(err => {
      console.log('ERROR', err);
    }).then(() => newList);
  }
}
