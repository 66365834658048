import { ChangeDetectorRef, Component, Input, OnInit } from '@angular/core';
import { AlertController } from '@ionic/angular';
import { TranslateService } from '@ngx-translate/core';
import { ListComponent } from '../lists/list/list.component';
import { ControlList } from '../model/controllist';

@Component({
  selector: 'app-list-selection',
  templateUrl: './list-selection.component.html',
  styleUrls: ['./list-selection.component.scss']
})
export class ListSelectionComponent implements OnInit {
  @Input() listSelection: Map<string, boolean>;
  @Input() controlLists: ControlList[];
  @Input() favorites: string[];
  @Input() noFavorites = false;
  @Input() multi = true;
  allSelected = false;

  constructor(private ref: ChangeDetectorRef,
    private translate: TranslateService,
    private alertCtrl: AlertController) { }

  ngOnInit() {}

  toggle(lid: string) {
    if (this.multi) {
      this.listSelection.set(lid, !this.listSelection.get(lid));
    } else {
      for (const key of this.listSelection.keys()) {
        this.listSelection.set(key, key === lid ? !this.listSelection.get(lid) : false);
      }
    }
    this.ref.markForCheck();
    this.ref.detectChanges();
  }

  all() {
    this.allSelected = !this.allSelected;
    for (const key of this.listSelection.keys()) {
      this.listSelection.set(key, this.allSelected);
    }
  }

  getIcon = (type: string) => ListComponent.ICON_MAP.get(type);

  isFavorite = (id: string) => !!this.favorites && this.favorites.findIndex(f => f === id) >= 0;

  setFavorite(ev: MouseEvent, id: string) {
    ev.stopPropagation();
    if (this.isFavorite(id)) {
      this.favorites.splice(this.favorites.findIndex(f => f === id), 1);
      return;
    }

    if (!this.favorites) {
      this.favorites = [id];
    } else {
      if (this.favorites.length === 20) {
        this.alertCtrl.create({
          header: this.translate.instant('Maximum reached'),
          message: this.translate.instant('Maximum number of favorites is 20'),
          buttons: [{text: 'OK'}]
        }).then(a => {
          a.present().then();
        });
      } else {
        this.favorites.push(id);
      }
    }
  }
}
