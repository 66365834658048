import { Injectable } from '@angular/core';
import { AuthService, AuthUser } from '@medmonitor/authlib';
import { BehaviorSubject, Subscription } from 'rxjs';
import { OfficeStatus } from '../model/office-status';
import { ControlListType } from '../model/controllist';
import { Database, DatabaseReference, equalTo, off, onValue, orderByChild, query, ref, set } from '@angular/fire/database';

@Injectable({
  providedIn: 'root'
})
export class OfficeStatusService {
  private _user: AuthUser;
  private readonly _sub: Subscription;
  private _statusRef: DatabaseReference;
  private _status = new BehaviorSubject<OfficeStatus>(null);

  constructor(private db: Database,
              private authService: AuthService) {
    this._sub = this.authService.user.subscribe(u => {
      this.clearService();
      if (!!u) {
        this._user = u;
        this._statusRef = ref(this.db, `tenants/${this._user.tenant}/status`);
        onValue(this._statusRef, snapshot => {
          this._status.next(snapshot.val());
        });
      }
    });
  }

  get status() {
    return this._status.asObservable();
  }

  clearService() {
    if (!!this._statusRef) {
      off(this._statusRef);
      this._statusRef = null;
    }
  }

  setStatus(nextStatus: OfficeStatus) {
    set(this._statusRef, nextStatus).then();
    if (nextStatus === 'opening') {
      this.setAlerts('open');
    } else if (nextStatus === 'closing') {
      this.setAlerts('close');
    }
  }

  setAlerts(status: ControlListType) {
    onValue(query(ref(this.db, `tenants/${this._user.tenant}/lists`), orderByChild('type'), equalTo(status)), listsSnapshot => {
      const lists = listsSnapshot.val();
      Object.keys(lists).forEach(key => {
        if (lists[key].type === status) {
          set(ref(this.db, `tenants/${this._user.tenant}/lists/${key}/alert`), true).then();
        } else {
          console.log('WRONG TYPE, this should not happen' + status + key);
        }
      });
    }, {onlyOnce: true});
  }
}
