import { ChangeDetectorRef, Component, OnDestroy, OnInit } from '@angular/core';
import { AlertController } from '@ionic/angular';
import { AuthService, AuthUser } from '@medmonitor/authlib';
import { TranslateService } from '@ngx-translate/core';
import { VersionService } from '../services/version.service';
import { TenantMetadataService } from '../services/tenant-metadata.service';
import { Subscription } from 'rxjs';
import { TenantMetadata } from '../model/tenant';

@Component({
  selector: 'app-footer-info',
  templateUrl: './footer-info.component.html',
  styleUrls: ['./footer-info.component.scss'],
})
export class FooterInfoComponent implements OnInit, OnDestroy {
  public user: AuthUser;
  hasData: boolean;
  info: TenantMetadata;
  release = '';
  private _authSub: Subscription;
  private _tmSub: Subscription;

  constructor(private authService: AuthService,
              private tmService: TenantMetadataService,
              private versionService: VersionService,
              private alertCtrl: AlertController,
              private translate: TranslateService,
              private ref: ChangeDetectorRef) {
  }

  ngOnInit() {
    this._authSub = this.authService.user.subscribe(u => {
      this.user = u;
      this._tmSub?.unsubscribe();
      if (!!u) {
        this._tmSub = this.tmService.info.subscribe(info => {
          this.info = info;
          if (!!this.info) {
            this.hasData = true;
            this.ref.detectChanges();
          }
        });
      }
    });
    this._authSub.add(this.versionService.version.subscribe(v => {
      if (this.release !== v) {
        if (this.release.length > 0) {
          this.alertCtrl.create({
            header: this.translate.instant('New Version'),
            message: this.translate.instant('New Version available. Page will be reloaded. See') +
              ' <a href="https://medmonitorswiss.crunch.help/de/release-notes-versionshinweise" target="_blank">Release Notes</a>',
            buttons: [{text: 'OK'}]
          }).then(al => {
            al.present().then();
            return al.onDidDismiss();
          }).then(() => {
            this.release = v;
            location.reload();
          });
        } else {
          this.release = v;
        }
      }
    }));
  }

  ngOnDestroy() {
    this._authSub?.unsubscribe();
    this._tmSub?.unsubscribe();
  }

  goToVersion() {

  }
}
