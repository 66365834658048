import { AfterViewInit, Component, Input, OnInit, ViewChild } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { IonDatetime, PopoverController } from '@ionic/angular';

@Component({
  selector: 'app-date-selector',
  templateUrl: './date-selector.component.html',
  styleUrls: ['./date-selector.component.scss']
})
export class DateSelectorComponent implements OnInit, AfterViewInit {
  @Input() max: string;
  @Input() min: string;
  @Input() date: string;
  @ViewChild('datetime') dt: IonDatetime;

  constructor(public translate: TranslateService,
    private popoverCtrl: PopoverController) { }

  ngOnInit() {}

  ngAfterViewInit() {
    if (!!this.min) {
      this.dt.min = this.min;
    }
    if (!!this.max) {
      this.dt.max = this.max;
    }
  }

  cancel() {
    this.dt.cancel().then(() => {
      this.popoverCtrl.dismiss(null, 'cancel').then();
    });
  }

  do() {
    this.dt.confirm().then(() => {
      const result = this.dt.value as string;
      this.popoverCtrl.dismiss(result, 'ok').then();
    });
  }
}
