import { Injectable } from '@angular/core';
import { LoadingController } from '@ionic/angular';

@Injectable({
  providedIn: 'root'
})
export class MonthloaderService {

  isLoading = false;
  isCreated = false;

  constructor(public loadingController: LoadingController) { }

  async present() {
    this.isLoading = true;
    return await this.loadingController.create().then(a => {
      this.isCreated = true;
      a.present().then(() => {
        if (!this.isLoading) {
          a.dismiss();
          this.isCreated = false;
        }
      });
    });
  }

  async dismiss() {
    if (this.isLoading) {
      this.isLoading = false;
      return await (this.isCreated ? this.loadingController.dismiss() : Promise.resolve(true));
    }
    return null;
  }
}

// see https://dcarvajal7.es/solucion-error-uncaught-in-promise-overlay-does-not-exist/
