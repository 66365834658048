import { NiceDate } from '../util/dateutil';
import { TranslateService } from '@ngx-translate/core';
import { IdentityData } from '@medmonitor/authlib';

export type ControlListType = 'maintenance' | 'request' | 'open' | 'close' | 'steril' | 'daily' | 'task';
export const LIST_TYPES = ['maintenance', 'steril', 'daily', 'task', 'request', 'open', 'close'];
export const INPUT_TEXT = 0;
export const INPUT_NUMBER = 1;
export const INPUT_DRUG = 2;


export class ControlList implements IdentityData {
  id: string;
  name: string;
  type: ControlListType;
  description: string;
  completions: number;
  maint?: number;
  maintInterval?: number;
  nextMaintenance?: number;
  changeMaintenance?: boolean;
  deviceID?: string;
  deviceName?: string;
  last: { [key: number]: string };
  executions: { date: string; user: string }[];
  alert: boolean;
  sequential: boolean;
  items: ListItem[];
  dailyTimes?: number[];
  dailyWeekdays?: number[];
  alertNextList?: boolean;
  nextList?: string;
}

export class ListItem {
  name: string;
  desc: string;
  optional: boolean;
  timed: number;
  timeblocked: boolean;
  timelimited: number;
  materialType: number;
  matID: string;
  deviceType: number;
  devID: string;
  program?: string;
  unit?: string;
  amount?: number;
  commented: boolean;
  picture: boolean;
  input: boolean;
  zone: number;
  showInfo: boolean;
  backToStep?: number;
  infoPic?: boolean;
  infoPicLink?: string;
  infoPicFile?: File;
  changeMaterial?: boolean;
  changeDevice?: boolean;
  inputType?: number;
  inputOptional?: boolean;
}

export class ListUtil {
  public static MAINT_INTERVAL = {
    en: ['Days', 'Weeks', 'Months'],
    de: ['Tage', 'Wochen', 'Monate']
  };

  public static NextMaintAt(lastMaint: number, interval: number, amount: number) {
    const dayMillis = 24 * 60 * 60000;
    const factor = [1, 7, 30];
    return lastMaint + factor[interval - 1] * amount * dayMillis;
  }

  public static GetMaintInterval(lists: ControlList[], listID: string, translate: TranslateService) {
    const list = lists?.find(l => l.id === listID);
    const maintNextStr = NiceDate(new Date(!!list.nextMaintenance ? list.nextMaintenance : Date.now()));

    return ListUtil.GetMaintIntText(list, translate.currentLang) + ' - ' + maintNextStr;
  }

  public static GetMaintIntText(list: ControlList, language: string) {
    return `${ list.maint?.toString() } ${ ListUtil.MAINT_INTERVAL[language][list.maintInterval - 1] }`;
  }

  public static DeepCopy(list: ControlList): ControlList {
    const newlist = new ControlList();
    newlist.name = list.name.repeat(1);
    newlist.description = list.description ? list.description.repeat(1) : '';
    newlist.type = list.type.repeat(1) as ControlListType;
    newlist.id = list.id.repeat(1);
    newlist.alert = list.alert ? list.alert : false;
    newlist.alertNextList = list.alertNextList ? list.alertNextList : false;
    newlist.sequential = list.sequential ? list.sequential : false;
    newlist.completions = list.completions ? list.completions : 0;
    newlist.last = {...list.last};
    // newlist.executions = [];   // omitted on purpose. do not store this in db.
    // list.executions.forEach(ex => newlist.executions.push({...ex}));
    newlist.items = [];
    list.items?.forEach(item => newlist.items.push(ListUtil.DeepCopyItem(item)));
    if (list.maintInterval) {
      newlist.maintInterval = list.maintInterval;
    }
    if (!!list.maint) {
      newlist.maint = list.maint;
    }
    if (!!list.deviceID) {
      newlist.deviceID = list.deviceID.repeat(1);
    }
    if (!!list.deviceName) {
      newlist.deviceName = list.deviceName.repeat(1);
    }
    if (list.nextMaintenance) {
      newlist.nextMaintenance = list.nextMaintenance;
    }
    if (list.changeMaintenance) {
      newlist.changeMaintenance = list.changeMaintenance;
    }
    if (!!list.dailyTimes) {
      newlist.dailyTimes = [...list.dailyTimes];
    }
    if (!!list.dailyWeekdays) {
      newlist.dailyWeekdays = [...list.dailyWeekdays];
    }
    if (!!list.nextList) {
      newlist.nextList = list.nextList.repeat(1);
    }
    return newlist;
  }

  public static DeepCopyItem(item: ListItem) {
    const newitem = new ListItem();
    newitem.name = item.name.repeat(1);
    newitem.desc = item.desc.repeat(1);
    newitem.optional = item.optional ? item.optional : false;
    newitem.timed = item.timed ? item.timed : 0;
    newitem.timeblocked = item.timeblocked ? item.timeblocked : false;
    newitem.commented = item.commented ? item.commented : false;
    newitem.picture = item.picture ? item.picture : false;
    newitem.input = item.input ? item.input : false;
    if (!!item.materialType) {
      newitem.materialType = item.materialType;
    }
    if (!!item.matID) {
      newitem.matID = item.matID.repeat(1);
    }
    if (item.deviceType !== undefined) {
      newitem.deviceType = item.deviceType;
    }
    if (!!item.devID) {
      newitem.devID = item.devID.repeat(1);
    }
    if (item.zone !== undefined) {
      newitem.zone = item.zone;
    }
    if (item.timelimited !== undefined) {
      newitem.timelimited = item.timelimited;
    }
    if (!!item.program) {
      newitem.program = item.program;
    }
    if (!!item.unit) {
      newitem.unit = item.unit;
    }
    if (item.amount !== undefined) {
      newitem.amount = item.amount;
    }
    if (item.backToStep !== undefined) {
      newitem.backToStep = item.backToStep;
    }
    if (item.infoPic !== undefined) {
      newitem.infoPic = item.infoPic;
    }
    if (!!item.infoPicLink) {
      newitem.infoPicLink = item.infoPicLink.repeat(1);
    }
    if (item.changeMaterial !== undefined) {
      newitem.changeMaterial = item.changeMaterial;
    } else {
      newitem.changeMaterial = true;
    }
    if (item.changeDevice !== undefined) {
      newitem.changeDevice = item.changeDevice;
    } else {
      newitem.changeDevice = true;
    }
    newitem.showInfo = item.showInfo ?? false;
    newitem.inputType = item.inputType ?? INPUT_TEXT;
    newitem.inputOptional = item.inputOptional ?? false;
    return newitem;
  }

  public static DupName(name: string) {
    if (name.endsWith(')')) {
      const m = /.*\(([0-9]+)\)$/.exec(name);
      if (m) {
        const newIndex = parseInt(m[1], 10) + 1;
        name = name.substr(0, name.length - 2 - m[1].length) + `(${ newIndex })`;
      } else {
        name += ' (1)';
      }
    } else {
      name += ' (1)';
    }
    return name;
  }

  static TimedText(timed: number) {
    if (timed) {
      const d = new Date(timed);
      let str = d.getUTCSeconds().toString();
      if (d.getUTCSeconds() < 10 && (d.getUTCMinutes() > 0 || d.getUTCHours() > 0)) {
        str = '0' + str;
      }
      if (d.getUTCMinutes() === 0 && d.getUTCHours() === 0) {
        return str + 'sec.';
      }
      str = `${ d.getUTCMinutes() }:${ str }`;
      if (d.getUTCMinutes() < 10 && d.getUTCHours() > 0) {
        str = '0' + str;
      }
      if (d.getUTCHours() === 0) {
        return str + 'min.';
      }
      return `${ d.getUTCHours() }:${ str }`;
    }
    return '';
  }
}
