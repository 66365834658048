import { Injectable } from '@angular/core';
import { AuthService, AuthUser } from '@medmonitor/authlib';
import { BehaviorSubject, Observable } from 'rxjs';
import { TenantMetadata } from '../model/tenant';
import { Database, DatabaseReference, onValue, ref, set } from '@angular/fire/database';

@Injectable({
  providedIn: 'root'
})
export class TenantMetadataService {
  private _user: AuthUser;
  private _tmRef: DatabaseReference;
  private _tenantMD = new BehaviorSubject<TenantMetadata>(null);
  private _offFunc: any;

  constructor(private db: Database, private authService: AuthService) {
    this.authService.user.subscribe(u => {
      this.cancelListeners();
      this._user = u;
      if (!!u) {
        this._tmRef = ref(this.db, `tenantInfo/${u.tenant}`);
        this._offFunc = onValue(this._tmRef, (data) => {
          this._tenantMD.next(data.val() as TenantMetadata);
        }, err => {
          console.log(Date.now(), err);
          this.cancelListeners();
        });
      }
    });
  }

  public get info(): Observable<TenantMetadata> {
    return this._tenantMD;
  }

  public cancelListeners() {
    if (this._tmRef) {
      if (!!this._offFunc) {
        this._offFunc();
      }
      this._tmRef = null;
    }
  }

  write(info: TenantMetadata) {
    set(this._tmRef, info).then();
  }
}
