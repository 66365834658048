import { Injectable } from '@angular/core';
import { Database } from '@angular/fire/database';
import { AuthService } from '@medmonitor/authlib';
import { TenantQss } from '../model/tenant-qss';
import { UserDataService } from './user-data.service';

@Injectable({
  providedIn: 'root'
})
export class TenantQssService extends UserDataService<TenantQss> {
  constructor(protected db: Database,
    protected authService: AuthService) {
    super(db, authService, 'tenantQss');
  }

  removeControl(detail: string) {
    const controls = this._theList?.filter(q => q.detail === detail);
    if (!!controls) {
      controls.forEach(c => {
        this.removeData(c).then();
      });
    }
  }
}
