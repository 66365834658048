import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { redirectUnauthorizedTo, customClaims, canActivate } from '@angular/fire/auth-guard';
import { pipe } from 'rxjs';
import { map } from 'rxjs/operators';

export const redirectUnauthorizedToLogin = () => redirectUnauthorizedTo(['/', 'auth']);

export const superAdminOnly =
               () => pipe(customClaims, map(
                 claims => !!claims ? (claims.hasOwnProperty('superadmin') ? true : ['home']) : ['auth']
               ));

export const adminOnly =
               () => pipe(customClaims, map(
                 claims => !!claims ?
                           ((claims.hasOwnProperty('admin') || claims.hasOwnProperty('superadmin')) ? true : ['theory']) :
                           ['auth']
               ));

export const routes: Routes = [
  {path: '', redirectTo: 'home', pathMatch: 'full'},
  {
    path: 'ok',
    loadChildren: () => import('./ok/ok.module').then(m => m.OkPassPageModule)
  },
  {
    path: 'auth',
    loadChildren: () => import('./auth/auth.module').then(m => m.AuthPageModule)
  },
  {
    path: 'home',
    loadChildren: () => import('./home/home.module').then(m => m.HomePageModule),
    ...canActivate(adminOnly)
  },
  {
    path: 'admin',
    loadChildren: () => import('./admin/admin.module').then(m => m.AdminPageModule),
    ...canActivate(superAdminOnly)
  },
  {
    path: 'lists',
    loadChildren: () => import('./lists/lists.module').then(m => m.ListsPageModule),
    ...canActivate(adminOnly)
  },
  {
    path: 'theory',
    loadChildren: () => import('./theory/theory.module').then(m => m.TheoryPageModule),
    ...canActivate(redirectUnauthorizedToLogin)
  },
  {
    path: 'tablets',
    loadChildren: () => import('./tablets/tablets.module').then(m => m.TabletsPageModule),
    ...canActivate(adminOnly)
  },
  {
    path: 'report',
    loadChildren: () => import('./report/report.module').then(m => m.ReportPageModule),
    ...canActivate(adminOnly)
  },
  {
    path: 'materials',
    loadChildren: () => import('./materials/materials.module').then(m => m.MaterialsPageModule),
    ...canActivate(adminOnly)
  },
  {
    path: 'devices',
    loadChildren: () => import('./devices/devices.module').then(m => m.DevicesPageModule),
    ...canActivate(adminOnly)
  },
  {
    path: 'sensors',
    loadChildren: () => import('./sensors/sensors.module').then(m => m.SensorsPageModule),
    ...canActivate(adminOnly)
  },
  {
    path: 'qss',
    loadChildren: () => import('./qss/qss.module').then(m => m.QssPageModule),
    ...canActivate(adminOnly)
  },
  {
    path: 'documents',
    loadChildren: () => import('./documents/documents.module').then(m => m.DocumentsPageModule),
    ...canActivate(adminOnly)
  },
  {
    path: 'settings',
    loadChildren: () => import('./settings/settings.module').then(m => m.SettingsPageModule),
    ...canActivate(adminOnly)
  }
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {preloadingStrategy: PreloadAllModules, useHash: true})
  ],
  exports: [RouterModule]
})
export class AppRoutingModule {
}
