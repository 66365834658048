import { Database } from '@angular/fire/database';
import { AuthService, AuthUser, IdentityData } from '@medmonitor/authlib';
import { GenericDataService } from './generic-data.service';

export abstract class UserDataService<T extends IdentityData> extends GenericDataService<T> {
  protected _user: AuthUser;
  private readonly _ready: Promise<void>;

  protected constructor(protected db: Database,
    protected authService: AuthService,
    path: string,
    inTenant = false) {
    super(db);
    this._ready = new Promise<void>((resolve) => {
      this.authService.user.subscribe(u => {
        // if (!this._user || this._user.tenant !== u.tenant) {
          this.cancelListeners();
          this._user = u;
          if (!!u && !!u.tenant) {
            this.init(inTenant ? `/tenants/${ u.tenant }/${ path }` : `/${ path }/${ u.tenant }`);
            resolve();
          }
        // }
      });
    });
  }

  public isReady() {
    return this._ready;
  }
}
