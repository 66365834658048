import { Injectable } from '@angular/core';
import { child, Database, onValue, set } from '@angular/fire/database';
import { AuthService } from '@medmonitor/authlib';
import { Device } from '../model/device';
import { DeviceCatalogService } from './data.service';
import { MatDevServiceBase } from './mat-dev-service-base';

@Injectable({
  providedIn: 'root'
})
export class DeviceService extends MatDevServiceBase<Device> {
  constructor(protected db: Database,
    protected authService: AuthService,
    private devCatService: DeviceCatalogService) {
    super(db, authService, 'myDevice');
    this.devCatService.data.subscribe(cat => {
      this._catalog = cat;
      this.processList();
    });
  }


  updateMaintList(deviceID: string, listID: string, listName: string) {
    return this.isReady().then(() => {
      const myRef = this.childRef(deviceID);
      return new Promise<void>((resolve) => {
        onValue(myRef, mlists => {
          const data = mlists.val() as Device;
          let maintLists = data.maintLists;
          let maintListNames = data.maintListNames;
          if (!maintLists) {
            maintLists = [listID];
            maintListNames = [listName];
          } else if (maintLists.indexOf(listID) < 0) {
            maintLists.push(listID);
            maintListNames.push(listName);
          } else {
            return;
          }
          set(child(myRef, 'maintLists'), maintLists).then();
          set(child(myRef, 'maintListNames'), maintListNames).then();
          resolve();
        }, {onlyOnce: true});
      });
    });
  }

  removeMaintList(prevDevID: any, listID: string) {
    return this.isReady().then(() => {
      const myRef = this.childRef(prevDevID);
      return new Promise<void>((resolve) => {
        onValue(myRef, mlists => {
          if (mlists.exists()) {
            const data = mlists.val() as Device;
            const maintLists = data.maintLists;
            const maintListNames = data.maintListNames;
            const i = maintLists?.indexOf(listID);
            if (i >= 0) {
              maintLists.splice(i, 1);
              maintListNames.splice(i, 1);
              set(child(myRef, 'maintLists'), maintLists).then();
              set(child(myRef, 'maintListNames'), maintListNames).then();
              resolve();
            }
          }
        }, {onlyOnce: true});
      });
    });
  }
}
