import { CategoryData } from '../model/category-data';
import { Device } from '../model/device';
import { Drug } from '../model/drug';
import { MatDevBase } from '../model/mat-dev-base';
import { Material } from '../model/material';
import { TenantMetadata } from '../model/tenant';
import { LogEvent } from '../model/log';
import { TranslateService } from '@ngx-translate/core';
import { DateUtil, StrDate } from './dateutil';
import { MarkdownService } from 'ngx-markdown';
import { ControlList, ListItem, ListUtil } from '../model/controllist';
import { Tablet } from '../model/tablet';
import { Doc } from '../model/document';
import { format } from 'date-fns';

export class PrintBase {
  static ZONES = ['Used', 'Dirty', 'Clean', 'Sterile', 'Distribution'];
  printWindow: Window;
  markdownService: MarkdownService;
  images = new Map<number, string[]>();
  material: Material[];
  devices: Device[];
  modules: Doc[];
  itemCategories: CategoryData[] = null;

  constructor(private translate: TranslateService) {}

  start() {
    this.printWindow = window.open('', 'PRINT', 'height=600, width=800');
    this.emit(`
      <html lang="en-US"><head><title>medmonitor.swiss Report ${ format(new Date(), 'yyyy-MM-dd') }</title>
        <meta charset="utf-8" />
        <base href="/" />
        <link href="https://fonts.googleapis.com/css2?family=Lato:ital,wght@0,100;0,300;0,400;1,300&display=swap" rel="stylesheet">
        <link href="/assets/printing.css" type="text/css" rel="stylesheet">
        </head>
        <body>
          <table>
            <thead>
              <tr>
                <td>
                  <div class="header-space">&nbsp;</div>
                </td>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>`);
  }

  startDoc(title: string) {
    this.printWindow = window.open('', 'PRINT', 'height=600, width=800');
    this.emit(`
      <html lang="en-US"><head><title>${ title }</title>
        <meta charset="utf-8" />
        <base href="/" />
        <link href="https://fonts.googleapis.com/css2?family=Lato:ital,wght@0,100;0,300;0,400;1,300&display=swap" rel="stylesheet">
        <link href="/assets/printing.css" type="text/css" rel="stylesheet">
        </head>
        <body>
          <table>
            <thead>
              <tr>
                <td>
                  <div class="header-space">&nbsp;</div>
                </td>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>`);
  }

  setHeaderFooter(info: TenantMetadata, tenantName: string) {
    this.emit(`
    <div class="header">
      <img src="assets/icon/android-icon-48x48.png" alt="mm"/>
      ${ tenantName } &nbsp; &mdash; &nbsp; ${ this.translate.instant('Quality Assurance System') }
    </div>
    `);
    this.setFooter(info, tenantName);
  }

  setFooter(info: TenantMetadata, tenantName: string) {
    this.emit(`
    <div class="footer">
      <div class="footerContent">
        <div>${ tenantName }</div>
        <div>${ info?.address }</div>
        <div>${ info?.zip } ${ info?.city }</div>
        <div>Tel. ${ info?.phone }</div>
        <div style="text-align: right;">email: <em>${ info?.email }</em></div>
      </div>
    </div>
    `);
    this.nl();
  }

  print() {
    this.finalize();
    setTimeout(() => {
      this.printWindow.print();
      this.printWindow.close();
    }, 1000);
  }

  pageBreak() {
    this.emit('</td></tr><tr class="pbreak"><td>');
  }

  addHeading(text: string, level: number, pageBreak = true) {
    if (pageBreak && level === 1) {
      this.pageBreak();
    }
    this.emit(`<h${ level + 1 }>${ text }</h${ level + 1 }>`);
    this.nl();
  }

  addListItem(text: string, level: number) {
    for (let i = 0; i < level; i++) {
      this.emit('<ul>');
    }
    this.emit(`<li>${ text }</li>`);
    for (let i = 0; i < level; i++) {
      this.emit('</ul>');
    }
    this.nl();
  }

  addSpace(n: number = 1) {
    this.emit(`<div class="space${ n }"></div>`);
    this.nl();
  }

  logHeader(nam, typ, user, time, duration, signature,
    listName, listType, listUser, listTime, listDuration, listSig, deviceName, maintInterval) {
    let aborted = false;
    if (listDuration === '-') {
      aborted = true;
      listDuration = this.translate.instant('ABORTED');
    }
    this.emit(`
     <div class="logHeader">
       <div style="width: 60%;">
        <div class="listContent">
          <div class="logTitle logTitleText">${ nam }:</div>
          <div class="logTitle logTitleValues">${ listName }</div>
        </div>
        <div class="listContent">
          <div class="logTitle logTitleText">${ typ }:</div>
          <div class="logTitle logTitleValues">${ listType }</div>
        </div>`);
    if (!!deviceName) {
      this.emit(`
        <div class="listContent">
          <div class="logTitle logTitleText">${ this.translate.instant('Device name') }:</div>
          <div class="logTitle logTitleValues">${ deviceName }</div>
        </div>`);
    }
    if (!!maintInterval) {
      this.emit(`
        <div class="listContent">
          <div class="logTitle logTitleText">${ this.translate.instant('Maintenance') }:</div>
          <div class="logTitle logTitleValues">${ maintInterval }</div>
        </div>`);
    }
    this.emit(`
        <div class="listContent">
          <div class="logTitle logTitleText">${ time }:</div>
          <div class="logTitle logTitleValues">${ listTime }</div>
        </div>
        <div class="listContent">
          <div class="logTitle logTitleText">${ duration }:</div>
          <div class="logTitle logTitleValues"${ aborted ? ' style="color: red;"' : '' }>${ listDuration }</div>
        </div>
       </div>
       <div style="width: 40%;">
        <div class="listContent">
          <div class="logTitle logTitleWho">${ user }:</div>
          <div class="logTitle logTitleName">${ listUser }</div>
        </div>
        <div style="width: 100%;"><img src="${ listSig }" width="100%" alt="sig"/></div>
       </div>
     </div>
     <hr>`);
    this.nl();
  }

  logItem(description: string, eventList: LogEvent[]) {
    this.emit(`<div class="logDetail">`);
    if (!!description) {
      this.emit(`<div class="logDesc">${ this.markdownService.parse(description) }</div>`);
    }
    this.emit('</div>');
    this.nl();
    this.emit(`
        <div class="logItemHeader">
          <div class="checkUncheckTitle topBorder">
           <img src="assets/print-icon/checkmark-outline.svg" alt="img" width="10">/
           <img src="assets/print-icon/close-outline.svg" alt="img" width="10">
          </div>
          <div class="logAtTitle topBorder">${ this.translate.instant('Done At') }</div>
          <div class="durationTitle topBorder">${ this.translate.instant('Duration') }</div>
          <div class="stepTitle topBorder">${ this.translate.instant('Step') }</div>`);
    if (!!eventList && eventList.length > 0 && !!eventList[0].zone) {
      this.emit(`      <div class="descriptionTitleZ topBorder">${ this.translate.instant('Description') }</div>`);
      this.emit(`      <div class="zoneTitle topBorder">Zone</div>`);
    } else {
      this.emit(`      <div class="descriptionTitle topBorder">${ this.translate.instant('Description') }</div>`);
    }
    this.emit(`    </div>`);
    this.nl();
    const content = new Map<string, string>();
    let lastE: LogEvent;
    eventList.forEach(e => {
      lastE = e;
      switch (e.type) {
        case 'check':
          this.doLogItem(e, content, true);
          content.clear();
          break;
        case 'uncheck':
          this.doLogItem(e, content, false);
          content.clear();
          break;
        case 'comment':
          content.set(e.type, e.comment);
          break;
        case 'input':
          content.set(e.type, e.input);
          break;
        case 'maintenance':
          content.set(e.type, StrDate(e.maintDate));
          break;
        case 'material':
          content.set(e.type, e.materialName + (e.amount ? ' ' + e.amount + e.unit : ''));
          break;
        case 'device':
          content.set(e.type, e.deviceName + (e.program ? ' Prog:' + e.program : ''));
          break;
        case 'timerstart':
        case 'timerend':
          content.set(e.type, e.timer.toString());
          break;
        case 'timedfail':
          content.set(e.type, e.timelimit.toString());
          break;
        case 'picture':
          if (e.images > 0) {
            content.set(e.type, `${ e.images };${ e.ts }`);
          }
          break;
      }
    });
    if (content.size > 0) {
      lastE.duration = -1;
      this.doLogItem(lastE, content, content.has('maintenance'));
    }
  }

  doLogItem(e: LogEvent, content: Map<string, string>, isChecked: boolean) {
    if (!!e.zone) {
      this.emit(`
          <div class="logItem">
            <div class="checkUncheck topBorder">
                <img src="assets/print-icon/${ isChecked ? 'checkmark' : 'close' }-outline.svg" alt="img" width="10">
            </div>
            <div class="logAt topBorder">${ StrDate(e.ts) }</div>
            <div class="duration topBorder">${ e.duration < 0 ? '-' : DateUtil.GetDuration(e.duration) }</div>
            <div class="step topBorder">${ e.name }</div>
            <div class="itemdescZ topBorder">${ !!e.description ? this.markdownService.parse(e.description) : '' }</div>
            <div class="itemZone topBorder">${ this.translate.instant(`${ PrintBase.ZONES[Math.round(e.zone)] }`) }</div>
          </div>
      `);
    } else {
      this.emit(`
          <div class="logItem">
            <div class="checkUncheck topBorder">
                <img src="assets/print-icon/${ isChecked ? 'checkmark' : 'close' }-outline.svg" alt="img" width="10">
            </div>
            <div class="logAt topBorder">${ StrDate(e.ts) }</div>
            <div class="duration topBorder">${ e.duration < 0 ? '-' : DateUtil.GetDuration(e.duration) }</div>
            <div class="step topBorder">${ e.name }</div>
            <div class="itemdesc topBorder">${ !!e.description ? this.markdownService.parse(e.description) : '' }</div>
          </div>
      `);
    }
    this.nl();
    if (content.has('comment')) {
      this.emit(`
            <div class="logDetailContent">
              <div class="logDetailSkip">&nbsp;</div>
              <div class="logDetailH">${ this.translate.instant('Comment:') }</div>
              <div class="logDetailRest">${ content.get('comment') }</div>
            </div>
      `);
      this.nl();
    }
    if (content.has('input')) {
      this.emit(`
            <div class="logDetailContent">
              <div class="logDetailSkip">&nbsp;</div>
              <div class="logDetailH">${ this.translate.instant('Input:') }</div>
              <div class="logDetailRest">${ content.get('input') }</div>
            </div>
      `);
      this.nl();
    }
    if (content.has('material')) {
      this.emit(`
            <div class="logDetailContent">
              <div class="logDetailSkip">&nbsp;</div>
              <div class="logDetailH">${ this.translate.instant('Material') }</div>
              <div class="logDetailRest">${ content.get('material') }</div>
            </div>
      `);
      this.nl();
    }
    if (content.has('device')) {
      this.emit(`
            <div class="logDetailContent">
              <div class="logDetailSkip">&nbsp;</div>
              <div class="logDetailH">${ this.translate.instant('Device:') }</div>
              <div class="logDetailRest">${ content.get('device') }</div>
            </div>
      `);
      this.nl();
    }
    if (content.has('timerstart')) {
      this.emit(`
            <div class="logDetailContent">
              <div class="logDetailSkip">&nbsp;</div>
              <div class="logDetailH"><img src="assets/print-icon/timer-outline.svg" alt="img" width="8">
                &nbsp;Start Timer ${ e.timerBlocks ? '(blocking)' : '' }:
              </div>
              <div class="logDetailRest">${ content.get('timerstart') }</div>
            </div>
      `);
      this.nl();
    }
    if (content.has('timerend')) {
      this.emit(`
            <div class="logDetailContent">
              <div class="logDetailSkip">&nbsp;</div>
              <div class="logDetailH"><img src="assets/print-icon/notifications-circle-outline.svg" alt="img" width="8">
                &nbsp;${ this.translate.instant('End') } Timer ${ e.timerBlocks ? '(blocking)' : '' }:
              </div>
              <div class="logDetailRest">${ content.get('timerend') }</div>
            </div>
      `);
      this.nl();
    }
    if (content.has('timedfail')) {
      this.emit(`
            <div class="logDetailContent">
              <div class="logDetailSkip">&nbsp;</div>
              <div class="logDetailH" style="color: red;"><img src="assets/print-icon/sad-outline.svg" alt="img" width="8">
                &nbsp;${ this.translate.instant('TimeFail') }!
              </div>
              <div class="logDetailRest">${ content.get('timedfail') }</div>
            </div>
      `);
      this.nl();
    }
    if (content.has('picture')) {
      const pro = content.get('picture').split(';');
      const myTS = parseInt(pro[1], 10);
      this.emit(`
            <div class="logDetailContent">
              <div class="logDetailSkip">&nbsp;</div>
              <div class="logDetailH"><img src="assets/print-icon/camera-outline.svg" alt="img" width="8">
                &nbsp;${ this.translate.instant('Pictures') }:
              </div>
              <div class="logDetailRest">${ pro[0] }</div>
            </div>
      `);
      this.emit(`<div class="logDetailImages">`);
      if (this.images.has(myTS)) {
        this.images.get(myTS).forEach(im => {
          this.emit(`<img src="${ im }" alt="image" width="150">`);
        });
      }
      this.emit(`</div>`);
      this.nl();
    }
    if (content.has('maintenance')) {
      this.emit(`
            <div class="logDetailContent">
              <div class="logDetailSkip">&nbsp;</div>
              <div class="logDetailH">${ this.translate.instant('CONTROLLIST.maintenance') }</div>
              <div class="logDetailRest">${ content.get('maintenance') }</div>
            </div>
      `);
      this.nl();
    }
  }

  p(text: string, cl: string = null) {
    let c = '';
    if (!!cl) {
      c = ` class="${ cl }"`;
    }
    this.emit(`<p${ c }>${ text }</p>`);
    this.nl();
  }

  hr() {
    this.emit('<hr>');
  }

  lineItem(item, text) {
    if (!!text) {
      this.emit(`
      <div class="lineItem">
        <div class="lineItemLeft">
          ${ this.translate.instant(item) }
        </div>
        <div class="lineItemText">
          ${ text }
        </div>
      </div>
    `);
      this.nl();
    }
  }

  markdown(md: string) {
    if (!!md) {
      this.emit(this.addNL(this.markdownService.parse(md)));
    }
  }

  justHtml(h: string) {
    if (!!h) {
      this.emit(this.addNL(h));
    }
  }

  listHeader(list: ControlList) {
    this.addSpace();
    this.hr();
    this.p(`${ this.translate.instant('List') }: ${ list.name }`);
    if (!!list.deviceName) {
      this.p(`${ this.translate.instant('Device') }: ${ list.deviceName }`, 'small');
      if (!!list.maint && !!list.maintInterval) {
        this.p(
          `${ this.translate.instant('Maintenance Interval') }: ${ ListUtil.GetMaintIntText(list, this.translate.currentLang) }`,
          'small');
      }
    }
    this.p(`${ this.translate.instant('Description') }: ${ this.markdownService.parse(list.description) }`);
    this.nl();
  }

  zName(zone: number) {
    return this.translate.instant(`${ PrintBase.ZONES[Math.round(zone)] }`);
  }

  listItem(items: ListItem[], steri: boolean = false) {
    if (!items) {
      return;
    }

    items.forEach((e, i) => {
      if (!steri) {
        this.emit(`
          <div class="listItem">
            <div class="checkUncheck"><img src="assets/print-icon/checkmark-outline.svg" alt="img" width="10"></div>
            <div class="step">${ e.name }</div>
            <div class="description">${ !!e.desc ? e.desc : '' }</div>
          </div>
          <div class="listOptions mini">
      `);
      } else {
        this.emit(`
          <div class="steriItem">
            <div class="sZone">${ this.zName(e.zone) }</div>
            <div class="sNr">${ i }</div>
            <div class="sName">${ e.name }</div>
            <div class="sDesc">${ !!e.desc ? e.desc : '' }</div>
          </div>
          <div class="listOptions mini">
      `);
      }
      if (e.optional) {
        this.emit(`<ul><li>${ this.translate.instant('Optional') }</li></ul>`);
      }
      if (e.commented || e.input) {
        this.emit(`<ul><li>${ e.commented ? this.translate.instant('Commented') + '&nbsp;' : '' }
                     ${ e.input ? this.translate.instant('Input') : '' }:</li></ul>`);
      }
      if (e.picture) {
        this.emit(`<ul><li>${ this.translate.instant('Picture') }</li></ul>`);
      }
      if (e.infoPic) {
        this.emit(`<ul><li>${ this.translate.instant('Show Picture') }</li></ul>`);
      }
      if (!!e.matID) {
        const material = this.material?.find(m => m.id === e.matID)?.name;
        this.emit(`<ul><li>${ this.translate.instant('Substance') }: ${ material } ${ e.amount }${ e.unit }</li></ul>`);
      }
      if (!!e.devID) {
        const device = this.devices?.find(m => m.id === e.devID)?.name;
        const program = e.program ? `; ${ this.translate.instant('Default Program') }: ${ e.program }` : '';
        this.emit(`<ul><li>${ this.translate.instant('Device:') } ${ device }${ program }</li></ul>`);
      }
      if (e.timed) {
        this.emit(`<ul><li>${ this.translate.instant('Timed') }: ${ format(new Date(e.timed), 'HH:mm:ss') }</li></ul>`);
      }
      if (e.timelimited) {
        this.emit(`<ul><li>${ this.translate.instant('TimeLimited') }: ` +
          `${ format(new Date(e.timelimited), 'HH:mm:ss') }</li></ul>`);
      }
      if (e.showInfo) {
        this.emit(`<ul><li>${ this.translate.instant('ShowInfo') }</li></ul>`);
      }
      if (e.backToStep) {
        this.emit(`<ul><li>${ this.translate.instant('Optional Repetition at Step..') } ${ e.backToStep }</li></ul>`);
      }
      this.emit('</div>');
      this.nl();
    });
  }

  materialItem(items: Material[]) {
    if (!items) {
      return;
    }
    items.forEach(item => {
      this.commonData(item);
      if (!!item.unit && !!item.amount) {
        this.emit(`<ul><li>${ this.translate.instant('Default Amount') }: ${ item.amount } ${ item.unit }</li></ul>`);
      }
      this.emit('</div>');
      this.nl();
      this.addSpace(2);
    });
  }

  deviceItem(items: Device[]) {
    if (!items) {
      return;
    }
    items.forEach(item => {
      this.commonData(item);
      if (!!item.deviceInfo) {
        if (!!item.deviceInfo.acquiredYear) {
          this.emit(`<ul><li>${ this.translate.instant('Acquisition Year') }: ${ item.deviceInfo.acquiredYear }</li></ul>`);
        }
        if (!!item.deviceInfo.serialNumber) {
          this.emit(`<ul><li>${ this.translate.instant('Serial Number') }: ${ item.deviceInfo.serialNumber }</li></ul>`);
        }
        if (item.deviceInfo.maintContract && item.deviceInfo.maintCompany) {
          this.emit(`<ul><li>${ this.translate.instant('Maintenance Contract') }: ${ item.deviceInfo.maintCompany }` +
            `; ${ this.translate.instant('Contact for Maintenance') }: ${ item.deviceInfo.maintContact }</li></ul>`);
        }
      }
      if (!!item.program && item.program.length > 0) {
        this.emit(`<ul><li>${ this.translate.instant('Programs') }:</li>`);
        item.program.forEach(p => {
          this.emit(`<ul><li>${ p }</li></ul>`);
        });
        this.emit('</ul>');
      }
      this.emit('</div>');
      this.nl();
      this.addSpace(2);
    });
  }

  commonData(item: MatDevBase) {
    this.emit(`
          <div class="dataItem">
            <div class="dataName">${ item.name }</div>
            <div class="dataDesc">${ item.description ? this.markdownService.parse(item.description) : '' }</div>
          </div>`);
    this.nl();
    this.addSpace();
    this.emit('<div class="dataParms">');
    if (!!item.manufacturer) {
      this.emit(`<ul><li>${ this.translate.instant('Manufacturer') }: ${ item.manufacturer }</li></ul>`);
    }
    const cat = this.getCategory(item.category);
    if (!!cat) {
      this.emit(`<ul><li>${ this.translate.instant('Type') }: ${ cat }</li></ul>`);
    }
  }

  drugItem(items: Drug[]) {
    if (!items) {
      return;
    }
    items.forEach(item => {
      let distCat = item.dist + ' ' + item.category;
      if (item.category === 'OWN') {
        distCat = this.translate.instant('Own Addition');
      }
      this.emit(`
          <div class="dataItem">
            <div class="dataDrug">${ item.name }</div>
            <div class="dataCompany">${ item.company }</div>
            <div class="dataDrugType">${ distCat }</div>
          </div>`);
      this.emit('</div>');
      this.nl();
      this.addSpace(2);
    });
  }

  theoryItem(items: Doc[]) {
    if (!items) {
      return;
    }

    items.forEach(item => {
      this.emit(`
          <div class="dataItem">
            <div class="dataName">${ item.name }</div>
            <div class="dataDesc">${ item.description ? this.markdownService.parse(item.description) : '' }</div>
          </div>`);
      this.nl();
      this.addSpace();
      this.emit('<div class="dataParms">');
      if (!!item.length) {
        this.emit(`<ul><li>${ this.translate.instant('Length') }: ${ item.length }</li></ul>`);
      }
      const cat = this.getCategory(item.category);
      if (!!cat) {
        this.emit(`<ul><li>${ this.translate.instant('Type') }: ${ cat }</li></ul>`);
      }
      if (!!item.versioned) {
        this.emit(`<ul><li>${ this.translate.instant('Versionen') }: ${ item.versions.length }</li></ul>`);
      }
      this.emit('</div>');
      this.nl();
      this.addSpace(2);
    });
  }

  getCategory(category: number) {
    const ic = this.itemCategories.find(t => t.category === category);
    return ic ? ic[this.translate.currentLang] : null;
  }

  tabletItem(tablets: Tablet[], listIds: Map<string, { name: string; type: string; icon: string; order: string }>) {
    tablets.forEach((t) => {
      this.emit(`
            <div class="dataItem">
              <div class="dataName">${ t.name }</div>
              <div class="dataDesc">`);
      if (!!t.listIds && !t.all) {
        this.emit('<ul>');
        t.listIds.forEach(l => {
          this.emit(`<li>${ listIds.get(l).name }</li>`);
        });
        this.emit('</ul>');
      } else if (t.all) {
        this.emit(this.translate.instant('ALL Control-lists'));
      }
      this.emit(`</div></div>`);
      this.nl();
      this.addSpace(2);
    });
  }

  private emit(content: string) {
    this.printWindow.document.write(content);
  }

  private nl() {
    this.emit('</td></tr><tr><td>');
  }

  private finalize() {
    this.emit(`
              </td>
            </tr>
        </tbody>
        <tfoot>
          <tr>
            <td>
              <div class="footer-space">&nbsp;</div>
            </td>
          </tr>
        </tfoot>
      </table>
    </body>
    </html>`);
  }

  private addNL(html: string) {
    const closed = [];
    let level = 0;
    for (let i = 0; i < html.length; i++) {
      if (html[i] === '<') {
        if (html[i + 1] === '/') {
          level -= 1;
          if (level === 0) {
            closed.push(html.substring(i).search('>') + i);
          }
        } else {
          if (html.substring(i, i + 3) !== '<br' &&
            html.substring(i, i + 3) !== '<hr' &&
            html.substring(i, i + 4) !== '<img' &&
            html.substring(i, i + 4) !== '<col' &&
            html.substring(i, i + 3) !== '<![' &&
            html.substring(i, i + 4) !== '<!--'
          ) {
            level += 1;
          }
        }
      }
    }
    let retHtml = '';
    let x = 0;
    closed.forEach(j => {
      retHtml = retHtml + html.substring(x, j + 1) + '</td></tr><tr><td>';
      x = j + 1;
    });
    return retHtml;
  }
}

