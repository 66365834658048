import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { IonDatetime, PopoverController } from '@ionic/angular';

@Component({
  selector: 'app-time-selector',
  templateUrl: './time-selector.component.html',
  styleUrls: ['./time-selector.component.scss']
})
export class TimeSelectorComponent implements OnInit {
  @ViewChild('datetime') dt: IonDatetime;
  @Input() time: string;

  constructor(public translate: TranslateService,
    private popoverCtrl: PopoverController) { }

  ngOnInit() {}

  cancel() {
    this.popoverCtrl.dismiss(null, 'cancel').then();
  }

  do() {
    this.dt.confirm().then(() => {
      const result = this.dt.value as string;
      this.popoverCtrl.dismiss(result, 'ok').then();
    });
  }
}
