import { Injectable } from '@angular/core';
import { CategoryData } from '../model/category-data';
import { DataKind } from '../model/common-data';
import { TranslateService } from '@ngx-translate/core';
import { child, Database, onValue, ref, remove, set, update } from '@angular/fire/database';

@Injectable({
  providedIn: 'root'
})
export class DataCategoriesService {

  constructor(protected db: Database,
    private translate: TranslateService) {
  }

  getCategories(dataKind: DataKind) {
    return new Promise<CategoryData[]>(resolve => {
      onValue(ref(this.db, `/categories/${ dataKind }`), (snap) => {
        if (snap.exists()) {
          const values = snap.val();
          const result: CategoryData[] = [];
          Object.keys(values).forEach(name => {
            result.push(Object.assign({name}, values[name]));
          });
          this.setTranslation(dataKind, result);
          result.sort((a,b) => a.order - b.order);
          resolve(result);
        } else {
          resolve(null);
        }
      }, {onlyOnce: true});
    });
  }

  getTypes() {
    return new Promise<CategoryData[]>(resolve => {
      onValue(ref(this.db, `/types/modules`), (snap) => {
        if (snap.exists()) {
          const values = snap.val();
          const result: CategoryData[] = [];
          Object.keys(values).forEach(name => {
            result.push(Object.assign({name}, values[name]));
          });
          this.setTranslation('modules', result);
          resolve(result);
        } else {
          resolve(null);
        }
      }, {onlyOnce: true});
    });
  }

  addCategoryData(dataKind: DataKind, item: CategoryData) {
    const itemName = item.name;
    const itemData = {...item};
    delete itemData.name;
    const nameRef = child(ref(this.db, `/categories/${ dataKind }`), itemName);
    this.setOneTranslation(dataKind, item);
    return set(nameRef, item).then(() => nameRef.key);
  }

  removeCategoryData(dataKind: DataKind, itemName: string) {
    remove(ref(this.db, `/categories/${ dataKind }/${ itemName }`)).then();
  }

  updateCategoryData(dataKind: DataKind, item: CategoryData) {
    const itemName = item.name;
    const itemData = {...item};
    delete itemData.name;
    this.setOneTranslation(dataKind, item);
    update(ref(this.db, `/categories/${ dataKind }/${ itemName }`), item).then();
  }

  updateCategoryAll(dataKind: DataKind, data: CategoryData[]) {
    const writeData = {};
    data.forEach(d => {
      const itemData = {...d};
      delete itemData.name;
      writeData[d.name] = itemData;
    });
    set(ref(this.db, `/categories/${ dataKind }`), writeData).then();
  }

  setTranslation(kind: string, data: CategoryData[]) {
    const type = kind.toUpperCase() + '.';
    data.forEach(item => {
      const key = type + item.name;
      this.translate.setTranslation('en', {[key]: item.en}, true);
      this.translate.setTranslation('de', {[key]: item.de}, true);
    });
  }

  setOneTranslation(kind: string, item: CategoryData) {
    const type = kind.toUpperCase() + '.';
    const key = type + item.name;
    this.translate.setTranslation('en', {[key]: item.en}, true);
    this.translate.setTranslation('de', {[key]: item.de}, true);
  }

}
