import { Log } from '../model/log';
import { format } from 'date-fns-tz';

export const Lead0 = (n: number) => n < 10 ? `0${ n }` : `${ n }`;

export const StrDate = (n: number) => !!n ? format(new Date(n), 'yyyy-MM-dd HH:mm:ss') : '';
export const NiceDate = (d: Date) => !!d ? format(d, 'dd. MMM yyyy') : '';
export const StrTime = (n: number) => !!n ? format(new Date(n), 'HH:mm') : '';

export class YearMonth {
  year: number;
  month: number;

  constructor(y: number = 0, m: number = 0) {
    if (y === 0 && m === 0) {
      const d = new Date();
      this.year = d.getUTCFullYear();
      this.month = d.getUTCMonth();
    } else {
      this.year = y;
      this.month = m;
    }
  }

  public equals(other: YearMonth): boolean {
    return !!other && this.year === other.year && this.month === other.month;
  }
}

export class DateUtil {
  static WEEKDAYS = {
    en: ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun'],
    de: ['Mo', 'Di', 'Mi', 'Do', 'Fr', 'Sa', 'So']
  };
  static DAY = 24 * 3600;
  static WEEK = 24 * 3600 * 6;
  static MONTHLENGTH = [31, 28, 31, 30, 31, 30, 31, 31, 30, 31, 30, 31];

  static ThisMonth() {
    const d = new Date();
    return new YearMonth(d.getUTCFullYear(), d.getUTCMonth());
  }

  static StrTime(item: Log) {
    if (item.endDate > 0) {
      let n = item.endDate - item.startDate;
      if (n < 0) { // this is due to a bug
        n = -1 * n;
      }
      n = Math.floor(n / 1000);
      let min = Math.floor(n / 60);
      const sec = n % 60;
      const hr = Math.floor(min / 60);
      min -= hr * 60;
      return `${ Lead0(hr) }:${ Lead0(min) }:${ Lead0(sec) }`;
    } else {
      return '-';
    }
  }

  static StepTime(t: number, start: number) {
    if (!t) {
      return '';
    }
    return format(new Date(t - start), 'HH:mm:ss');
  }

  static GetDuration(duration: number) {
    const min = Math.floor(duration / 1000 / 60);
    const sec = Math.round(duration / 1000) % 60;
    return (min > 0 ? min + ' min / ' : '') + sec + ' s';
  }

  static SetTimed(isTimed: boolean, tstring: string) {
    if (isTimed) {
      const d = new Date('1970-01-01T' + tstring + 'Z');  // keep to the unix start/stop, Z assures UTC
      return d.getTime();
    } else {
      return 0;
    }
  }

  static TimedFormat(timed: number) {
    const t = timed / 1000;
    const sec = t % 60;
    const min = Math.floor(t / 60) % 60;
    const hr = Math.floor(t / 3600);
    return Lead0(hr) + ':' + Lead0(min) + ':' + Lead0(sec);
  }
}
