import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { TranslateService } from '@ngx-translate/core';
import { SelectorModalComponent } from '../selector-modal/selector-modal.component';

@Component({
  selector: 'app-selector',
  templateUrl: './selector.component.html',
  styleUrls: ['./selector.component.scss'],
})
export class SelectorComponent implements OnInit {
  @Input() items: { name: string; id: string }[];
  @Input() multi = false;
  @Input() disabled = false;
  @Input() firstOnEmpty = false;
  @Input() title: string;
  @Input() selected: string | string[];
  @Output() value = new EventEmitter<string | string[]>();
  text: string;
  mySelected: string | string[];

  constructor(private modalCtrl: ModalController,
    private translate: TranslateService) { }

  ngOnInit() {
    if (!!this.selected) {
      if (this.multi) {
        if (typeof this.selected === 'string') {
          throw new Error('Wrong Input Type: string for multi');
        }
        this.mySelected = [...(this.selected as string[])];
      } else {
        if (typeof this.selected !== 'string') {
          throw new Error('Wrong Input Type: string[] for non-multi');
        }
        this.mySelected = (this.selected as string).repeat(1);
      }
    }
    this.setText(this.selected);
  }

  showSelection() {
    this.modalCtrl.create({
      component: SelectorModalComponent,
      componentProps: {
        items: this.items,
        multi: this.multi,
        firstOnEmpty: this.firstOnEmpty,
        title: this.title,
        selected: this.selected
      }
    }).then(modal => {
      modal.present().then();
      return modal.onDidDismiss();
    }).then(result => {
      if (result.role === 'ok') {
        this.setText(result.data);
        this.value.emit(result.data);
      }
    });
  }

  setText(text: string | string[]) {
    if (text === undefined) {
      this.text = this.firstOnEmpty ? this.items[0].name : this.translate.instant('Please select');
      return;
    }
    if (!this.multi) {
      const item = this.items.find(i => i.id === text);
      if (!!item) {
        this.text = item.name;
      }
    } else {
      const items = this.items.filter(i => text.includes(i.id));
      const selectedTxt = this.translate.instant('Selected');
      const ofTxt = this.translate.instant('of');
      this.text = `${selectedTxt} ${items.length} ${ofTxt} ${this.items.length}`;
    }
  }
}
