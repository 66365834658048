import { IdentityData } from '@medmonitor/authlib';

export class Tablet implements IdentityData {
  id: string;
  listIds: string[];
  favorites?: string[];
  name: string;
  all?: boolean;
}

export class TabletUtil {
  public static DeepCopy(tablet: Tablet): Tablet {
    const newTablet = new Tablet();
    newTablet.name = tablet.name.repeat(1);
    newTablet.id = tablet.id.repeat(1);
    newTablet.listIds = [];
    newTablet.favorites = [];
    newTablet.all = tablet.all ?? false;
    tablet.listIds?.forEach(i => {
      newTablet.listIds.push(i.repeat(1));
    });
    tablet.favorites?.forEach(i => {
      newTablet.favorites.push(i.repeat(1));
    });
    return newTablet;
  }

  public static FilterInvalidIDs(tablt: Tablet, listIDs: string[]) {
    const invalidIDs: string[] = [];
    tablt.listIds?.forEach(llid => {
      let found = false;
      for (const k of listIDs) {
        if (k.localeCompare(llid) === 0) {
          found = true;
        }
      }
      if (!found) {
        invalidIDs.push(llid);
      }
    });
    if (invalidIDs.length > 0) {
      tablt.listIds = tablt.listIds.filter(val => invalidIDs.indexOf(val) < 0);
      // this.tabletService.updateTablet(tablt).then();
    }
  }


}
