import { ControlList } from '../model/controllist';
import { BehaviorSubject } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';
import {
  child,
  Database,
  DatabaseReference,
  off,
  onChildAdded,
  onChildChanged,
  onChildRemoved,
  onValue, push,
  ref, remove, set, update
} from '@angular/fire/database';
import { format } from 'date-fns';
import { Device } from '../model/device';
import { Material } from '../model/material';

export class AddedItems {
  addDeviceNames = new Array<string>();
  addMaterialNames = new Array<string>();
  removeDevice = false;
  listID: string;
}

export class ListServiceBase {
  static MONTHS = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
  static DAY = 3600 * 24 * 1000;
  private static DELAYED_EXEC = null;
  protected _listRef: DatabaseReference;
  protected _theList: ControlList[] = [];
  protected _lists = new BehaviorSubject<ControlList[]>(null);
  private _today: number;
  private _yesterday: number;
  private _tomorrow: number;
  private _typeMap = new Map<string, string>();

  constructor(protected db: Database,
    protected translate: TranslateService) {
    this.setToday();
  }

  public get lists() {
    return this._lists.asObservable();
  }

  public cancelListeners(err = null) {
    if (!!err) {
      console.log(Date.now(), err);
    }
    if (this._listRef) {
      off(this._listRef);
      this._listRef = null;
    }
  }

  public addList(listItem: ControlList) {
    const lRef = push(this._listRef);
    return set(lRef, listItem).then(() => lRef.key);
  }

  public updateList(listItem: ControlList) {
    const upd = Object.assign({}, listItem);
    const id = upd.id;
    delete upd.id;
    return update(child(this._listRef, listItem.id), upd).then(() => id);
  }

  public removeListBase(id: string) {
    remove(child(this._listRef, id)).then();
  }

  typeMap() {
    return this._typeMap;
  }

  // date formatting done
  extractLastExecutions(cl: ControlList) {
    if (!!cl.last) {
      const executions = Object.keys(cl.last).map(v => parseInt(v, 10)).sort((a, b) => b - a);
      cl.executions = executions.map(t => {
        const timeStr = this.getStringDate(t, true);
        return {date: timeStr, user: cl.last[t]};
      });
    }
  }

  // This will get the last 3 executions into the local executions array so that it can be properly displayed.
  getStringDate(t: number, past: boolean) {
    const execTime = new Date(t);
    let timeStr: string;
    const cleanTime = format(execTime, 'HH:mm');
    if ((past && t > this._today) || (!past && t < this._tomorrow)) {
      timeStr = `${ this.translate.instant('Today at') } ${ cleanTime }`;
    } else if (past && t > this._yesterday) {
      timeStr = `${ this.translate.instant('Yesterday at') } ${ cleanTime }`;
    } else {
      timeStr = format(execTime, 'do MMM yy, HH:mm');
    }
    return timeStr;
  }

  protected listen(tenant: string) {
    this._listRef = ref(this.db, `${ tenant }/lists`);
    onChildAdded(this._listRef, (data) => {
      const newListItem = Object.assign({id: data.key}, data.val());
      this.extractLastExecutions(newListItem);
      this._theList.push(newListItem);
      if (ListServiceBase.DELAYED_EXEC) {
        clearTimeout(ListServiceBase.DELAYED_EXEC);
      }
      ListServiceBase.DELAYED_EXEC = setTimeout(() => {
        this.updateTypeMap();
        this._lists.next(this._theList);
        ListServiceBase.DELAYED_EXEC = null;
      }, 200);
    }, this.cancelListeners);
    onChildRemoved(this._listRef, (data) => {
      const i = this._theList.findIndex(l => l.id === data.key);
      if (i >= 0) {
        this._theList.splice(i, 1);
        this.updateTypeMap();
        this._lists.next(this._theList);
      }
    }, this.cancelListeners);
    onChildChanged(this._listRef, (data) => {
      const i = this._theList.findIndex(l => l.id === data.key);
      if (i >= 0) {
        this._theList[i] = Object.assign({id: data.key}, data.val());
        this.extractLastExecutions(this._theList[i]);
        this.updateTypeMap();
        this._lists.next(this._theList);
      }
    }, this.cancelListeners);
  }

  protected async getDeviceFromCatalog(id: string, fromTenant: string = null): Promise<Device> {
    const path = !fromTenant ? '/devices' : `/myDevice/${ fromTenant }`;
    const dataRef = ref(this.db, `${ path }/${ id }`);
    const getVal = new Promise<Device>(resolve => {
      onValue(dataRef, snap => {
        resolve(snap.val() as Device);
      }, {onlyOnce: true});
    });
    return await getVal;
  }

  protected async getMaterialFromCatalog(id: string, fromTenant: string = null): Promise<Material> {
    const path = !fromTenant ? '/material' : `myMaterial/${ fromTenant }`;
    const dataRef = ref(this.db, `${ path }/${ id }`);
    const getVal = new Promise<Material>(resolve => {
      onValue(dataRef, snap => {
        resolve(snap.val() as Material);
      }, {onlyOnce: true});
    });
    return await getVal;
  }

  private updateTypeMap() {
    this._typeMap.clear();
    this._theList.forEach(list => {
      this._typeMap.set(list.id, list.type);
    });
  }

  private setToday() {
    this._today = Math.floor(Date.now() / ListServiceBase.DAY) * ListServiceBase.DAY;
    this._yesterday = this._today - ListServiceBase.DAY;
    this._tomorrow = this._today + ListServiceBase.DAY;
  }

}
