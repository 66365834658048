import { Injectable } from '@angular/core';
import { Database, onValue, ref } from '@angular/fire/database';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class VersionService {
  private _version = new BehaviorSubject<string>('');

  constructor(private db: Database) {
    const dbRef = ref(this.db, 'version/version');
    onValue(dbRef, (snap) => {
      if (snap.exists()) {
        this._version.next(snap.val());
      }
    });
  }

  public get version() {
    return this._version;
  }
}
