import { LoadingController } from '@ionic/angular';
import { FileShowService } from '../services/file-show.service';

export class SafeImage {
  public static OpenFile(path: string, loadingCtrl: LoadingController, fileShow: FileShowService) {
    let bar: any;
    loadingCtrl.create().then(el => {
      bar = el;
      return el.present();
    }).then(() => fileShow.getUrl(path))
    .then(url => {
      bar.dismiss();
      window.open(url, '_blank');
    });
  }

  // public static getContent(path: string, loadingCtrl: LoadingController, fileShow: FileShowService) {
  //   let bar: any;
  //   return loadingCtrl.create().then(el => {
  //     bar = el;
  //     return el.present();
  //   }).then(() => {
  //     return fileShow.getUrl(path);
  //   }).then(url => {
  //     bar.dismiss();
  //     return fetch(url);
  //   }).then(resp => {
  //     console.log(resp.headers);
  //     return resp.body;
  //   });
  // }
}
