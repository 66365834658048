import { Injectable } from '@angular/core';
import { AuthService, AuthUser } from '@medmonitor/authlib';
import { DocSearch } from '../model/doc-search';
import { BehaviorSubject } from 'rxjs';
import { Doc, DocUtil } from '../model/document';
import { DocumentService } from './document.service';

@Injectable({
  providedIn: 'root'
})
export class DocFilterService {
  private _allDocs: Doc[];
  private _catalog: Doc[];
  private _filteredDocs: Doc[] = [];
  private _docs = new BehaviorSubject<Doc[]>([]);
  private _catDocs = new BehaviorSubject<Doc[]>([]);
  private _theoryDocs = new BehaviorSubject<Doc[]>([]);
  private _complete = new BehaviorSubject<number>(0);
  private readonly _filter: DocSearch;
  private _user: AuthUser;

  constructor(private docService: DocumentService,
    private authService: AuthService) {
    this._filter = new DocSearch();
    this.authService.user.subscribe(u => {
      if (!!u) {
        this._user = u;
        this._filter.setDefault();
        this.setModulePercentage();
      }
    });
    this.docService.docs.subscribe(docs => {
      if (!!docs) {
        this._allDocs = docs;
        this.completeDataFromCatalog();
        this.setModulePercentage();
        this.applyFilters();
      }
    });
    this.docService.catalog.subscribe(docs => {
      if (!!docs) {
        this._catalog = docs;
        this.completeDataFromCatalog();
        this.setModulePercentage();
        this._catDocs.next(this._catalog);
      }
    });
  }

  get filter() {
    return this._filter;
  }

  get docs() {
    return this._docs.asObservable();
  }

  get catalog() {
    return this._catDocs.asObservable();
  }

  get theoryDocs() {
    return this._theoryDocs.asObservable();
  }

  theoryModulesCompletedPercentForUser() {
    return this._complete.asObservable();
  }

  search(text: string) {
    this._filter.searchText = text?.toLowerCase();
    this.applyFilters();
  }

  toggleVersion(val: boolean) {
    this._filter.filterVersioned = val;
    this.applyFilters();
  }

  // setVersion(v: boolean) {
  //   this._filter.versioned = v;
  //   this.applyFilters();
  // }

  toggleTheory(val: boolean) {
    this._filter.filterTheory = val;
    this.applyFilters();
  }

  // setTheory(t: boolean) {
  //   this._filter.theory = t;
  //   this.applyFilters();
  // }

  setTags(tags: string[]) {
    this._filter.tags = tags;
    this.applyFilters();
  }

  toggleText(val: boolean) {
    this._filter.showText = val;
    this.applyFilters();
  }

  toggleFile(val: boolean) {
    this._filter.showFile = val;
    this.applyFilters();
  }

  toggleLink(val: boolean) {
    this._filter.showLink = val;
    this.applyFilters();
  }

  public getModulePercentage(userCompleted) {
    return DocUtil.GetModulePercentage(userCompleted, this._theoryDocs.getValue());
  }

  public setModulePercentage() {
    if (!!this._user) {
      const percentage = this.getModulePercentage(this._user.completed);
      this._complete.next(percentage);
    }
  }

  private applyFilters() {
    this._filteredDocs.length = 0;
    this.getFilteredDocs();
    this._docs.next(this._filteredDocs);
  }

  private getFilteredDocs() {
    this._allDocs.forEach(doc => {
      if (
        this.theoryFilter(doc) &&
        this.versionFilter(doc) &&
        this.privateFilter(doc) &&
        this.isInTypes(doc) &&
        this.searchNameDesc(doc) &&
        this.hasTags(doc)) {
        this._filteredDocs.push(doc);
      }
    });
  }

  private theoryFilter(doc: Doc) {
    const catalog = doc.catalog ?? false;
    return this._filter.filterTheory ? (!catalog && this._filter.theory === doc.theory) : !catalog;
  }

  private versionFilter(doc: Doc) {
    return this._filter.filterVersioned ? this._filter.versioned === doc.versioned : true;
  }

  private privateFilter(doc: Doc) {
    return this._user.isAdmin || !doc.private;
  }

  private isInTypes(doc: Doc) {
    if (this._filter.showFile && this._filter.showText && this.filter.showLink) {
      return true;
    }
    return this._filter.showFile && doc.kind === 'File' ||
      this._filter.showLink && doc.kind === 'Link' ||
      this._filter.showText && doc.kind === 'Text';
  }

  private hasTags(doc: Doc) {
    for (const tag of this._filter.tags) {
      if (!doc.tags || !doc.tags.includes(tag)) {
        return false;
      }
    }
    return true;
  }

  private searchNameDesc(doc: Doc) {
    if (!this._filter.searchText) {
      return true;
    }
    if (doc.name.toLowerCase().includes(this._filter.searchText)) {
      return true;
    }
    return !!doc.description ? doc.description.toLowerCase().includes(this._filter.searchText) : false;
  }

  private completeDataFromCatalog() {
    if (this._catalog?.length > 0) {
      this._allDocs.forEach(doc => {
        if (doc.catalog && !!doc.catalogId) {
          const catItem = this._catalog.find(item => item.id === doc.catalogId);
          if (!!catItem) {
            if (!!catItem.name) {
              doc.name = catItem.name.repeat(1);
            }
            if (!!catItem.description) {
              doc.description = catItem.description.repeat(1);
            }
            if (!!catItem.kind) {
              doc.kind = catItem.kind;
            }
            if (!!catItem.extension) {
              doc.extension = catItem.extension.repeat(1);
            }
            if (!!catItem.theory) {
              doc.theory = catItem.theory;
            }
            if (!!catItem.private) {
              doc.private = catItem.private;
            }
            if (!!catItem.versioned) {
              doc.versioned = catItem.versioned;
            }
            if (!!catItem.tags) {
              doc.tags = [...catItem.tags];
            }
            if (!!doc.versions) {
              doc.versions = [...catItem.versions];
            }
            if (!!catItem.category) {
              doc.category = catItem.category;
            }
            if (!!catItem.length) {
              doc.length = catItem.length;
            }
            if (!!catItem.repInterval) {
              doc.repInterval = catItem.repInterval;
            }
            if (!!catItem.rep) {
              doc.rep = catItem.rep;
            }
          }
        }
      });
    }
    this._theoryDocs.next(this._allDocs.filter(d => d.theory).sort((a, b) => a.name.localeCompare(b.name)));
  }
}
