import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { CategoryData } from '../../model/category-data';
import { ControlListType, INPUT_DRUG, INPUT_NUMBER, INPUT_TEXT, ListItem, ListUtil } from '../../model/controllist';
import { AlertController, ModalController, PickerController, PopoverController } from '@ionic/angular';
import { TranslateService } from '@ngx-translate/core';
import { Subscription } from 'rxjs';
import { Device } from '../../model/device';
import { MatDevBase } from '../../model/mat-dev-base';
import { Material } from '../../model/material';
import { ShowAlert } from '../../util/show-alert';
import { DefaultSelectionComponent } from '../default-selection/default-selection.component';
import { DataCategoriesService } from '../../services/data-categories.service';
import { DateUtil } from '../../util/dateutil';

@Component({
  selector: 'app-newitem',
  templateUrl: './newitem.component.html',
  styleUrls: ['./newitem.component.scss']
})
export class NewItemComponent implements OnInit, OnDestroy {
  static HOURS = [...Array.from(Array(24).keys()), 48, 72, 96, 120];
  static MINSEC = Array.from(Array(60).keys());

  static COL_HOURS = NewItemComponent.HOURS.map(h => Object.assign({}, {
    text: `${ h }`,
    value: h > 9 ? `${ h }` : `0${ h }`
  }));
  static COL_MINS = NewItemComponent.MINSEC.map(h => Object.assign({}, {
    text: `${ h }`,
    value: h > 9 ? `${ h }` : `0${ h }`
  }));
  static COL_SEC = NewItemComponent.MINSEC.map(h => Object.assign({}, {
    text: `${ h }`,
    value: h > 9 ? `${ h }` : `0${ h }`
  }));
  @Input() item: ListItem = null;
  @Input() itemNames: string[];
  @Input() currentIndex: number;
  @Input() ltype: ControlListType = null;
  @Input() prevZone = 0;
  @Input() materials: Material[];
  @Input() devices: Device[];
  role: string;
  timedItem: boolean;
  previousItems: string[];
  newitem: ListItem;
  timedstring: string;
  materialCategories: CategoryData[] = [];
  devCategories: CategoryData[];
  hasMaterial = false;
  materialType = -1;
  defaultMaterial = '';
  hasDevice = false;
  deviceType = -1;
  defaultDevice: Device;
  defaultProgram = '';
  timeLimited: boolean;
  timeLimitedString: string;
  backToStep = 0;
  hasBackToStep = false;
  picmimeType: 'image/gif,image/jpeg,image/bmp,image/tif,image/png,image/svg+xml';
  inputText = INPUT_TEXT;
  inputNumber = INPUT_NUMBER;
  inputDrug = INPUT_DRUG;
  private _sub: Subscription;
  private _showAlert: ShowAlert;

  constructor(private modalCtrl: ModalController,
    private alertCtrl: AlertController,
    private pickerCtrl: PickerController,
    private popoverCtrl: PopoverController,
    private dataTypeSvc: DataCategoriesService,
    public translate: TranslateService) {
    this._showAlert = new ShowAlert(alertCtrl, translate);
  }

  ngOnInit() {
    if (this.item) {
      this.role = 'edit';
      this.newitem = ListUtil.DeepCopyItem(this.item);
      this.timedItem = !!this.newitem.timed;
      this.timeLimited = !!this.newitem.timelimited;
      if (!!this.newitem.matID) {
        this.hasMaterial = true;
        this.materialType = this.newitem.materialType;
        this.defaultMaterial = this.newitem.matID;
      }
      if (!!this.newitem.devID) {
        this.hasDevice = true;
        this.deviceType = this.newitem.deviceType;
        this.defaultProgram = this.newitem.program;
      }
      if (!this.timedItem) {
        this.newitem.timed = 60000;
      }
      if (!this.timeLimited) {
        this.newitem.timelimited = 300000;
      }
      if (this.newitem.backToStep !== undefined) {
        this.backToStep = this.item.backToStep;
        this.hasBackToStep = true;
      }
    } else {
      this.role = 'save';
      this.timedItem = false;
      this.timeLimited = false;
      this.newitem = new ListItem();
      this.newitem.timed = 60000; // default is 1 minute
      this.newitem.timelimited = 300000; // default is 5 minutes
      this.newitem.showInfo = false;
      this.newitem.inputType = INPUT_TEXT;
      this.materialType = -1;
      this.deviceType = -1;
      this.newitem.changeMaterial = true;
      this.newitem.changeDevice = true;
      if (this.ltype === 'steril') {
        this.newitem.timeblocked = true;
        if (this.prevZone > 0) {
          this.newitem.zone = this.prevZone;
        } else {
          this.newitem.zone = -0.1;
        }
      }
    }
    this.timedstring = DateUtil.TimedFormat(this.newitem.timed);
    this.timeLimitedString = DateUtil.TimedFormat(this.newitem.timelimited);

    this.dataTypeSvc.getCategories('material').then(items => {
      if (!!items) {
        this.materialCategories = [...items].sort((a, b) => a.order - b.order);
      }
    });
    this.dataTypeSvc.getCategories('devices').then(items => {
      if (!!items) {
        this.devCategories = [...items].sort((a, b) => a.order - b.order);
      }
    });

    if (this.hasMaterial) {
      const selectedMaterial = this.materials.find(m => m.id === this.newitem.matID);
      if (!!selectedMaterial) {
        this.defaultMaterial = selectedMaterial.name;
      } else {
        this.defaultMaterial = '';
        this.hasMaterial = false;
        this.materialType = -1;
        this.newitem.unit = undefined;
        this.newitem.amount = 0;
      }
    }

    if (this.hasDevice) {
      this.defaultDevice = this.devices.find(m => m.id === this.newitem.devID);
    }

    this.previousItems = [...this.itemNames];
    if (this.currentIndex >= 0) {
      this.previousItems.splice(this.currentIndex);
      this.itemNames.splice(this.currentIndex, 1);
    }
  }

  ngOnDestroy() {
    if (this._sub) {
      this._sub.unsubscribe();
    }
  }

  onCancel() {
    this.modalCtrl.dismiss(null, 'cancel').then();
  }

  async onAddItem() {
    if (!!this.newitem.infoPic && !this.newitem.infoPicFile && !this.newitem.infoPicLink) {
      await this._showAlert.withHeader('No File')
        .withMessage('You have to set a file if Show Picture is set')
        .addOkButton().show();
      return;
    }
    if (this.itemNames.findIndex(name => name === this.newitem.name) >= 0) {
      await this._showAlert.withHeader('Name not unique')
        .withMessage('This step name already exists: ', this.newitem.name)
        .addOkButton().show();
      return;
    }
    this.newitem.timed = DateUtil.SetTimed(this.timedItem, this.timedstring);
    this.newitem.timelimited = DateUtil.SetTimed(this.timeLimited, this.timeLimitedString);
    await this.modalCtrl.dismiss(this.newitem, this.role);
  }

  async onDeleteItem() {
    const choice = await this._showAlert.withHeader('Delete step')
    .withMessage('Really delete step? ', this.newitem.name)
    .addYesDeleteButton()
    .addNoButton()
    .show();
    if (choice === 'yes') {
      this.modalCtrl.dismiss(null, 'delete').then();
    }
  }

  setDefaultMaterial() {
    if (!this.hasMaterial || this.materialType < 0) {
      return;
    } else {
      this.newitem.materialType = this.materialType;
      const list = this.materials.filter(m => m.category === this.newitem.materialType);
      this.popoverCtrl.create({
        component: DefaultSelectionComponent,
        backdropDismiss: false,
        componentProps: {list: list.map(l => l.name), kind: 'material'}
      }).then(el => {
        el.present().then();
        return el.onDidDismiss();
      }).then(result => {
        if (result.role === 'ok') {
          const chosenMaterial = this.materials.find(m => m.name === result.data);
          this.newitem.matID = chosenMaterial.id;
          this.defaultMaterial = chosenMaterial.name;
          this.newitem.unit = chosenMaterial.unit;
          if (!!chosenMaterial.amount) {
            this.newitem.amount = chosenMaterial.amount;
          }
        }
      });
    }
  }

  resetMaterial() {
    delete this.newitem.matID;
    delete this.newitem.materialType;
    delete this.newitem.unit;
    delete this.newitem.amount;
    this.defaultMaterial = '';
  }

  toggleMaterial() {
    if (!this.hasMaterial) {
      this.resetMaterial();
    }
  }

  defaultName(type: number, material: boolean) {
    if (type < 0) {
      return this.translate.instant('Please select');
    }
    const categories = material ? this.materialCategories : this.devCategories;
    if (!categories) { // initialization did not complete. RARE.
      return '';
    }
    const cat = categories.find(x => x.category === type);
    return !!cat ? cat[this.translate.currentLang] : '';
  }

  async setDefaultDevice() {
    if (!this.hasDevice || this.deviceType < 0) {
      return;
    }
    this.newitem.deviceType = this.deviceType;
    const list = this.devices.filter(m => m.category === this.newitem.deviceType);
    const popover = await this.popoverCtrl.create({
      component: DefaultSelectionComponent,
      componentProps: {list: list.map(l => l.name), kind: 'devices'}
    });
    await popover.present();
    const result = await popover.onDidDismiss();
    if (result.role === 'ok') {
      const chosenDevice: Device = this.devices.find(l => l.name === result.data);
      this.defaultDevice = chosenDevice;
      if (chosenDevice.program && chosenDevice.program.length > 0) {
        this.defaultProgram = this.newitem.devID === chosenDevice.id && !!this.newitem.program
                              ? this.newitem.program : chosenDevice.program[0];
        this.newitem.program = this.defaultProgram;
      }
      this.newitem.devID = chosenDevice.id;
    }
  }

  setDefaultProgram() {
    this.popoverCtrl.create({
      component: DefaultSelectionComponent,
      componentProps: {list: this.defaultDevice.program, kind: 'devices'}
    }).then(el => {
      el.present().then();
      return el.onDidDismiss();
    }).then(result => {
      if (result.role === 'ok') {
        this.newitem.program = result.data;
        this.defaultProgram = result.data;
      }
    });
  }

  resetDevice() {
    delete this.newitem.devID;
    delete this.newitem.deviceType;
    delete this.newitem.program;
    this.deviceType = -1;
    this.defaultDevice = undefined;
  }

  toggleDevice() {
    if (!this.hasDevice) {
      this.resetDevice();
    }
  }

  isValid() {
    const invalid = !this.newitem.name || this.newitem.name.length === 0 || !this.newitem.desc || this.newitem.desc.length === 0;
    const badMaterial = this.hasMaterial && !this.defaultMaterial;
    return !invalid && !badMaterial;
  }

  resetForInput(input: boolean) {
    if (input) {
      this.newitem.commented = false;
      this.newitem.picture = false;
    }
  }

  resetForComment(commented: boolean) {
    if (commented) {
      this.newitem.input = false;
      this.newitem.picture = false;
    }
  }

  resetForPicture(picture: boolean) {
    if (picture) {
      this.newitem.commented = false;
      this.newitem.input = false;
    }
  }

  removeBackToStep() {
    if (this.hasBackToStep) {
      delete this.newitem.backToStep;
    } else {
      if (this.newitem.backToStep === undefined) {
        if (this.previousItems.length > 0) {
          this.newitem.backToStep = this.previousItems.length - 1;
        } else {
          this.hasBackToStep = true;
        }
      }
    }
  }

  setUploadPic(event) {
    const files = event.target.files;
    if (files.length > 0) {
      this.newitem.infoPicFile = files.item(0);
      delete this.newitem.infoPicLink;
    }
  }

  getTranslatedName(t: CategoryData) {
    return !!t ? t[this.translate.currentLang] : '';
  }

  getCategories(categories: CategoryData[], items: MatDevBase[]): CategoryData[] {
    const list: CategoryData[] = [];
    if (!categories) {
      return list;
    }
    for (const x of categories) {
      if (items.filter(m => m.category === x.category).length > 0) {
        list.push(x);
      }
    }
    return list;
  }

  async selectTime() {
    this.timedstring = await this.timeSelect(this.timedstring);
  }

  async selectTimeLimit() {
    this.timeLimitedString = await this.timeSelect(this.timeLimitedString);
  }

  async timeSelect(tstr: string) {
    const t = tstr ?? '00:03:00';
    const el = await this.pickerCtrl.create({
      columns: [
        {
          name: 'hours',
          options: NewItemComponent.COL_HOURS
        },
        {
          name: 'mins',
          options: NewItemComponent.COL_MINS
        },
        {
          name: 'secs',
          options: NewItemComponent.COL_SEC
        }
      ],
      buttons: [
        {
          text: this.translate.instant('Cancel'),
          role: 'cancel'
        },
        {
          text: 'OK',
          role: 'ok'
        }
      ]

    });
    el.columns[0].selectedIndex = +t.split(':')[0];
    el.columns[1].selectedIndex = +t.split(':')[1];
    el.columns[2].selectedIndex = +t.split(':')[2];
    await el.present();
    const val = await el.onDidDismiss();
    if (val.role === 'ok') {
      return (val.data.hours.value) + ':' + (val.data.mins.value) + ':' + (val.data.secs.value);
    } else {
      return tstr;
    }
  }

}
