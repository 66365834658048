import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { IonicModule } from '@ionic/angular';
import { FormsModule } from '@angular/forms';
import { DocSelectionComponent } from '../doc-selection/doc-selection.component';
import { ListSelectionComponent } from '../list-selection/list-selection.component';
import { SelectorModalComponent } from '../selector-modal/selector-modal.component';
import { SelectorComponent } from '../selector/selector.component';
import { ShowHelpComponent } from '../show-help/show-help.component';
import { HomePage } from './home.page';

import { HomePageRoutingModule } from './home-routing.module';
import { NavbuttonsComponent } from '../navbuttons/navbuttons.component';
import { ProfileComponent } from '../profile/profile.component';
import { UseradminComponent } from '../useradmin/useradmin.component';
import { TranslateModule } from '@ngx-translate/core';
import { NewuserComponent } from '../useradmin/newuser/newuser.component';
import { EdituserComponent } from '../useradmin/edituser/edituser.component';
import { DateStatusComponent } from '../datestatus/datestatus.component';
import { ListStatusComponent } from '../liststatus/list-status.component';
import { ListsdueComponent } from '../listsdue/listsdue.component';
import { BarchartComponent } from '../barchart/barchart.component';
import { LangselectComponent } from '../langselect/langselect.component';
import { TabletinfoComponent } from '../tabletinfo/tabletinfo.component';
import { TabletlistComponent } from '../tabletinfo/tabletlist/tabletlist.component';
import { MorebuttonsComponent } from '../morebuttons/morebuttons.component';
import { CatalogButtonComponent } from '../catalog-button/catalog-button.component';
import { FooterInfoComponent } from '../footer-info/footer-info.component';
import { HeaderComponent } from '../header/header.component';
import { NgChartsModule } from 'ng2-charts';
import { DateSelectorComponent } from '../date-selector/date-selector.component';


@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    IonicModule,
    HomePageRoutingModule,
    TranslateModule,
    NgChartsModule
  ],
  exports: [
    ProfileComponent,
    NavbuttonsComponent,
    UseradminComponent,
    NewuserComponent,
    EdituserComponent,
    DateStatusComponent,
    ListStatusComponent,
    LangselectComponent,
    MorebuttonsComponent,
    CatalogButtonComponent,
    FooterInfoComponent,
    HeaderComponent,
    DateSelectorComponent,
    ListSelectionComponent,
    DocSelectionComponent,
    SelectorModalComponent,
    SelectorComponent,
    ShowHelpComponent
  ],
  declarations: [
    HomePage,
    NavbuttonsComponent,
    ProfileComponent,
    UseradminComponent,
    NewuserComponent,
    EdituserComponent,
    DateStatusComponent,
    ListStatusComponent,
    ListsdueComponent,
    BarchartComponent,
    LangselectComponent,
    TabletinfoComponent,
    TabletlistComponent,
    MorebuttonsComponent,
    CatalogButtonComponent,
    FooterInfoComponent,
    HeaderComponent,
    DateSelectorComponent,
    ListSelectionComponent,
    DocSelectionComponent,
    SelectorModalComponent,
    SelectorComponent,
    ShowHelpComponent
  ]
})
export class HomePageModule {
}
