import { ListUtil } from './controllist';

export type DocKind = 'Text' | 'Link' | 'File';

export class Doc {
  id?: string;
  name?: string;
  description?: string;
  kind?: DocKind;
  extension?: string;
  theory?: boolean;
  private?: boolean;
  versioned?: boolean;
  tags?: string[];
  versions?: DocVersion[];
  category?: number;
  length?: number;
  repInterval?: number;
  rep?: number;
  catalog?: boolean;
  catalogId?: string;

  static Create() {
    const doc = new Doc();
    doc.tags = [];
    doc.kind = 'Text';
    doc.versioned = true;
    doc.theory = false;
    doc.catalog = false;
    doc.versions = [];
    doc.versions.push(new DocVersion());
    return doc;
  }
}

export class DocVersion {
  id?: string;
  created: number;
  modified: number;
  createdBy: string;
  modifiedBy: string;
  comment?: string;
  released?: number;
  releasedBy?: string;
  data: string;  // actual file name in google storage (for file and html text) or actual link
}

export class DocUtil {
  static DeepCopy(doc: Doc, fromCatalog = false): Doc {
    const newDoc = new Doc();
    if (!!doc.id) {
      newDoc.id = doc.id.repeat(1);
    }
    if (!!doc.name) {
      newDoc.name = doc.name.repeat(1);
    }
    newDoc.kind = doc.kind ?? null;
    if (!!doc.description) {
      newDoc.description = doc.description.repeat(1);
    }
    if (!!doc.extension) {
      newDoc.extension = doc.extension.repeat(1);
    }
    if (!!doc.theory) {
      newDoc.theory = doc.theory;
    }
    if (!!doc.versioned) {
      newDoc.versioned = doc.versioned;
    }
    if (!!doc.tags) {
      newDoc.tags = [...doc.tags];
    } else {
      newDoc.tags = [];
    }
    if (!!doc.versions && !fromCatalog) {
      newDoc.versions = [...doc.versions];
    } else {
      newDoc.versions = [];
      newDoc.versions.push(new DocVersion());
    }
    if (!!doc.category) {
      newDoc.category = doc.category;
    }
    if (!!doc.private) {
      newDoc.private = doc.private;
    }
    if (!!doc.rep) {
      newDoc.rep = doc.rep;
    }
    if (!!doc.repInterval) {
      newDoc.repInterval = doc.repInterval;
    }
    if (!!doc.length) {
      newDoc.length = doc.length;
    }
    if (!!doc.catalog && !fromCatalog) {
      newDoc.catalog = doc.catalog;
    }
    if (!!doc.catalogId && !fromCatalog) {
      newDoc.catalogId = doc.catalogId.repeat(1);
    }
    return newDoc;
  }

  static CleanTheory(doc: Doc) {
    if (!doc.theory) {
      doc.category = null;
      doc.length = null;
      doc.repInterval = null;
      doc.rep = null;
    } else {
      if (doc.length < 1) {
        doc.length = 1;
      }
    }
  }

  public static IsModuleCompleted(mod: Doc, id: string, completed: any): boolean {
    if (!mod) {
      return false;
    }
    if (!!completed && Object.keys(completed).includes(id)) {
      return (!mod.repInterval || !mod.rep) ||
        ListUtil.NextMaintAt(completed[id], mod.repInterval, mod.rep) - Date.now() > 0;
    }
    return false;
  }

  public static GetModulePercentage(userCompleted: any, docs: Doc[]) {
    if (!userCompleted) {
      return 0;
    }
    let completed = 0;
    docs.forEach(doc => {
      const id = doc.versions[0].id;
      completed += DocUtil.IsModuleCompleted(doc, id, userCompleted) ? 1 : 0;
    });
    return docs.length > 0 ? Math.round((completed / docs.length) * 100) : 100;
  }

}
