import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { AuthService, AuthUser, NewUser } from '@medmonitor/authlib';
import { TranslateService } from '@ngx-translate/core';
import { Subscription } from 'rxjs';
import {
  CommonDataUploadProgressComponent
} from '../common-data-upload-progress/common-data-upload-progress.component';
import { TitleInfo } from '../model/title-info';
import { TitleService } from '../services/data.service';
import { FileUploadService } from '../services/file-upload.service';
import { TenantUserService } from '../services/tenant-user.service';
import { AlertController, ModalController, PopoverController } from '@ionic/angular';
import { NewuserComponent } from './newuser/newuser.component';
import { EdituserComponent } from './edituser/edituser.component';
import { DocFilterService } from '../services/doc-filter.service';

@Component({
  selector: 'app-useradmin',
  templateUrl: './useradmin.component.html',
  styleUrls: ['./useradmin.component.scss']
})
export class UseradminComponent implements OnInit, OnDestroy {
  @Input() tenant: string = null;
  @Input() tenantName: string = null;
  @Input() admin = false;
  userList: AuthUser[];
  userByID = new Map<string, AuthUser>();
  private _sub: Subscription;
  private _titles = new Map<string, TitleInfo>();
  private _user: AuthUser;

  constructor(public translate: TranslateService,
    private authService: AuthService,
    private alertController: AlertController,
    private modalCtrl: ModalController,
    private popoverCtrl: PopoverController,
    private docFilterService: DocFilterService,
    private tenantUserService: TenantUserService,
    private titleService: TitleService,
    private fileUploads: FileUploadService) {
  }

  ngOnInit() {
    this._sub = this.tenantUserService.data.subscribe(u => {
      if (!!u) {
        this.userList = u.filter(x => !x.deleted);
        this.userList.sort((a, b) => a.name.localeCompare(b.name));
        for (const user of this.userList) {
          user.tenant = this.tenant;
          user.tenantName = this.tenantName;
          this.userByID.set(user.id, user);
        }
      }
    });
    this._sub.add(this.titleService.data.subscribe(t => {
      t.forEach(title => this._titles.set(title.id, title));
    }));
    this._sub.add(this.authService.user.subscribe(u => {
      this._user = u;
    }));
  }

  ngOnDestroy() {
    this._sub?.unsubscribe();
  }

  addUser() {
    let user: AuthUser = null;
    this.modalCtrl.create({
      component: NewuserComponent,
      canDismiss: true,
      componentProps: {
        tenant: this.tenant,
        tenantName: this.tenantName,
        currentEmails: this.userList.map(u => u.email),
        short: false
      }
    }).then(el => {
      el.present().then();
      return el.onDidDismiss();
    }).then(result => {
      if (result.role === 'save') {
        const newUser: NewUser = result.data as NewUser;
        user = newUser.toUser();
        return this.tenantUserService.newUser(newUser);
      } else {
        return Promise.resolve(false);
      }
    }).then(succeed => {
      if (!succeed) {
        console.log('no user created');
      } else {
        user.tenant = this.tenant;
        this.userList.push(user);
      }
    })
    .catch(err => {
      console.log('ERROR', err);
    });
  }

  isLastAdmin(admin: boolean) {
    return admin && this.userList.filter(u => u.isAdmin === true).length === 1;
  }

  async delete(user: AuthUser) {
    const alert = await this.alertController.create({
      header: this.translate.instant('Delete'),
      message: this.translate.instant('Really delete user?'),
      buttons: [
        {
          text: this.translate.instant('Cancel'),
          role: 'cancel'
        }, {
          text: this.translate.instant('Yes, delete'),
          handler: () => {
            this.tenantUserService.removeUser(user).then(() => {
              if (!!user.docs && user.docs.length > 0) {
                for (const doc of user.docs) {
                  const path = `${ user.tenant }/users/`;
                  this.fileUploads.deleteFileFromStorage(path + doc);
                }
              }
            });
          }
        }
      ]
    });
    await alert.present();
  }

  edit(user: AuthUser) {
    if (!this.admin) {
      return;
    }
    if (!user.id) {
      this.alertController.create({
        message: this.translate.instant('User not ready yet'),
        buttons: [{text: 'OK'}]
      }).then(al => {
        al.present().then();
      });
      return;
    }
    const origUser = AuthUser.Duplicate(user);
    this.modalCtrl.create({
      component: EdituserComponent,
      canDismiss: true,
      componentProps: {
        tenant: this.tenant,
        user: origUser,
        disableAdmin: this.isLastAdmin(user.isAdmin),
        short: false
      }
    }).then(el => {
      el.present().then();
      return el.onDidDismiss();
    }).then((result) => {
      if (result.role === 'save') {
        const saveUser = {...origUser} as AuthUser;
        delete saveUser.tenantName;
        delete saveUser.tenant;
        this.tenantUserService.setData(saveUser).then(() => {
          this.tenantUserService.setAdmin(origUser);
          // if I change myself, I want to see the change now
          if (origUser.id === this._user.id) {
            this.authService.setUser(origUser);
          }
        });
        this.saveFiles();
      } else if (result.role === 'delete') {
        this.delete(origUser).then();
      }
    }).catch(err => {
      console.log('ERROR', err);
    });
  }

  getCompletion(id: string) {
    if (!!this.userByID.get(id)) {
      return this.docFilterService.getModulePercentage(this.userByID.get(id).completed);
    }
    return 0;
  }

  saveFiles() {
    let bar = null;
    if (this.fileUploads.numberOfFilesToUpload > 0) {
      this.popoverCtrl.create({
        component: CommonDataUploadProgressComponent
      }).then(el => {
        bar = el;
        el.present().then();
        return el.onDidDismiss();
      }).then();
    }
    this.fileUploads.save().then(() => {
      // do not overwrite this, this is handled through the list update.
      bar?.dismiss().then();
    }).catch(() => {
      bar?.dismiss().then();
    });

  }

  getTitle = (role: string) => this._titles.get(role)?.short[this.translate.currentLang];
}
