import { Injectable } from '@angular/core';
import { AlertController } from '@ionic/angular';
import { AuthService, AuthUser } from '@medmonitor/authlib';
import { ShowAlert } from '../util/show-alert';
import { TranslateService } from '@ngx-translate/core';
import { TabletService } from './tablet.service';
import { AddedItems, ListServiceBase } from './list-service-base';
import { ControlList, ListUtil } from '../model/controllist';
import { child, Database, equalTo, onValue, orderByChild, push, query, ref, set } from '@angular/fire/database';

@Injectable({
  providedIn: 'root'
})
export class ListsService extends ListServiceBase {
  private _user: AuthUser;
  private _catalog = false;
  private _alert: ShowAlert;

  constructor(private authService: AuthService,
              protected db: Database,
              private tabletService: TabletService,
              private alertCtrl: AlertController,
              protected translate: TranslateService) {
    super(db, translate);
    this._alert = new ShowAlert(alertCtrl, translate);
    this.authService.user.subscribe(u => {
      this.cancelListeners();
      this._user = u;
      if (!!u) {
        this._theList.length = 0;
        this.listen(`/tenants/${u.tenant}`);
      }
    });
  }


  public removeList(id: string) {
    this.removeListBase(id);
    this.tabletService.removeList(id);
  }

  public setCatalog() {
    this._theList.length = 0;
    this.cancelListeners();
    this._catalog = true;
    this.listen('');
  }

  public setRegular() {
    if (this._catalog) {
      this._theList.length = 0;
      this.cancelListeners();
      this._catalog = false;
      this.listen(`/tenants/${this._user.tenant}`);
    }
  }

  public hasList(name: string) {
    return new Promise<boolean>(resolve => {
      onValue(query(ref(this.db, `/tenants/${this._user.tenant}/lists`), orderByChild('name'),
        equalTo(name)), snap => {
        resolve(snap.exists());
      }, {onlyOnce: true});
    });
  }

  public async addListFromCatalog(list: ControlList) {
    const dbRef = push(ref(this.db, `/tenants/${this._user.tenant}/lists`));
    const content = ListUtil.DeepCopy(list);
    content.completions = 0;
    delete content.id;
    delete content.alert;
    delete content.last;
    delete content.nextMaintenance;
    const retVal = new AddedItems();
    retVal.listID = dbRef.key;
    if (!!content.deviceID) {
      // add device to my list of devices
      const addD = await this.addDataFromCatalog(content.deviceID);
      content.deviceID = addD.id;
      if (addD.newItem) {
        retVal.addDeviceNames.push(content.deviceName);
      }
    }
    for (const item of content.items) {
      if (!!item.devID) {
        const addD = await this.addDataFromCatalog(item.devID);
        item.devID = addD.id;
        if (addD.newItem) {
          retVal.addDeviceNames.push(addD.name);
        }
      }
      if (!!item.matID) {
        const addD = await this.addDataFromCatalog(item.matID, false);
        item.matID = addD.id;
        if (addD.newItem) {
          retVal.addMaterialNames.push(addD.name);
        }
      }
    }
    await set(dbRef, content);
    return retVal;

  }

  public removeMaterial(id: string) {
    let changed = false;
    this._theList.forEach(listItem => {
      listItem.items.forEach((step, index) => {
        if (step.matID === id) {
          const delRef = child(this._listRef, `${listItem.id}/items/${index}`);
          delete step.matID;
          delete step.materialType;
          delete step.unit;
          delete step.amount;
          set(delRef, step).then();
          changed = true;
        }
      });
    });
    if (changed) {
      this._lists.next(this._theList);
    }
  }

  public removeDevice(id: string) {
    let changed = false;
    this._theList.forEach(listItem => {
      if (listItem.deviceID === id) {
        delete listItem.deviceID;
        delete listItem.deviceName;
        changed = true;
      }
      listItem.items.forEach((step, index) => {
        if (step.devID === id) {
          const delRef = child(this._listRef, `${listItem.id}/items/${index}`);
          delete step.devID;
          delete step.deviceType;
          delete step.program;
          set(delRef, step).then();
          changed = true;
        }
      });
    });
    if (changed) {
      this._lists.next(this._theList);
    }
  }

  async addListFromCatalogWithInteractions(list: ControlList) {
    const hasList = await this.hasList(list.name);
    if (hasList) {
      this._alert.withHeader('List Exists').withMessage('TextExists', list.name);
    } else {
      this._alert.withHeader('New List').withMessage('TextNew', list.name);
    }
    const choice = await this._alert.addYesButton().addNoButton().show();
    if (choice === 'yes') {
      const ret = await this.addListFromCatalog(list);
      for (const name of ret.addMaterialNames) {
        await this._alert.withHeader('Substance added').withMessage('TextMatAdd', name)
        .addOkButton().show();
      }
      for (const name of ret.addDeviceNames) {
        await this._alert.withHeader('Device added').withMessage('TextDevAdd', name)
        .addOkButton().show();
      }
      return ret.listID;
    }
    return '';
  }

  private async addDataFromCatalog(catID: string, devMat = true) {
    const path = devMat ? `/myDevice/${this._user.tenant}/` : `/myMaterial/${this._user.tenant}`;
    const existDeviceRef = query(ref(this.db, path), orderByChild('catalogId'), equalTo(catID));
    const getRef = new Promise<string>(resolve => {
      onValue(existDeviceRef, snap => {
        if (snap.exists()) {
          resolve(Object.keys(snap.val())[0]);
        } else {
          resolve(null);
        }
      }, {onlyOnce: true});
    });
    const devID = await getRef;
    if (!!devID) {
      return {id: devID, newItem: false};
    }
    const newDeviceRef = push(ref(this.db, path), {catalogId: catID, catalog: true});
    const namePath = devMat ? '/devices' : '/material';
    const getName = new Promise<string>(resolve => {
      onValue(ref(this.db, namePath + `/${catID}/name`), snap => {
        resolve(snap.val());
      }, {onlyOnce: true});
    });
    const name = await getName;
    return {id: newDeviceRef.key, newItem: true, name};
  }
}
