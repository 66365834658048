import { CategoryData } from './category-data';
import { ListUtil } from './controllist';
import { DataKind } from './common-data';
import { Device, DeviceMetadata } from './device';
import { Material } from './material';

export class CommonUtils {
  // module icons should probably be editable.
  public static MODULE_ICONS = ['newspaper-outline', 'film-outline', 'link-outline', 'image-outline'];
  public static TYPE_CATEGORIES = new Map<DataKind, DataKind>([
    ['material', 'material'],
    ['myMaterial', 'material'],
    ['devices', 'devices'],
    ['myDevice', 'devices'],
    ['modules', 'modules'],
    ['myModule', 'modules']
  ]);
  public static CATALOG_COPY = new Map<DataKind, DataKind>([
    ['material', 'myMaterial'],
    ['devices', 'myDevice'],
    ['modules', 'myModule']
  ]);

  public static DeepCopyCategoryData(x: CategoryData) {
    const y = new CategoryData();
    y.category = x.category;
    y.de = x.de.repeat(1);
    y.en = x.en.repeat(1);
    y.name = x.name.repeat(1);
    y.order = x.order;
    return y;
  }

  public static DeepCopyDevice(x: Device) {
    if (!x) {
      return x;
    }
    const y = new Device();
    y.id = x.id.repeat(1);
    y.name = x.name.repeat(1);
    if (!!x.description) {
      y.description = x.description.repeat(1);
    }
    y.category = x.category;
    if (!!x.program) {
      y.program = [...x.program];
    }
    if (!!x.catalogId) {
      y.catalogId = x.catalogId.repeat(1);
    }
    if (!!x.manufacturer) {
      y.manufacturer = x.manufacturer.repeat(1);
    }
    if (!!x.manuLink) {
      y.manuLink = x.manuLink.repeat(1);
    }
    if (x.catalog !== undefined) {
      y.catalog = x.catalog;
    }
    if (!!x.fileNames) {
      y.fileNames = [...x.fileNames];
    }
    if (!!x.fileDescs) {
      y.fileDescs = [...x.fileDescs];
    }
    CommonUtils.DeepCopyNonCatValuesDevice(x, y);
    return y;
  }

  public static DeepCopyDeviceInfo(x: DeviceMetadata) {
    const y = new DeviceMetadata();
    if (!!x.acquiredYear) {
      y.acquiredYear = x.acquiredYear;
    }
    if (x.maintContract !== undefined) {
      y.maintContract = x.maintContract;
    }
    if (!!x.maintCompany) {
      y.maintCompany = x.maintCompany.repeat(1);
    }
    if (!!x.serialNumber) {
      y.serialNumber = x.serialNumber.repeat(1);
    }
    if (!!x.maintContact) {
      y.maintContact = x.maintContact.repeat(1);
    }
    return y;
  }

  public static DeleteNonCatalogDataFromDevice(device: Device) {
    delete device.deviceInfo;
    delete device.maintLists;
    delete device.maintListNames;
  }

  static KeepOnlyNonCatalogDevice(newItem: Device) {
    const res = new Device();
    res.id = newItem.id.repeat(1);
    res.catalog = newItem.catalog;
    res.catalogId = newItem.catalogId.repeat(1);
    CommonUtils.DeepCopyNonCatValuesDevice(newItem, res);
    return res;
  }

  static KeepOnlyNonCatalogMaterial(newItem: Material) {
    const res = new Material();
    res.id = newItem.id.repeat(1);
    res.catalog = newItem.catalog;
    res.catalogId = newItem.catalogId.repeat(1);
    CommonUtils.DeepCopyNonCatValuesMaterial(newItem, res);
    return res;
  }

  static CountOnExistingName(currentName: string, names: string[]) {
    let name = ListUtil.DupName(currentName);
    let hasIndex = names.findIndex(n => n === name);
    while (hasIndex >= 0) {
      name = ListUtil.DupName(names[hasIndex]);
      hasIndex = names.findIndex(n => n === name);
    }
    return name;
  }

  static DeepCopyMaterial(x: Material) {
    if (!x) {
      return x;
    }
    const y = new Material();
    y.id = x.id.repeat(1);
    y.name = x.name.repeat(1);
    if (!!x.description) {
      y.description = x.description.repeat(1);
    }
    y.category = x.category;
    if (!!x.amount) {
      y.amount = x.amount;
    }
    if (!!x.unit) {
      y.unit = x.unit.repeat(1);
    }
    if (!!x.catalogId) {
      y.catalogId = x.catalogId.repeat(1);
    }
    if (!!x.manufacturer) {
      y.manufacturer = x.manufacturer.repeat(1);
    }
    if (!!x.manuLink) {
      y.manuLink = x.manuLink.repeat(1);
    }
    if (x.catalog !== undefined) {
      y.catalog = x.catalog;
    }
    if (!!x.fileNames) {
      y.fileNames = [...x.fileNames];
    }
    if (!!x.fileDescs) {
      y.fileDescs = [...x.fileDescs];
    }

    CommonUtils.DeepCopyNonCatValuesMaterial(x, y);
    return y;
  }

  public static DeleteNonCatalogDataFromMaterial(material: Material) {
    delete material.count;
    delete material.countThreshold;
  }

  public static DeepCopyNonCatValuesMaterial(x: Material, y: Material) {
    if (!!x.countThreshold) {
      y.countThreshold = x.countThreshold;
    }
    if (!!x.count) {
      y.count = x.count;
    }
  }

  private static DeepCopyNonCatValuesDevice(x: Device, y: Device) {
    if (!!x.deviceInfo) {
      y.deviceInfo = CommonUtils.DeepCopyDeviceInfo(x.deviceInfo);
    }
    if (!!x.maintLists && x.maintLists.length > 0) {
      y.maintLists = [...x.maintLists];
    }
    if (!!x.maintListNames && x.maintListNames.length > 0) {
      y.maintListNames = [...x.maintListNames];
    }
  }

}
