import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { ControlList, ListUtil } from '../../model/controllist';
import { ModalController } from '@ionic/angular';
import { TranslateService } from '@ngx-translate/core';
import { Device } from '../../model/device';
import { Material } from '../../model/material';
import { DeviceService } from '../../services/device.service';
import { MaterialService } from '../../services/material.service';
import { DateUtil, NiceDate, StrTime } from '../../util/dateutil';

@Component({
  selector: 'app-view',
  templateUrl: './view.component.html',
  styleUrls: ['./view.component.scss']
})
export class ViewComponent implements OnInit, OnDestroy {
  @Input() tenant: string;
  @Input() isAdmin: boolean;
  @Input() canEdit = true;
  @Input() tenantName: string;
  @Input() list: ControlList;
  @Input() lists: ControlList[];
  maintIntText: string;
  maintNextStr: string;
  dailyDaysTxt: string;
  dailyTimes: string[];
  private _sub: Subscription;
  private _materials: Material[];
  private _devices: Device[];

  constructor(private modalCtrl: ModalController,
    private matSvc: MaterialService,
    private devSvc: DeviceService,
    private translate: TranslateService) {
  }

  ngOnInit() {
    this.maintIntText =
      `${ this.list.maint?.toString() } ${ ListUtil.MAINT_INTERVAL[this.translate.currentLang][this.list.maintInterval - 1] }`;
    this.maintNextStr = NiceDate(new Date(this.list.nextMaintenance ? this.list.nextMaintenance : Date.now()));
    this.dailyDaysTxt = this.list.dailyWeekdays?.map(t => DateUtil.WEEKDAYS[this.translate.currentLang][t]).join(',');
    this.dailyTimes = this.list.dailyTimes?.map(t => StrTime(t));
    this._sub = this.matSvc.data.subscribe(m => {
      if (!!m && m.length > 0) {
        this._materials = m;
        this.list.items.forEach(step => {
          if (!!step.matID) {
            if (!m.find(x => x.id === step.matID)) {
              delete step.matID;
              delete step.amount;
              delete step.unit;
            }
          }
        });
      }
    });
    this._sub.add(this.devSvc.data.subscribe(d => {
      if (!!d && d.length > 0) {
        this._devices = d;
        this.list.items.forEach(step => {
          if (!!step.devID) {
            if (!d.find(x => x.id === step.devID)) {
              delete step.devID;
              delete step.program;
            }
          }
        });
      }
    }));
  }

  ngOnDestroy() {
    this._sub?.unsubscribe();
  }

  dismiss(role?: string) {
    this.modalCtrl.dismiss(null, role).then();
  }

  timedText(timed: number) {
    return ListUtil.TimedText(timed);
  }

  listName = (nextList: string) => this.lists.find(l => l.id === nextList)?.name;

  getDeviceName(devID: string) {
    return this._devices?.find(m => m.id === devID)?.name;
  }

  getMatName(matID: string) {
    return this._materials?.find(m => m.id === matID)?.name;
  }
}
