import {
  child,
  Database,
  DatabaseReference,
  onChildAdded,
  onChildChanged,
  onChildRemoved,
  push,
  ref, remove,
  set
} from '@angular/fire/database';
import { IdentityData } from '@medmonitor/authlib';
import { BehaviorSubject } from 'rxjs';


export abstract class GenericDataService<T extends IdentityData> {
  private static _DELAYED_EXEC = new Map<string, any>();
  protected _theList: T[] = [];
  private _dbRef: DatabaseReference;
  private _added;
  private _removed;
  private _changed;
  private _data = new BehaviorSubject<T[]>([]);
  private _path: string;

  protected constructor(protected db: Database) {}

  public get data() {
    if (!this._dbRef && !!this._path) {
      // console.log('generic DATA get without ref', this._path);
      this.init(this._path);
    }
    return this._data.asObservable();
  }

  public init(path: string) {
    this._path = path;
    // console.log('SERVICE initializing:', path);
    this._dbRef = ref(this.db, path);
    this._added = onChildAdded(this._dbRef, (data) => {
      this._theList.push(Object.assign({id: data.key}, data.val()));
      if (GenericDataService._DELAYED_EXEC.has(path)) {
        clearTimeout(GenericDataService._DELAYED_EXEC.get(path));
      }
      GenericDataService._DELAYED_EXEC.set(path, setTimeout((p: string) => {
        this.processList();
        this._data.next(this._theList);
        GenericDataService._DELAYED_EXEC.delete(p);
      }, 200, path));
    }, () => {
      // console.log('add', err);
      this.cancelListeners();
    });

    this._removed = onChildRemoved(this._dbRef, (data) => {
      const i = this._theList.findIndex(l => l.id === data.key);
      if (i >= 0) {
        this._theList.splice(i, 1);
        this._data.next(this._theList);
      }
    }, () => {
      // console.log('remove', err);
      this.cancelListeners();
    });

    this._changed = onChildChanged(this._dbRef, (data) => {
      const i = this._theList.findIndex(l => l.id === data.key);
      if (i >= 0) {
        this.processItem(i, Object.assign({id: data.key}, data.val()));
        this._data.next(this._theList);
      }
    }, () => {
      // console.log('change', err);
      this.cancelListeners();
    });
  }

  public cancelListeners() {
    if (!!this._dbRef) {
      this._dbRef = null;
      // console.log('SERVICE cancel', this._path);
      this._changed();
      this._removed();
      this._added();
      this._theList.length = 0;
      this._data.next(this._theList);
    }
  }

  async setData(data: T) {
    const toSave = {...data};
    if (!!data.id) {
      delete toSave.id;
      await set(child(this._dbRef, data.id), toSave);
      return data.id;
    } else {
      const newRef = await push(this._dbRef, toSave);
      return newRef.key;
    }
  }

  async removeData(data: T) {
    if (!!data.id) {
      await remove(child(this._dbRef, data.id));
    }
  }

  protected processList(): void {}

  protected processItem(index: number, obj: T): void {
    this._theList[index] = obj;
  }

  protected childRef(path: string) {
    return child(this._dbRef, path);
  }
}
