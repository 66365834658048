import { ChangeDetectorRef, Component, EventEmitter, Input, NgZone, OnDestroy, OnInit, Output } from '@angular/core';
import { AuthService, AuthUser } from '@medmonitor/authlib';
import { CategoryData } from '../../model/category-data';
import { Doc, DocKind } from '../../model/document';
import { AlertController, LoadingController, ModalController, NavController } from '@ionic/angular';
import { ShowAlert } from '../../util/show-alert';
import { DocDetailComponent } from '../doc-detail/doc-detail.component';
import { DocEditComponent } from '../doc-edit/doc-edit.component';
import { Subscription } from 'rxjs';
import { DocFilterService } from '../../services/doc-filter.service';
import { DocumentProcessingService } from '../../services/document-processing.service';
import { TranslateService } from '@ngx-translate/core';
import { DataCategoriesService } from '../../services/data-categories.service';
import { FileShowService } from '../../services/file-show.service';
import { CategoryEditComponent } from '../../category-edit/category-edit.component';

@Component({
  selector: 'app-doc-list',
  templateUrl: './doc-list.component.html',
  styleUrls: ['./doc-list.component.scss']
})
export class DocListComponent implements OnInit, OnDestroy {
  static SORT_ORDER = 1;
  static ICON_MAP = new Map<DocKind, string>([
    ['Text', 'text'],
    ['Link', 'link'],
    ['File', 'document']
  ]);
  @Input() catalog = false;
  @Input() theoryCatalog = false;
  @Input() editCategory = false;
  @Input() editCatalog = false;
  @Input() catalogCopy = false;
  @Output() added = new EventEmitter<number>();
  viewIndex: number;
  docs: Doc[];
  user: AuthUser;
  categoryTypes: CategoryData[];
  hasAddDocButton = false;
  addFromCatalogButton = false;
  private _click = false;
  private _sub: Subscription;
  private _alert: ShowAlert;

  constructor(private modalCtrl: ModalController,
    private alertCtrl: AlertController,
    private loadingCtrl: LoadingController,
    private ref: ChangeDetectorRef,
    private authService: AuthService,
    private docProcessing: DocumentProcessingService,
    private translate: TranslateService,
    private dataTypeSvc: DataCategoriesService,
    private fileShow: FileShowService,
    private zone: NgZone,
    private navCtrl: NavController,
    private docFilter: DocFilterService) {
    this._alert = new ShowAlert(this.alertCtrl, this.translate);
  }

  ngOnInit() {
    this._sub = this.authService.user.subscribe(u => {
      if (!!u) {
        this.user = u;
        this.hasAddDocButton = (this.user.isAdmin && !this.catalog) || this.editCatalog;
        this.addFromCatalogButton = this.catalog && this.user.isAdmin && !this.editCatalog;
      }
    });
    if (this.catalog) {
      this._sub.add(this.docFilter.catalog.subscribe(d => {
        this.initDoc(d);
      }));
    } else {
      this._sub.add(this.docFilter.docs.subscribe(d => {
        this.initDoc(d);
      }));
    }
    this.dataTypeSvc.getCategories('modules').then(t => {
      if (!!t) {
        this.categoryTypes = t;
        this.ref.detectChanges();
      }
    });
    this.viewIndex = -1;
  }

  ngOnDestroy() {
    this._sub?.unsubscribe();
  }

  iconType(type: DocKind) {
    return DocListComponent.ICON_MAP.get(type);
  }

  view(i: number) {
    this._click = true;
    setTimeout(() => {
      if (this._click) {
        if (this.viewIndex === i) {
          this.viewIndex = -1;
        } else {
          this.viewIndex = i;
        }
        this.ref.detectChanges();
        this._click = false;
      }
    }, 250);
  }

  ignore(ev: MouseEvent) {
    ev.stopPropagation();
  }

  addDoc() {
    const doc = Doc.Create();
    doc.catalog = this.catalog;
    doc.theory = false;
    this.modalCtrl.create({
      component: DocEditComponent,
      componentProps: {doc}
    }).then(modal => {
      modal.present().then();
      return modal.onDidDismiss();
    }).then(ret => {
      this.docProcessing.process(ret.role, ret.data);
    });
  }

  async addFromCatalog(ev, doc: Doc) {
    ev.stopPropagation();
    const choice = await this._alert
      .withHeader('Add from catalog')
      .withMessage('Add Document from Catalog?')
      .addYesButton()
      .addNoButton()
      .show();

    if (this.catalogCopy) {
      const loader = await this.loadingCtrl.create({message: this.translate.instant('Copying...')});
      await loader.present();
      await this.docProcessing.copyCatalogItem(doc);
      await loader.dismiss();
    } else {
      if (choice === 'yes') {
        if (await this.docProcessing.addCatalogItem(doc.id) === null) {
          await this._alert
          .withHeader('Already Added')
          .withMessage('You have already added this catalog module.')
          .addOkButton()
          .show()
          .then();
        } else {
          this.added.emit(0);
        }
      }
    }
  }

  getCategory(n: number) {
    if (this.categoryTypes) {
      return this.categoryTypes.find(x => x.category === n)[this.translate.currentLang];
    }
  }

  edit(i: number) {
    this._click = false;
    if (this.user.isAdmin && this.docs[i].kind === 'Text') {
      if (!!this.docs[i].versions[0].released) {
        this._alert
          .withHeader('Cannot Edit')
          .withMessage('Latest version already released and cannot be edited. Create a new version?')
          .addYesButton().addNoButton().addButton('Show', 'show')
          .show()
          .then(ret => {
            if (ret === 'yes') {
              return DocDetailComponent.AddVersion(this.docs[i], this.translate, this.alertCtrl, this.docProcessing);
            } else if (ret === 'show') {
              this.show(i);
            }
            return;
          });
        return;
      }
      // do edit
      this.navEdit(i);
    }
  }

  manageCategories() {
    this.modalCtrl.create({
      component: CategoryEditComponent,
      canDismiss: true,
      componentProps: {kind: 'modules'}
    }).then(el => {
      el.present().then();
      return el.onDidDismiss();
    }).then();
  }

  sortlist(n: number) {
    if (n === 0) {
      n = DocListComponent.SORT_ORDER;
    } else {
      if (n !== Math.abs(DocListComponent.SORT_ORDER)) {
        DocListComponent.SORT_ORDER = Math.abs(n);
      } else {
        DocListComponent.SORT_ORDER *= -1;
      }
    }
    switch (n) {
      case 1:
        this.docs.sort((a, b) => DocListComponent.SORT_ORDER * a.name?.localeCompare(b.name));
        break;
      case 2: // 'this' is used here for the translation... so it cannot go into a function
        this.docs.sort((b, a) => DocListComponent.SORT_ORDER * a.kind?.localeCompare(b.kind));
        break;
      case 3:
        this.docs.sort(this.tagSort);
        break;
    }
  }

  private initDoc(d: Doc[]) {
    if (!!d && d.length > 0) {
      if (this.theoryCatalog) {
        this.docs = d.filter(x => x.theory);
      } else {
        this.docs = d;
      }
      this.sortlist(0);
      this.ref.detectChanges();
    }
  }

  private tagSort(a: Doc, b: Doc) {
    const aTag = !!a.tags ? a.tags.sort((x, y) => DocListComponent.SORT_ORDER * x.localeCompare(y))
    .reduce((val, x) => val + x) : '';
    const bTag = !!b.tags ? b.tags.sort((x, y) => DocListComponent.SORT_ORDER * x.localeCompare(y))
    .reduce((val, x) => val + x) : '';

    const comparison = -DocListComponent.SORT_ORDER * aTag.localeCompare(bTag);
    return comparison === 0 ? a.name.localeCompare(b.name) : comparison;
  }

  private show(i: number) {
    this.fileShow.doc = this.docs[i];
    this.fileShow.newDoc = false;
    this.fileShow.empty = false;
    this.zone.run(() => {
      this.navCtrl.navigateForward('/documents/show').then();
    });
  }

  private navEdit(i: number) {
    this.fileShow.doc = this.docs[i];
    this.fileShow.newDoc = false;
    this.fileShow.empty = true;
    this.zone.run(() => {
      this.navCtrl.navigateForward('/documents/edit-text').then();
    });
  }
}
