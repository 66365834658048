import {
  ChangeDetectorRef,
  Component, ElementRef,
  Input,
  OnChanges,
  OnDestroy,
  OnInit, Renderer2,
  SimpleChanges, ViewChild
} from '@angular/core';
import { ControlList } from '../model/controllist';
import { ListsService } from '../services/lists.service';
import { Subscription } from 'rxjs';
import { ListComponent } from '../lists/list/list.component';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-listsdue',
  templateUrl: './listsdue.component.html',
  styleUrls: ['./listsdue.component.scss']
})
export class ListsdueComponent implements OnInit, OnDestroy, OnChanges {
  @ViewChild('scrollList') scrollList: ElementRef;
  @Input() height: number;
  sub: Subscription;
  controllists: ControlList[];

  constructor(private listsService: ListsService,
    private translate: TranslateService,
    private renderer: Renderer2,
    private ref: ChangeDetectorRef) {
  }

  ngOnInit() {
    this.sub = this.listsService.lists.subscribe(lists => {
      if (lists) {
        this.controllists = lists.filter(l => l.alert);
        this.controllists.sort((a, b) => a.name.localeCompare(b.name));
        this.ref.markForCheck();
        this.ref.detectChanges();
      }
    });
  }

  ngOnDestroy() {
    if (this.sub) {
      this.sub.unsubscribe();
    }
  }

  ngOnChanges(changes: SimpleChanges) {
    if (!!this.scrollList) {
      setTimeout(() => {
        this.renderer.setStyle(this.scrollList.nativeElement, 'height', `${ this.height - 86 }px`);
      }, 300);
    }
  }

  iconType(type: string) {
    return ListComponent.ICON_MAP.get(type);
  }

  dueStr(l: ControlList) {
    if (l.alert) {
      return this.translate.instant('ASAP');
    }
    if (l.type === 'maintenance') {
      return !!l.nextMaintenance ? this.listsService.getStringDate(l.nextMaintenance, false) : '-';
    }
  }
}
