import { Component, OnInit } from '@angular/core';
import { AlertController, ModalController } from '@ionic/angular';
import { TranslateService } from '@ngx-translate/core';
import { FileUploadService } from '../services/file-upload.service';
import { DocumentData } from '../model/document-data';

@Component({
  selector: 'app-common-new-document',
  templateUrl: './common-new-document.component.html',
  styleUrls: ['./common-new-document.component.scss']
})
export class CommonNewDocumentComponent implements OnInit {
  doc: DocumentData;

  constructor(private modalCtrl: ModalController,
    private alertCtrl: AlertController,
    private fileUploads: FileUploadService,
    private translate: TranslateService) {
  }

  ngOnInit() {
    this.doc = new DocumentData();
  }

  onAddItem() {
    if (this.isValid()) {
      this.fileUploads.uploadNewFile(this.doc);
      this.modalCtrl.dismiss().then();
    }
  }

  onCancel() {
    this.modalCtrl.dismiss().then();
  }

  isValid() {
    return !!this.doc.file && !!this.doc.description;
  }

  async setUploadFile(event) {
    // only one file at a time is possible.
    const files = event.target.files;
    if (files.length > 1) {
      const el = await this.alertCtrl.create({
        header: this.translate.instant('Too many selections'),
        message: this.translate.instant('OneFileMsg'),
        buttons: [{text: 'OK', role: 'OK'}]
      });
      await el.present();
      await el.onDidDismiss();
    }
    if (files.length > 0) {
      this.doc.file = files.item(0);
    }
  }
}
