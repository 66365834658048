import { AlertController } from '@ionic/angular';
import { TranslateService } from '@ngx-translate/core';

export class ShowAlert {
  private _header: string;
  private _message: string;
  private _buttons: {text: string; role: string; cssClass?: string}[] = [];

  constructor(private alertCtrl: AlertController,
    private translate: TranslateService) {}

  public static DoAlert(alertCtrl: AlertController, header: string, message: string, yesno = false) {
    const buttons = new Array<{ text: string; role: string }>();
    buttons.push({text: 'OK', role: 'ok'});
    if (yesno) {
      buttons.push({text: 'Cancel', role: 'cancel'});
    }
    return alertCtrl
    .create({header, message, buttons})
    .then(el => {
      el.present().then();
      return el;
    });
  }

  public withHeader(header: string, extra: string = '') {
    this._header = this.translate.instant(header) + ' ' +extra;
    return this;
  }

  public withMessage(message: string, extra: string = '') {
    this._message = this.translate.instant(message) + ' ' + extra;
    return this;
  }

  public addYesButton() {
    this.pushButton('Yes');
    return this;
  }

  public addYesDeleteButton() {
    this.pushButton('Yes, delete', 'yes', 'btnalert');
    return this;
  }

  public addNoButton() {
    this.pushButton('No');
    return this;
  }

  public addCancelButton() {
    this.pushButton('Cancel');
    return this;
  }

  addOkButton() {
    this.pushButton('OK');
    return this;
  }

  public addButton(name: string, role: string, cssClass: string = null) {
    this.pushButton(name, role, cssClass);
    return this;
  }

  public async show() {
    const alert = await this.alertCtrl.create({
      header: this._header,
      message: this._message,
      buttons: this._buttons
    });
    await alert.present();
    const ret = await alert.onDidDismiss();
    this.reset();
    return ret.role;
  }

  private pushButton(content: string, role: string = null, cssClass: string = null) {
    if (!role) {
      role = content.toLowerCase();
    }
    const buttonData: {text: string; role: string; cssClass?: string} = {
      text: this.translate.instant(content),
      role};
    if (!!cssClass) {
      buttonData.cssClass = cssClass;
    }
    this._buttons.push(buttonData);
  }

  private reset() {
    this._header = null;
    this._message = null;
    this._buttons = [];
  }
}
