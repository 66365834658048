import { Injectable, NgZone } from '@angular/core';
import { AuthService, AuthUser } from '@medmonitor/authlib';
import { CommonUtils } from '../model/common-utils';
import { Doc, DocUtil, DocVersion } from '../model/document';
import { DocumentService } from './document.service';
import { FileShowService } from './file-show.service';
import { DocUploadComponent } from '../documents/doc-upload/doc-upload.component';
import ShortUid from 'short-uuid';
import { LoadingController, ModalController, NavController } from '@ionic/angular';
import { ActivatedRoute } from '@angular/router';
import { SafeImage } from '../util/safe-image';
import { TenantQssService } from './tenant-qss.service';

@Injectable({
  providedIn: 'root'
})
export class DocumentProcessingService {
  private _user: AuthUser;
  private _path: string;

  constructor(private docService: DocumentService,
    private qssService: TenantQssService,
    private fileShow: FileShowService,
    private authService: AuthService,
    private modalCtrl: ModalController,
    private navCtrl: NavController,
    private loadingCtrl: LoadingController,
    private activatedRoute: ActivatedRoute,
    private zone: NgZone) {
    this.authService.user.subscribe(u => {
      if (!!u) {
        this._user = u;
        if (this._user.isSuperadmin && !this._user.tenant) {
          this._path = 'documents/';
        } else {
          this._path = `${ this._user.tenant }/documents/`;
        }
      }
    });
  }

  private static CleanName(name: string): string {
    return name.replace(/[# *+?^${}()\/|[\]\\]/g, '_');
  }

  process(role: string, doc: Doc) {
    if (!doc) {
      return;
    }
    switch (role) {
      case 'new':
        doc.id = this.docService.addDoc(doc);
        this.setData(doc);
        break;
      case 'save-data':
        this.docService.update(doc);
        this.setData(doc);
        break;
      case 'save':
        this.docService.update(doc);
        break;
      case 'delete':
        if (doc.catalog && this._user.isSuperadmin) {
          this.docService.deleteFromCatalog(doc.id);
        } else {
          this.docService.delete(doc.id);
          this.qssService.removeControl(doc.id);
        }
        doc.versions?.forEach(version => {
          if (doc.kind === 'File' && !!version.data) {
            if (!doc.catalog || this._user.isSuperadmin) {
              this.fileShow.delete(this._path + version.data);
            }
          }
        });
        break;
      case 'duplicate':
        doc.id = this.docService.addDoc(doc);
        this.fileShow.duplicatePath = doc.versions[0].data;
        doc.versions = [new DocVersion()];
        this.setData(doc);
        break;
      default:
        return;
    }
  }

  setData(doc: Doc, newVersion = false, empty = true) {
    if (newVersion) {
      doc.versions.unshift(new DocVersion());
    }
    switch (doc.kind) {
      case 'Text':
        this.editor(doc, empty);
        break;
      case 'Link':
      case 'File':
        this.setLinkOrFile(doc);
        break;
      default:
        break;
    }
  }

  navigate(doc: Doc, i: number = 0) {
    switch (doc.kind) {
      case 'Link':
        window.open(doc.versions[i].data, '_blank');
        break;
      case 'File':
        const path = doc.catalog ? 'documents/' : this._path;
        SafeImage.OpenFile(path + doc.versions[i].data, this.loadingCtrl, this.fileShow);
        break;
      case 'Text':
        this.fileShow.doc = doc;
        this.fileShow.newDoc = false;
        this.zone.run(() => {
          this.navCtrl.navigateForward('/documents/show').then();
        });
        break;
      default:
        break;
    }
  }

  async addCatalogItem(id: string): Promise<string> {
    return await this.docService.newCatalogItem(id);
  }

  duplicate(doc: Doc, allDocs: Doc[]) {
    const newDoc = DocUtil.DeepCopy(doc);
    delete newDoc.id;
    newDoc.name = CommonUtils.CountOnExistingName(doc.name, allDocs.map(x => x.name));
    this.process('duplicate', newDoc);
  }

  async copyCatalogItem(doc: Doc) {
    await this.docService.copyFromCatalog(doc);
  }

  private setLinkOrFile(doc: Doc) {
    this.modalCtrl.create({
      component: DocUploadComponent,
      componentProps: {doc}
    }).then(el => {
      el.present().then();
      return el.onDidDismiss();
    }).then(ret => {
      if (ret.role === 'ok') {
        const vid = doc.versions[0].id;
        if (!!vid) {
          if (doc.catalog) {
            this.docService.updateVersionCatalog(doc.id, vid, ret.data as DocVersion);
          } else {
            this.docService.updateVersion(doc.id, vid, ret.data as DocVersion);
          }
        } else {
          if (doc.catalog) {
            this.docService.addVersionCatalog(doc.id, ret.data as DocVersion);
          } else {
            this.docService.addVersion(doc.id, ret.data as DocVersion);
          }
        }
      }
    });
  }

  private editor(doc: Doc, empty = true) {
    let newDoc = false;
    const vid = doc.versions[0].id;
    if (!vid) {
      doc.versions[0].created = Date.now();
      doc.versions[0].createdBy = this._user.id;
      doc.versions[0].modified = doc.versions[0].created;
      doc.versions[0].modifiedBy = this._user.id;
      doc.versions[0].data = ShortUid.generate() + '_' + DocumentProcessingService.CleanName(doc.name);
      if (doc.catalog) {
        doc.versions[0].id = this.docService.addVersionCatalog(doc.id, doc.versions[0]);
      } else {
        doc.versions[0].id = this.docService.addVersion(doc.id, doc.versions[0]);
      }
      newDoc = true;
      this.fileShow.empty = empty;
    }
    this.fileShow.doc = doc;
    this.fileShow.newDoc = newDoc;
    this.zone.run(() => {
      this.navCtrl.navigateForward('/documents/edit-text').then();
    });
  }
}
