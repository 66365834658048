import { Injectable } from '@angular/core';
import { AuthService, AuthUser } from '@medmonitor/authlib';
import { MarkdownService } from 'ngx-markdown';
import { DeviceService } from './device.service';
import { MaterialService } from './material.service';
import { TabletService } from './tablet.service';
import { TenantMetadataService } from './tenant-metadata.service';
import { DataCategoriesService } from './data-categories.service';
import { ListsService } from './lists.service';
import { TranslateService } from '@ngx-translate/core';
import { PrintBase } from '../util/print-base';
import { TenantMetadata } from '../model/tenant';
import { Subscription } from 'rxjs';
import { DataKind } from '../model/common-data';
import { ControlList } from '../model/controllist';
import { Tablet } from '../model/tablet';
import { ListComponent } from '../lists/list/list.component';
import { Log } from '../model/log';
import { DateUtil, NiceDate, StrDate } from '../util/dateutil';
import { FilteredLogService } from './filtered-log.service';
import { CommonUtils } from '../model/common-utils';
import { DocFilterService } from './doc-filter.service';
import { Doc, DocUtil } from '../model/document';

@Injectable({
  providedIn: 'root'
})
export class PrintingService {
  private readonly _print: PrintBase;
  private _typeMap = new Map<string, string>();
  private _sub: Subscription[] = [];
  private _lists: ControlList[];

  constructor(
    private infoService: TenantMetadataService,
    private dataTypeSvc: DataCategoriesService,
    private controlListService: ListsService,
    private tabletService: TabletService,
    private docFilterService: DocFilterService,
    private listService: ListsService,
    private filteredLog: FilteredLogService,
    private authService: AuthService,
    private materialService: MaterialService,
    private deviceService: DeviceService,
    private translate: TranslateService,
    private markdownService: MarkdownService
  ) {
    this._print = new PrintBase(this.translate);
    this._print.markdownService = this.markdownService;
  }

  public get print() {
    return this._print;
  }

  async setHeaderFooter(tenantName: string, info?: TenantMetadata) {
    if (!!info) {
      this._print.setHeaderFooter(info, tenantName);
      return Promise.resolve();
    }
    return new Promise<void>(resolve => {
      this._sub.push(this.infoService.info.subscribe(data => {
        if (!!data) {
          this._print.setHeaderFooter(data as TenantMetadata, tenantName);
          resolve();
        }
      }));
    });
  }

  async setFooter(tenantName: string, info?: TenantMetadata) {
    if (!!info) {
      this._print.setFooter(info, tenantName);
      return Promise.resolve();
    }
    return new Promise<void>(resolve => {
      this._sub.push(this.infoService.info.subscribe(data => {
        if (!!data) {
          this._print.setFooter(data as TenantMetadata, tenantName);
          resolve();
        }
      }));
    });
  }

  async setItemCategories(kind: DataKind) {
    this._print.itemCategories = await this.dataTypeSvc.getCategories(CommonUtils.TYPE_CATEGORIES.get(kind));
  }

  async printMaterial() {
    if (!this._print.material) {
      await this.materialService.isReady();
      const waitForMaterials = new Promise<void>((resolve) => {
        this._sub.push(this.materialService.data.subscribe(m => {
          if (!!m) {
            this._print.material = m;
            resolve();
          }
        }));
      });
      await waitForMaterials;
    }
    this._print.materialItem(this._print.material);
  }

  async printDevices() {
    if (!this._print.devices) {
      await this.deviceService.isReady();
      const waitForDevices = new Promise<void>((resolve) => {
        this._sub.push(this.deviceService.data.subscribe(d => {
          if (!!d) {
            this._print.devices = d;
            resolve();
          }
        }));
      });
      await waitForDevices;
    }
    this._print.deviceItem(this._print.devices);
  }

  release() {
    this._sub.forEach(s => s.unsubscribe());
  }

  async printLists(lists: ControlList[] = null) {
    if (!!lists) {
      this._lists = lists;
    } else {
      await this.getLists();
    }
    this._print.addHeading(this.translate.instant('Summary'), 2);
    this._print.addListItem(this.translate.instant('CONTROLLIST.maintenance'), 1);
    this._lists.filter(l => l.type === 'maintenance').forEach(list => {
      this._print.addListItem(list.name, 2);
    });
    this._print.addSpace();
    this._print.addListItem(this.translate.instant('Opening') + ' &amp; ' +
      this.translate.instant('Closing'), 1);
    this._lists.filter(l => l.type === 'open' || l.type === 'close').forEach(list => {
      this._print.addListItem(list.name, 2);
    });
    this._print.addSpace();
    this._print.addListItem(this.translate.instant('CONTROLLIST.task'), 1);
    this._lists.filter(l => l.type === 'task').forEach(list => {
      this._print.addListItem(list.name, 2);
    });
    this._print.addSpace();
    this._print.addListItem(this.translate.instant('CONTROLLIST.daily'), 1);
    this._lists.filter(l => l.type === 'daily').forEach(list => {
      this._print.addListItem(list.name, 2);
    });
    this._print.addSpace();
    this._print.addListItem(this.translate.instant('CONTROLLIST.steril'), 1);
    this._lists.filter(l => l.type === 'steril').forEach(list => {
      this._print.addListItem(list.name, 2);
    });

    this._print.addSpace(2);
    this._print.addHeading(this.translate.instant('List Details') + ': ' + this.translate.instant('CONTROLLIST.maintenance'), 2);
    this._lists.filter(l => l.type === 'maintenance').forEach(list => {
      this._print.listHeader(list);
      this._print.listItem(list.items);
    });
    this._print.addSpace(2);
    this._print.addHeading(this.translate.instant('Opening') + ' &amp; ' +
      this.translate.instant('Closing'), 2);
    this._lists.filter(l => l.type === 'open' || l.type === 'close').forEach(list => {
      this._print.listHeader(list);
      this._print.listItem(list.items);
    });
    this._print.addSpace(2);
    this._print.addHeading(this.translate.instant('CONTROLLIST.task'), 2);
    this._lists.filter(l => l.type === 'task').forEach(list => {
      this._print.listHeader(list);
      this._print.listItem(list.items);
    });
    this._print.addSpace(2);
    this._print.addHeading(this.translate.instant('CONTROLLIST.daily'), 2);
    this._lists.filter(l => l.type === 'daily').forEach(list => {
      this._print.listHeader(list);
      this._print.listItem(list.items);
    });
    this._print.addSpace(2);
    this._print.addHeading(this.translate.instant('CONTROLLIST.steril'), 2);
    this._lists.filter(l => l.type === 'steril').forEach(list => {
      this._print.listHeader(list);
      this._print.listItem(list.items, true);
    });
  }

  async getLists() {
    if (!!this._lists) {
      return Promise.resolve();
    } else {
      return new Promise<void>(resolve => {
        this._sub.push(this.listService.lists.subscribe(l => {
          if (!!l) {
            this._lists = l;
            l.forEach(list => {
              this._typeMap.set(list.id, list.type);
            });
            resolve();
          }
        }));
      });
    }
  }

  async getTheoryModules() {
    if (!!this._print.modules) {
      return Promise.resolve();
    } else {
      return new Promise<void>(resolve => {
        this._sub.push(this.docFilterService.theoryDocs.subscribe(t => {
          this._print.modules = t;
          resolve();
        }));
      });
    }
  }

  async printTablets(tablets: Tablet[] = null, listData: Map<string, { name: string; type: string; icon: string; order: string }> = null) {
    if (!tablets) {
      tablets = await this.getTablets();
    }
    if (!listData) {
      if (!this._lists) {
        await this.getLists();
      }
      listData = new Map<string, { name: string; type: string; icon: string; order: string }>();
      this._lists.forEach(el => {
        listData.set(el.id, {
          name: el.name, type: el.type, icon: ListComponent.ICON_MAP.get(el.type),
          order: `${ ListComponent.ORDER_OF_TYPE.get(el.type) }${ el.name }`
        });
      });
    }
    this._print.tabletItem(tablets, listData);
  }

  async printLog(logData: Log[] = null) {
    if (!logData) {
      logData = await this.getLogs();
    }
    if (this._typeMap.size === 0) {
      await this.getLists();
    }
    await this.getImages(logData);
    const signatures = await this.getSignatures(logData);
    logData.forEach(log => {
      const type = !!log.listType ? log.listType : this._typeMap.get(log.listId);
      this._print.logHeader(
        this.translate.instant('Name of List'),
        this.translate.instant('Type'),
        this.translate.instant('Executed by'),
        this.translate.instant('Started at'),
        this.translate.instant('Duration'),
        this.translate.instant('Signature'),
        log.listName,
        type ?
        this.translate.instant('CONTROLLIST.' + type) :
        '-',
        log.userName,
        StrDate(log.startDate),
        DateUtil.StrTime(log),
        signatures.get(log.startDate),
        log.deviceName,
        log.maintInterval
      );
      this._print.logItem(log.listDesc, log.eventList);
    });
  }

  addHtml(html: string) {
    this._print.justHtml(html);
  }

  async printTheory() {
    await this.getTheoryModules();
    this._print.theoryItem(this._print.modules);

  }

  async printTheoryCompleted(users: AuthUser[]) {
    users.forEach(user => {
      this._print.addListItem(`${ user.name }: ${ DocUtil.GetModulePercentage(user.completed, this._print.modules) }%`, 1);
      this._print.modules.forEach(doc => {
        if (DocUtil.IsModuleCompleted(doc, doc.versions[0].id, user.completed)) {
          this._print.addListItem(`${ this.getDoneDate(user, doc) }: ${ doc.name }` + this.getVersionStr(doc), 2);
        }
      });
    });
  }

  getDoneDate(user: AuthUser, m: Doc) {
    if (!!user.completed) {
      const date = new Date(user.completed[m.versions[0].id]);
      return NiceDate(date);
    }
    return '';
  }

  private async getTablets() {
    return new Promise<Tablet[]>(resolve => {
      this._sub.push(this.tabletService.data.subscribe(t => {
        if (!!t) {
          resolve(t);
        }
      }));
    });
  }

  private async getLogs() {
    return new Promise<Log[]>(resolve => {
      this._sub.push(this.filteredLog.filteredLog.subscribe(l => {
        if (!!l) {
          resolve(l);
        }
      }));
    });
  }

  private async getImages(logs: Log[]) {
    const tss: number[] = [];
    logs.filter(l => !!l.eventList.find(i => i.images > 0)).map(l => l.eventList).forEach(x => x.forEach(e => {
      if (e.images > 0 && !this._print.images.has(e.ts)) {
        tss.push(e.ts);
      }
    }));
    return new Promise<void>(resolve => {
      const promises: Promise<string[]>[] = [];
      tss.forEach(ts => {
        promises.push(this.filteredLog.image(ts));
      });
      Promise.all(promises).then(imgLists => {
        imgLists.forEach((img, i) => {
          this._print.images.set(tss[i], img);
        });
        resolve();
      });
    });
  }

  private async getSignatures(logs: Log[]) {
    const p: Promise<string>[] = [];
    logs.forEach(l => {
      p.push(this.filteredLog.signature(l.startDate));
    });
    return Promise.all(p).then(sig => {
      const ret = new Map<number, string>();
      sig.forEach((s, i) => {
        ret.set(logs[i].startDate, s);
      });
      return ret;
    });
  }

  private getVersionStr(doc: Doc) {
    if (doc.versioned) {
      return ', ' + this.translate.instant('Version') + ' ' + doc.versions.length;
    }
    return '';
  }
}
