import { Injectable } from '@angular/core';
import { Database, ref, set } from '@angular/fire/database';
import { ControlList } from '../model/controllist';
import { Device } from '../model/device';
import { LegalDoc } from '../model/legal';
import { Material } from '../model/material';
import { QssControl } from '../model/qss-control';
import { TenantInfo } from '../model/tenant';
import { TitleInfo } from '../model/title-info';
import { GenericDataService } from './generic-data.service';

@Injectable({
  providedIn: 'root'
})
export class MaterialCatalogService extends GenericDataService<Material> {
  constructor(protected db: Database) {
    super(db);
    this.init('/material');
  }
}

@Injectable({
  providedIn: 'root'
})
export class DeviceCatalogService extends GenericDataService<Device> {
  constructor(protected db: Database) {
    super(db);
    this.init('/devices');
  }
}

@Injectable({
  providedIn: 'root'
})
export class LegalService extends GenericDataService<LegalDoc> {
  constructor(protected db: Database) {
    super(db);
    this.init('/legal');
  }

  async setData(data: LegalDoc): Promise<string> {
    await super.setData(data);
    const newId = Date.now();
    await set(ref(this.db, `/legallog/${ newId }`), data);
    return newId.toString();
  }
}

@Injectable({
  providedIn: 'root'
})
export class QssService extends GenericDataService<QssControl> {
  constructor(protected db: Database) {
    super(db);
    this.init('/qss');
  }
}

@Injectable({
  providedIn: 'root'
})
export class TenantListService extends GenericDataService<TenantInfo> {
  constructor(protected db: Database) {
    super(db);
    this.init('/tlist');
  }
}

@Injectable({
  providedIn: 'root'
})
export class TitleService extends GenericDataService<TitleInfo> {
  constructor(protected db: Database) {
    super(db);
    this.init('/titles');
  }

  setOrder(id: string, order: number) {
    set(ref(this.db,`/titles/${ id }/order`), order).then();
  }
}

@Injectable({
  providedIn: 'root'
})
export class ListCatalogService extends GenericDataService<ControlList> {
  constructor(protected db: Database) {
    super(db);
    this.init('/lists');
  }
}
