import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Device } from '../model/device';
import { Material } from '../model/material';
import { FileUploadService } from './file-upload.service';
import { AddedItems, ListServiceBase } from './list-service-base';
import { ControlList, ListUtil } from '../model/controllist';
import { Database, equalTo, onValue, orderByChild, push, query, ref, set } from '@angular/fire/database';

@Injectable({
  providedIn: 'root'
})
export class AdminListsService extends ListServiceBase {

  constructor(protected db: Database,
              protected fileSvc: FileUploadService,
              protected translate: TranslateService) {
    super(db, translate);
  }

  public setTenant(tenant: string) {
    this.cancelListeners();
    this._theList.length = 0;
    this.listen(`/tenants/${tenant}`);
  }

  public setCatalog() {
    this.cancelListeners();
    this._theList.length = 0;
    this.listen('');
  }

  public async addListToCatalog(listItem: ControlList, fromTenant: string) {
    const dref = push(ref(this.db, '/lists'));
    const content = ListUtil.DeepCopy(listItem);
    content.completions = 0;
    delete content.id;
    delete content.alert;
    delete content.last;
    delete content.nextMaintenance;
    delete content.changeMaintenance;
    const retVal = new AddedItems();
    if (!!content.deviceID) {
      const device = await this.getDeviceFromCatalog(content.deviceID, fromTenant);
      if (device && device.catalog) {
        content.deviceID = device.catalogId;
        const catDev = await this.getDeviceFromCatalog(device.catalogId);
        if (!!catDev) {
          content.deviceName = catDev.name;
        } else {
          // corrupt data if catalog item points to nonexistent catalog entry
          delete content.deviceID;
          delete content.deviceName;
          retVal.removeDevice = true;
        }
      } else if (device && !device.catalog) {
        // add device to catalog so that we can have a catalog list entry
        const addD = await this.addDeviceToCatalog(device, fromTenant);
        content.deviceID = addD.id;
        content.deviceName = addD.name;
        if (addD.newItem) {
          retVal.addDeviceNames.push(addD.name);
        }
      }
    }
    const devicePromises = new Array<Promise<Device>>();
    const materialPromises = new Array<Promise<Material>>();
    const deviceIndex = new Array<number>();
    const materialIndex = new Array<number>();
    content.items.forEach((item, index) => {
      if (!!item.devID) {
        devicePromises.push(this.getDeviceFromCatalog(item.devID, fromTenant));
        deviceIndex.push(index);
      }
      if (!!item.matID) {
        materialPromises.push(this.getMaterialFromCatalog(item.matID, fromTenant));
        materialIndex.push(index);
      }
    });
    const devices = await Promise.all(devicePromises);
    const materials = await Promise.all(materialPromises);
    for (const d of devices) {
      const i = devices.indexOf(d);
      if (d.catalog) {
        content.items[deviceIndex[i]].devID = d.catalogId;
      } else {
        const addD = await this.addDeviceToCatalog(d, fromTenant);
        content.items[deviceIndex[i]].devID = addD.id;
        if (addD.newItem) {
          retVal.addDeviceNames.push(d.name);
        }
      }
    }
    for (const m of materials) {
      const i = materials.indexOf(m);
      if (m.catalog) {
        content.items[materialIndex[i]].matID = m.catalogId;
      } else {
        const addD = await this.addMaterialToCatalog(m, fromTenant);
        content.items[materialIndex[i]].matID = addD.id;
        if (addD.newItem) {
          retVal.addMaterialNames.push(m.name);
        }
      }
    }
    await set(dref, content);
    return retVal;
  }

  public hasList(name: string) {
    return new Promise<boolean>(resolve => {
      onValue(query(ref(this.db, '/lists'), orderByChild('name'), equalTo(name)),
        snap => {
          resolve(snap.exists());
        }, {onlyOnce: true});
    });
  }

  private async addDeviceToCatalog(data: Device, fromTenant: string) {
    const path = '/devices';
    const existId = await this.testReference(path, data.name);
    if (!!existId) {
      return {id: existId, name: data.name, newItem: false};
    }

    const newDeviceRef = push(ref(this.db, path));
    delete data.catalog;
    data.fileNames = await this.fileSvc.copyFiles(data.fileNames, fromTenant, '', 'equipment');
    delete data.id;
    delete data.maintListNames;
    delete data.maintLists;
    delete data.deviceInfo;
    set(newDeviceRef, data).then();
    return {id: newDeviceRef.key, name: data.name, newItem: true};
  }

  private async addMaterialToCatalog(data: Material, fromTenant: string) {
    const path = '/material';
    const existId = await this.testReference(path, data.name);
    if (!!existId) {
      return {id: existId, name: data.name, newItem: false};
    }

    const newDeviceRef = push(ref(this.db, path));
    delete data.catalog;
    data.fileNames = await this.fileSvc.copyFiles(data.fileNames, fromTenant, '', 'material');
    delete data.id;
    delete data.count;
    delete data.countThreshold;
    set(newDeviceRef, data).then();
    return {id: newDeviceRef.key, name: data.name, newItem: true};
  }

  private async testReference(path: string, name: string) {
    const existRef = query(ref(this.db, path), orderByChild('name'), equalTo(name));
    const id = new Promise<string>(resolve => {
      onValue(existRef, snapshot => {
        if (snapshot.exists()) {
          resolve(Object.keys(snapshot.val())[0]);
        } else {
          resolve(null);
        }
      }, {onlyOnce: true});
    });
    return await id;
  }
}
