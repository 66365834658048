import { Component, Input, NgZone, OnInit } from '@angular/core';
import { Doc } from '../../model/document';
import { StrDate } from '../../util/dateutil';
import { AlertController, LoadingController, ModalController, NavController } from '@ionic/angular';
import { ShowAlert } from '../../util/show-alert';
import { DocVersionComponent } from '../doc-version/doc-version.component';
import { DocEditComponent } from '../doc-edit/doc-edit.component';
import { DocumentService } from '../../services/document.service';
import { FileShowService } from '../../services/file-show.service';
import { SafeImage } from '../../util/safe-image';
import { DocumentProcessingService } from '../../services/document-processing.service';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-doc-detail',
  templateUrl: './doc-detail.component.html',
  styleUrls: ['./doc-detail.component.scss']
})
export class DocDetailComponent implements OnInit {
  @Input() doc: Doc;
  @Input() docs: Doc[];
  @Input() tenant: string;
  @Input() editCatalog = false;
  @Input() isAdmin = false;
  @Input() canRelease = false;

  versioned: boolean;
  editing = true;
  path: string;
  private _alert: ShowAlert;

  constructor(private docProcessing: DocumentProcessingService,
    private navCtrl: NavController,
    private modalCtrl: ModalController,
    private alertCtrl: AlertController,
    private docService: DocumentService,
    private zone: NgZone,
    private translate: TranslateService,
    private loadingCtrl: LoadingController,
    private fileShow: FileShowService) {
    this._alert = new ShowAlert(this.alertCtrl, this.translate);
  }

  static async AddVersion(doc: Doc, translate: TranslateService, alertCtrl: AlertController, docProcessing: DocumentProcessingService) {
    const al = new ShowAlert(alertCtrl, translate);
    al.withHeader('New Version').withMessage('Create a new version?');
    if (doc.kind === 'Text') {
      al.addButton('Yes, from empty', 'yes').addButton('Yes, from current', 'yes2');
    } else {
      al.addYesButton();
    }
    al.addNoButton();

    const ret = await al.show();
    if (ret === 'yes') {
      docProcessing.setData(doc, true);
      return true;
    }
    if (ret === 'yes2') {
      docProcessing.setData(doc, true, false);
      return true;
    }
    return false;
  }

  ngOnInit() {
    this.path = this.doc.catalog ? 'documents/' : `${ this.tenant }/documents/`;
    this.versioned = this.doc.versioned;
  }


  edit() {
    this.modalCtrl.create({
      component: DocEditComponent,
      componentProps: {doc: this.doc}
    }).then(modal => {
      modal.present().then();
      return modal.onDidDismiss();
    }).then(ret => {
      if (ret.role === 'show') {
        this.navigate();
      } else {
        this.docProcessing.process(ret.role, ret.data);
      }
    });
  }

  addVersion() {
    DocDetailComponent.AddVersion(this.doc, this.translate, this.alertCtrl, this.docProcessing).then();
  }

  toDate(date: number) {
    return StrDate(date);
  }

  navigate(i: number = 0) {
    switch (this.doc.kind) {
      case 'Link':
        window.open(this.doc.versions[i].data, '_blank');
        break;
      case 'File':
        SafeImage.OpenFile(this.path + this.doc.versions[i].data, this.loadingCtrl, this.fileShow);
        break;
      case 'Text':
        this.fileShow.doc = this.doc;
        this.fileShow.version = i;
        this.fileShow.newDoc = false;
        this.zone.run(() => {
          this.navCtrl.navigateForward('/documents/show').then();
        });
        break;
      default:
        break;
    }
  }

  showVersions() {
    this.modalCtrl.create({
      component: DocVersionComponent,
      componentProps: {doc: this.doc, canRelease: this.canRelease}
    }).then(modal => {
      modal.present().then();
      return modal.onDidDismiss();
    }).then(ret => {
      if (ret.role === 'show') {
        this.navigate(ret.data);
      }
    });
  }

  duplicate() {
    this._alert.withHeader('Duplicate').withMessage('Duplicate document?')
      .addYesButton().addNoButton().show().then(res => {
      if (res === 'yes') {
        this.docProcessing.duplicate(this.doc, this.docs);
      }
    });
  }
}
