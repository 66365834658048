import { Component } from '@angular/core';

import { Platform } from '@ionic/angular';
import { Router } from '@angular/router';
import { AuthService, AuthUser } from '@medmonitor/authlib';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss']
})
export class AppComponent {
  user: AuthUser;

  constructor(
    private platform: Platform,
    public authService: AuthService,
    private router: Router,
    public translate: TranslateService
  ) {
    this.initializeApp();
    this.authService.user.subscribe(u => {
      this.user = u;
    });
  }

  initializeApp() {
  }

  onLogout() {
    this.router.navigateByUrl('/auth', {replaceUrl: true}).then();
    this.authService.logout();
  }
}
