import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { AuthUser } from '@medmonitor/authlib';
import { TranslateService } from '@ngx-translate/core';
import { Subscription } from 'rxjs';
import { Doc } from '../../model/document';
import { AlertController, ModalController } from '@ionic/angular';
import { DocumentService } from '../../services/document.service';
import { TenantUserService } from '../../services/tenant-user.service';
import { StrDate } from '../../util/dateutil';
import { ShowAlert } from '../../util/show-alert';

@Component({
  selector: 'app-doc-version',
  templateUrl: './doc-version.component.html',
  styleUrls: ['./doc-version.component.scss']
})
export class DocVersionComponent implements OnInit, OnDestroy {
  @Input() doc: Doc;
  @Input() canRelease = false;
  private _users: AuthUser[];
  private _sub: Subscription;
  private _showAlert: ShowAlert;

  constructor(private modalCtrl: ModalController,
    private alertCtrl: AlertController,
    private docService: DocumentService,
    private tenantUserService: TenantUserService,
    private translate: TranslateService) {
    this._showAlert = new ShowAlert(alertCtrl, translate);
  }

  ngOnInit() {
    this._sub = this.tenantUserService.data.subscribe(u => {
      this._users = u;
    });
  }

  ngOnDestroy() {
    this._sub?.unsubscribe();
  }

  onCancel() {
    this.modalCtrl.dismiss().then();
  }

  toDate(date: number) {
    return StrDate(date);
  }

  show(i: number) {
    this.modalCtrl.dismiss(i, 'show').then();
  }

  toName(id: string) {
    const user = this._users?.find(u => u.id === id);
    return !!user ? user.name : '-';
  }

  release() {
    this.docService.release(this.doc, this.doc.versions[0].released, this._showAlert).then();
  }
}
