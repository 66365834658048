import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';

@Component({
  selector: 'app-catalog-button',
  templateUrl: './catalog-button.component.html',
  styleUrls: ['./catalog-button.component.scss'],
})
export class CatalogButtonComponent implements OnInit, OnChanges {
  @Input() names: string[];
  @Input() user: boolean[];
  @Input() admin: boolean[];
  @Input() isSuperAdmin = false;
  @Input() isAdmin = false;
  @Input() selection: number;
  @Input() change;
  @Output() selected = new EventEmitter<number>();

  shownNames: {name: string; index: number}[] = [];

  constructor() { }

  ngOnInit() {
    for (let i = 0; i < this.names.length; i++) {
      if (!this.isAdmin && !this.isSuperAdmin) {
        if (!!this.user) {
          if (this.user[i]) {
            this.shownNames.push({name: this.names[i], index: i});
          }
        } else {
          this.shownNames.push({name: this.names[i], index: i});
        }
      } else if (this.isAdmin && !this.isSuperAdmin) {
        if (!!this.admin) {
          if (this.admin[i]) {
            this.shownNames.push({name: this.names[i], index: i});
          }
        } else {
          this.shownNames.push({name: this.names[i], index: i});
        }
      } else {
        this.shownNames.push({name: this.names[i], index: i});
      }
    }
  }

  ngOnChanges(value: SimpleChanges) {
    if (!!value.change) {
      this.selection = 0;
      this.selected.emit(this.selection);
    }
  }

  onSelect(n: number) {
    this.selection = n;
    this.selected.emit(n);
  }
}
