import { Component, Input, OnInit } from '@angular/core';
import { PopoverController } from '@ionic/angular';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-morebuttons',
  templateUrl: './morebuttons.component.html',
  styleUrls: ['./morebuttons.component.scss']
})
export class MorebuttonsComponent implements OnInit {
  @Input() hasDuplicate = true;
  @Input() hasEdit = true;

  constructor(private popoverController: PopoverController,
    public translate: TranslateService) { }

  ngOnInit() {}

  do(what: string) {
    this.popoverController.dismiss(null, what).then();
  }
}
