import { ChangeDetectorRef, Component, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { CategoryData } from '../../model/category-data';
import { Doc, DocUtil, DocKind } from '../../model/document';
import { AlertController, ModalController, PickerController } from '@ionic/angular';
import { TranslateService } from '@ngx-translate/core';
import { DataCategoriesService } from '../../services/data-categories.service';
import { Subscription } from 'rxjs';
import { ListUtil } from '../../model/controllist';
import { DocumentProcessingService } from '../../services/document-processing.service';
import { ShowAlert } from '../../util/show-alert';
import { selectMaintenanceInterval } from '../../util/viewutil';
import { DocDetailComponent } from '../doc-detail/doc-detail.component';

@Component({
  selector: 'app-doc-edit',
  templateUrl: './doc-edit.component.html',
  styleUrls: ['./doc-edit.component.scss']
})
export class DocEditComponent implements OnInit, OnDestroy {
  @Input() doc: Doc;
  @Input() theory = false;
  @ViewChild('versionToggle') versionToggle;

  editDoc: Doc;
  newDoc: boolean;
  sub: Subscription;
  editText = new Map<DocKind, string>([
    ['Text', 'Edit Text'],
    ['Link', 'Change Link'],
    ['File', 'Change File']
  ]);
  newText = new Map<DocKind, string>([
    ['Text', 'Edit Text'],
    ['Link', 'Set Link'],
    ['File', 'Upload File']
  ]);
  categoryTypes: CategoryData[] = [];
  repIntervalText: string;
  private _alert: ShowAlert;

  constructor(private modalCtrl: ModalController,
    private alertCtrl: AlertController,
    public translate: TranslateService,
    private pickerCtrl: PickerController,
    private ref: ChangeDetectorRef,
    private docProcessing: DocumentProcessingService,
    private dataTypeSvc: DataCategoriesService) {
    this._alert = new ShowAlert(this.alertCtrl, this.translate);
  }

  ngOnInit() {
    this.newDoc = !this.doc.name;
    this.editDoc = DocUtil.DeepCopy(this.doc);
    this.setRepIntervalText();
    this.dataTypeSvc.getCategories('modules').then(t => {
      this.categoryTypes = t;
      this.ref.detectChanges();
    });
  }

  ngOnDestroy() {
    this.sub?.unsubscribe();
  }

  onCancel() {
    this.modalCtrl.dismiss().then();
  }

  onSave(doEdit = false) {
    DocUtil.CleanTheory(this.editDoc);
    const role = this.newDoc ? 'new' : (doEdit ? 'save-data' : 'save');
    this.modalCtrl.dismiss(this.editDoc, role).then();
  }

  setRepIntervalText() {
    if (this.editDoc.theory && !!this.editDoc.rep) {
      const interval = ListUtil.MAINT_INTERVAL[this.translate.currentLang][this.editDoc.repInterval - 1];
      this.repIntervalText = `${ this.editDoc.rep.toString() } ${ interval }`;
    } else {
      this.repIntervalText = this.translate.instant('Never');
    }
  }

  edit() {
    if (this.newDoc && !this.editDoc.name) {
      this._alert.withHeader('Set Name').withMessage('Please set a name first')
      .addOkButton().show().then();
      return;
    }
    if (this.editDoc.versioned && !!this.editDoc.versions[0].released) {
      this._alert
      .withHeader('Cannot Edit')
      .withMessage('Latest version already released and cannot be edited. Create a new version?')
      .addYesButton().addNoButton().addButton('Show', 'show')
      .show()
      .then(ret => {
        if (ret === 'yes') {
          this.modalCtrl.dismiss(null, 'new').then();
          return DocDetailComponent.AddVersion(this.editDoc, this.translate, this.alertCtrl, this.docProcessing);
        } else if (ret === 'show') {
          this.modalCtrl.dismiss(null, 'show').then();
        }
        return;
      });
    } else {
      this.onSave(true);
    }
  }

  async deleteDoc() {
    const choice = await this._alert.withHeader('Delete Document')
    .withMessage('Really delete document?',
      this.editDoc.versioned && this.editDoc.versions.length > 1 ? this.translate.instant(' And all its versions?') : '')
    .addYesDeleteButton().addNoButton().show();
    if (choice === 'yes') {
      await this.modalCtrl.dismiss(this.editDoc, 'delete');
    }
  }

  async checkVersioned() {
    if (this.editDoc.versions.length > 1 && !this.editDoc.versioned) {
      await this._alert.withHeader('Already Versioned')
        .withMessage('Versions already exist. Cannot set to unversioned.')
        .addOkButton().show();
      this.editDoc.versioned = true;
      this.versionToggle.checked = true;
    }
  }

  checkTheory() {
    if (!this.editDoc.theory) {
      this.editDoc.repInterval = 0;
      this.editDoc.rep = 0;
    }
    this.setRepIntervalText();
  }

  getCategory() {
    if (this.categoryTypes.length === 0) { // happens on page reload, wait until we read this from db
      return '';
    }
    let data = this.categoryTypes.find(t => t.category === this.editDoc.category);
    if (!data) { // can happen if a category was deleted. fall back to default
      data = this.categoryTypes[0];
      this.editDoc.category = this.categoryTypes[0].category;
    }
    return data[this.translate.currentLang];
  }

  selectRepInterval() {
    selectMaintenanceInterval(this.pickerCtrl, this.translate,
      this.editDoc?.repInterval ? this.editDoc.repInterval : 1,
      this.editDoc?.rep ? this.editDoc.rep : 0, true).then(result => {
      if (result) {
        this.editDoc.rep = result.length.value;
        this.editDoc.repInterval = result.length.value > 0 ? result.interval.value : 0;
        this.setRepIntervalText();
      }
    });
  }

  updateTags(tags: string[]) {
    this.editDoc.tags = tags;
  }

  setLength() {
    this.editDoc.length = this.editDoc.length < 1 ? 1 : this.editDoc.length;
    this.ref.detectChanges();
    this.ref.markForCheck();
  }
}
