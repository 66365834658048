import { ChangeDetectorRef, Component, OnDestroy, OnInit } from '@angular/core';
import { AuthService, AuthUser } from '@medmonitor/authlib';
import { Subscription } from 'rxjs';
import { DocFilterService } from '../services/doc-filter.service';

@Component({
  selector: 'app-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.scss']
})
export class ProfileComponent implements OnInit, OnDestroy {
  public user: AuthUser;
  public role = '';
  percentage = 0;
  private _sub: Subscription;

  constructor(private authService: AuthService,
    private docFilterService: DocFilterService,
    private ref: ChangeDetectorRef) {
  }

  ngOnInit() {
    this._sub = this.authService.user.subscribe(u => {
      this.user = u;
      if (!!u) {
        this.role = this.user.isAdmin ? 'Admin' : 'User';
      }
    });
    this._sub.add(this.docFilterService.theoryModulesCompletedPercentForUser().subscribe(n => {
      this.percentage = n;
      this.ref.detectChanges();
    }));
  }

  ngOnDestroy() {
    this._sub?.unsubscribe();
  }

  reset() {
    this.user.tenant = null;
    this.user.tenantName = null;
    this.authService.setUser(this.user);
  }
}
