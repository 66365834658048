import { Injectable } from '@angular/core';
import { AuthService, AuthUser } from '@medmonitor/authlib';
import { LogsService } from './logs.service';
import { Log } from '../model/log';
import { BehaviorSubject, Subscription } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { DateUtil, YearMonth } from '../util/dateutil';
import { ListsService } from './lists.service';
import { FirebaseApp } from '@angular/fire/app';
import { getFunctions, Functions, httpsCallable } from '@angular/fire/functions';

class SignatureData {
  data: string;
  uid: string;
  ts: number;
  tenant: string;
  lid: string;
}

class CommentImageData {
  data: string[];
  tenant: string;
  lid: string;
  ts: number;
}

class Filter {
  month: YearMonth;
  lists: string[];
  users: string[];
  types: string[];
  cancelled: boolean;
  deleted: boolean;
  inputText: string;
}

@Injectable({
  providedIn: 'root'
})
export class FilteredLogService {
  private _filteredLog: Log[];
  private _filtered = new BehaviorSubject<Log[]>(null);
  private _newSignatures = new BehaviorSubject<boolean>(false);
  private _detailed = new BehaviorSubject<boolean>(false);
  private _signatures = new Map<number, string>();
  private _images = new Map<number, string[]>();
  private _theLogs: Log[] = [];
  private _sigDate: YearMonth;
  private _filter = new Filter();
  private _listType: Map<string, string>;
  private _listSub: Subscription;
  private _logSub: Subscription;
  private _user: AuthUser;
  private _init = true;
  private _newestOnTop = true;
  private readonly _afs: Functions;
  private _ongoingImageCall = 0;

  constructor(private logService: LogsService,
    private authService: AuthService,
    private httpClient: HttpClient,
    private firebaseApp: FirebaseApp,
    private listService: ListsService) {
    this._afs = getFunctions(firebaseApp, 'europe-west6');
    this.initFilters();
    this.authService.user.subscribe(u => {
      this.cancelListeners();
      if (!!u) {
        this._user = u;
        this._init = true;
        this._listSub = this.listService.lists.subscribe(lists => {
          if (!!lists) {
            this._listType = this.listService.typeMap();
            this.applyFilters();
          }
        });
        this._logSub = this.logService.logs.subscribe(logs => {
          if (!!logs) {
            this._theLogs = logs;
            this.sortLog();
            if (this._init) {
              this.initLog();
              this._init = false;
            }
            // new log list loaded!
            this.applyFilters();
          }
        });
      }
    });
  }

  get detailed() {
    return this._detailed.asObservable();
  }

  get filteredLog() {
    return this._filtered.asObservable();
  }

  get filters(): Filter {
    return this._filter;
  }

  async signature(ts: number): Promise<string> {
    if (this._signatures.has(ts)) {
      return this._signatures.get(ts);
    }
    try {
      const result = await httpsCallable(this._afs, 'getSignatureList')({tenant: this._user.tenant, ts});
      const data: SignatureData[] = JSON.parse(result.data as string);
      this._signatures.set(ts, data[0].data);
      return data[0].data;
    } catch (err) {
      console.log('signature');
      console.log(err);
      return '';
    }
  }

  async image(ts: number): Promise<string[]> {
    if (!this._images.has(ts)) {
      if (this._ongoingImageCall === ts) {
        return;
      }
      try {
        this._ongoingImageCall = ts;
        const res = await httpsCallable(this._afs, 'getImage')({tenant: this._user.tenant, ts});
        this._ongoingImageCall = null;
        const data: CommentImageData = JSON.parse(res.data as string);
        this._images.set(ts, data.data);
        return data.data;
      } catch (err) {
        console.log(err);
        return [];
      }
    } else {
      return this._images.get(ts);
    }
  }

  setMonthFilter(newMonth: Date): boolean {
    const d = new YearMonth(newMonth.getUTCFullYear(), newMonth.getUTCMonth());
    if (!d.equals(this._filter.month)) {
      this._filter.month = d;
      this.logService.initializeLog(d);
      return true;
    }
    return false;
  }

  // getSignatures(date: YearMonth) {
  //   if (!this.haveSignatureMonth(date)) {
  //     this.getSignaturesForMonth(date);
  //   }
  // }

  // haveSignatureMonth(date: YearMonth): boolean {
  //   let haveMonth = 0;
  //   for (const k of this._signatures.keys()) {
  //     const kd = new Date(k);
  //     haveMonth += kd.getUTCFullYear() === date.year && kd.getUTCMonth() === date.month ? 1 : 0;
  //   }
  //   return haveMonth > 0;
  // }

  getSignaturesForMonth(date: YearMonth) {
    if (!date.equals(this._sigDate) && !!this._user.tenant) {
      this._sigDate = date;
      this._newSignatures.next(false); // notify about new set of signatures to be loaded
      httpsCallable(this._afs, 'getSignatureList')(
        {
          tenant: this._user.tenant,
          year: this._sigDate.year,
          month: this._sigDate.month
        })
      .then(res => {
        const data: SignatureData[] = JSON.parse(res.data as string);
        data.forEach(d => {
          this._signatures.set(d.ts, d.data);
        });
        this._newSignatures.next(true);
      })
      .catch(err => {
        console.log('getSignaturesForMonth');
        console.log(err);
      });
    }
  }

  setListFilter(selectedLists: string[]) {
    this._filter.lists = selectedLists;
    this.applyFilters();
  }

  setUserFilter(selectedUsers: string[]) {
    this._filter.users = selectedUsers;
    this.applyFilters();
  }

  setCancelled(cancelled: boolean) {
    this._filter.cancelled = cancelled;
    this.applyFilters();
  }

  setNewestTop(newestOnTop: boolean) {
    this._newestOnTop = newestOnTop;
    this.sortLog();
    this.applyFilters();
  }

  setMaintenance(maintType: boolean) {
    this.toggleType(maintType, 'maintenance');
  }

  setRequest(reqType: boolean) {
    this.toggleType(reqType, 'request');
  }

  setOpen(openType: boolean) {
    this.toggleType(openType, 'open');
  }

  setTask(taskType: boolean) {
    this.toggleType(taskType, 'task');
  }

  setClose(closeType: boolean) {
    this.toggleType(closeType, 'close');
  }

  setSteril(sterilType: boolean) {
    this.toggleType(sterilType, 'steril');
  }

  setDaily(dailyType: boolean) {
    this.toggleType(dailyType, 'daily');
  }

  setDeleted(deleteType: boolean) {
    this._filter.deleted = deleteType;
    this.applyFilters();
  }

  setSearchInput(inputText: string) {
    this._filter.inputText = inputText;
    this.applyFilters();
  }

  cancelListeners() {
    this._listSub?.unsubscribe();
    this._logSub?.unsubscribe();
    this._signatures.clear();
    this._images.clear();
  }

  public initLog() {
    this.initFilters();
    this._filteredLog = this._theLogs;
    this._filtered.next(this._filteredLog);
  }

  private wasNotCancelled(log: Log) {
    if (this._filter.cancelled) {
      return log.endDate !== 0;
    }
    return true;
  }

  private isInLists(log: Log) {
    return this._filter.lists.length === 0 || (this._filter.lists.length > 0 && this._filter.lists.includes(log.listId));
  }

  private isInUsers(log: Log) {
    return this._filter.users.length === 0 || (this._filter.users.length > 0 && this._filter.users.includes(log.userId));
  }

  private isInTypes(log: Log) {
    const type = log.listType || this._listType?.get(log.listId);
    let hasType = !!type && this._filter.types.includes(type);
    if (!this._filter.deleted) {
      hasType = hasType && !!this._listType?.has(log.listId);
    }
    return hasType;
  }

  private isInInputOrComment(log: Log) {
    if (!this._filter.inputText) {
      return true;
    }
    for (const event of log.eventList) {
      if (!!event.input && event.input.toLowerCase().includes(this._filter.inputText.toLocaleLowerCase())) {
        return true;
      }
      if (!!event.comment && event.comment.toLowerCase().includes(this._filter.inputText.toLocaleLowerCase())) {
        return true;
      }
    }
    return false;
  }

  private getFilteredList() {
    this._theLogs.forEach(log => {
      if (
        this.isInLists(log) &&
        this.isInUsers(log) &&
        this.isInTypes(log) &&
        this.wasNotCancelled(log) &&
        this.isInInputOrComment(log)
      ) {
        this._filteredLog.push(log);
      }
    });
  }

  private applyFilters() {
    this._filteredLog = [];
    this.getFilteredList();
    this._filtered.next(this._filteredLog);
    // this.getSignatures(this._filter.month);
  }

  private initFilters() {
    this._filter.month = DateUtil.ThisMonth();
    this._filter.lists = [];
    this._filter.users = [];
    this._filter.types = ['open', 'close', 'maintenance', 'request', 'steril', 'daily', 'task'];
    this._filter.cancelled = false;
    this._filter.deleted = false;
    this._filter.inputText = '';
  }

  private toggleType(typFlag: boolean, typ: string) {
    const i = this._filter.types.findIndex(f => f === typ);
    if (typFlag) {
      if (i < 0) {
        this._filter.types.push(typ);
      }
    } else {
      if (i >= 0) {
        this._filter.types.splice(i, 1);
      }
    }
    this.applyFilters();
  }

  private sortLog() {
    const sign = this._newestOnTop ? -1 : 1;
    this._theLogs.sort((a, b) => sign * (a.startDate - b.startDate));
  }
}
