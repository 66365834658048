import { Database } from '@angular/fire/database';
import { AuthService } from '@medmonitor/authlib';
import { CatalogData } from '../model/catalog-data';
import { UserDataService } from './user-data.service';

export abstract class MatDevServiceBase<T extends CatalogData> extends UserDataService<T> {
  protected _catalog: T[] = [];

  protected constructor(protected db: Database,
    protected authService: AuthService,
    path: string) {
    super(db, authService, path);
  }

  public get catalogNames() {
    return this._catalog.map(c => c.name);
  }

  public catalogContains(name: string) {
    return this._catalog.some(c => c.name === name);
  }

  protected processItem(index: number, obj: T) {
    const item = obj;
    if (item.catalog) {
      const catItem = this._catalog.find(c => c.id === item.catalogId);
      if (!!catItem) {
        const newObj = Object.assign({...item}, catItem);
        newObj.id = item.id;
        super.processItem(index, newObj);
      }
    } else {
      super.processItem(index, obj);
    }
  }

  protected processList() {
    this._theList.forEach((item, index) => {
      if (item.catalog && !item.name) {
        const catItem = this._catalog.find(cat => cat.id === item.catalogId);
        this._theList[index] = {...item, ...catItem};
        this._theList[index].id = item.id;
      }
    });
  }
}
