import { Injectable } from '@angular/core';
import { FirebaseApp } from '@angular/fire/app';
import { Functions, getFunctions, httpsCallable } from '@angular/fire/functions';
import {
  deleteObject,
  getDownloadURL,
  ref,
  uploadBytesResumable,
  Storage,
  StorageReference, getMetadata
} from '@angular/fire/storage';
import * as ShortUid from 'short-uuid';
import { BehaviorSubject } from 'rxjs';
import { DocumentData } from '../model/document-data';
import { NiceDate } from '../util/dateutil';
import { BackendError } from '@medmonitor/authlib';

@Injectable({
  providedIn: 'root'
})
export class FileUploadService {

  private _filesToDelete: string[];
  private _currentData: DocumentData[];
  private _uploadPath: string;
  private _progress = new BehaviorSubject<{ progress: number; name: string }>({progress: 0, name: ''});
  private readonly _storageRef: StorageReference;
  private readonly _afs: Functions;

  constructor(private fs: Storage, private firebaseApp: FirebaseApp) {
    this._afs = getFunctions(this.firebaseApp, 'europe-west6');
    this._storageRef = ref(this.fs);
    this.reset();
  }

  get progressUpload() {
    return this._progress.asObservable();
  }

  get numberOfFilesToUpload() {
    return this._currentData.filter(x => !!x.file).length;
  }

  private static SetMimeType(fname: string) {
    if (fname.endsWith('pdf')) {
      return {
        contentType: 'application/pdf'
      };
    }
    if (fname.endsWith('txt')) {
      return {
        contentType: 'text/plain'
      };
    }
    if (fname.endsWith('doc')) {
      return {
        contentType: 'application/msword'
      };
    }
    if (fname.endsWith('jpg') || fname.endsWith('jpeg')) {
      return {
        contentType: 'image/jpeg'
      };
    }
    if (fname.endsWith('png')) {
      return {
        contentType: 'image/png'
      };
    }
    if (fname.endsWith('docx')) {
      return {
        contentType: 'application/vnd.openxmlformats-officedocument.wordprocessingml.document'
      };
    }

  }

  public uploadNewFile(fileToUpload: DocumentData, currentName: string = null): void {
    fileToUpload.name = !!currentName ? currentName : ShortUid.generate() + '_' + fileToUpload.file.name;
    fileToUpload.exists = false;
    this._currentData.push(fileToUpload);
  }

  public deleteFile(fileToDelete: string, index: number): void {
    if (this._currentData[index].exists) {
      this._filesToDelete.push(fileToDelete);
    }
    this._currentData.splice(index, 1);
  }

  public reset(): void {
    this._filesToDelete = [];
    this._currentData = [];
    this._uploadPath = null;
  }

  public setCurrentData(fileNames: string[], fileDescs: string[]) {
    this.reset();
    if (!!fileNames && !!fileDescs && fileNames.length > 0 &&
      fileNames.length === fileDescs.length) {
      fileNames.forEach((name, index) => {
        const d = new DocumentData();
        d.name = name;
        d.description = fileDescs[index];
        d.exists = true;
        this._currentData.push(d);
      });
    }
  }

  getCurrentNames(): string[] {
    return this._currentData.map(x => x.name);
  }

  getCurrentDescriptions(): string[] {
    return this._currentData.map(x => x.description);
  }

  getCurrentLinks(): string[] {
    return this._currentData.map(x => x.link);
  }

  setUploadPath(uploadFilePath: string) {
    this._uploadPath = uploadFilePath;
  }

  async save() {
    await this.uploadAllFiles();
    for (const fileName of this._filesToDelete) {
      await this.deleteFileFromStorage(this._uploadPath + fileName);
    }
  }

  async uploadAllFiles() {
    for (const item of this._currentData) {
      if (!!item.file) {
        await this.uploadFile(this, item.file,
          this._uploadPath + item.name, this.progressCallback);
      }
    }
  }

  progressCallback(obj: any, progress: number, name: string) {
    obj._progress.next({progress, name});
  }

  async getFileUpdated(fullPath: string) {
    return getMetadata(ref(this.fs, fullPath)).then(md => !!md ? md.updated : '');
  }

  async getFileDates(path: string, fileNames: string[]) {
    const fileDates: string[] = [];
    for (const fileName of fileNames) {
      try {
        const dateStr = await this.getFileUpdated(path + '/' + fileName);
        const d = new Date(dateStr);
        fileDates.push(NiceDate(d));
      } catch (err) {
        console.log(err);
        fileDates.push('ERROR');
      }
    }
    return fileDates;
  }

  checkIfNameExists(path: string) {
    return getMetadata(ref(this._storageRef, path))
    .then(() => true)
    .catch(() => false);
  }

  uploadFile(obj: any, file: File, fileName: string,
    progressFn: (obj: any, progress: number, name: string) => void): Promise<string> {

    return new Promise((resolve, reject) => {
      const uploadTask = uploadBytesResumable(ref(this._storageRef, fileName), file, FileUploadService.SetMimeType(fileName.toLowerCase()));
      uploadTask.on('state_changed', snapshot => {
        const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
        switch (snapshot.state) {
          case 'paused':
          case 'running':
            // console.log(snapshot.state, progress);
            progressFn(obj, progress, fileName);
            break;
        }
      }, (err) => {
        reject(new BackendError(fileName + ': ' + err.message));
      }, async () => {
        const url = await getDownloadURL(uploadTask.snapshot.ref);
        resolve(url as string);
      });
    });
  }

  async deleteFileFromStorage(fileName: string) {
    return deleteObject(ref(this._storageRef, fileName));
  }

  async copyFiles(fileNames: string[], fromPrefix: string, toPrefix: string, path: string) {
    const newFileNames: string[] = [];
    for (const fName of fileNames) {
      const from = `${this.slash(fromPrefix)}${ path }/${ fName }`;
      const newName = ShortUid.generate() + '_' + fName.substring(fName.indexOf('_') + 1);
      const to = `${this.slash(toPrefix)}${ path }/${ newName }`;
      // console.log('COPY   ', from, to);
      newFileNames.push(newName);
      try {
        await httpsCallable(this._afs, 'copyFile')({from, to});
      } catch (err) {
        console.log('copyFile failed', from, 'to', to);
        console.log(err);
      }
    }
    return newFileNames;
  }

  private slash = (path: string) => !!path ? path + '/' : '';
}
