import {Component, Input, OnInit} from '@angular/core';
import {PopoverController} from '@ionic/angular';
import {TranslateService} from '@ngx-translate/core';

@Component({
  selector: 'app-default-selection',
  templateUrl: './default-selection.component.html',
  styleUrls: ['./default-selection.component.scss'],
})
export class DefaultSelectionComponent implements OnInit {
  @Input() list: string[];
  @Input() kind: string;

  constructor(private popoverController: PopoverController,
              public translate: TranslateService) { }

  ngOnInit() {
  }

  do(l: string) {
    this.popoverController.dismiss(l, 'ok').then();
  }
}
