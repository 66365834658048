import { Injectable } from '@angular/core';
import { Database } from '@angular/fire/database';
import { AuthService } from '@medmonitor/authlib';
import { Material } from '../model/material';
import { MaterialCatalogService } from './data.service';
import { MatDevServiceBase } from './mat-dev-service-base';

@Injectable({
  providedIn: 'root'
})
export class MaterialService extends MatDevServiceBase<Material> {
  constructor(protected db: Database,
    protected authService: AuthService,
    private matCatService: MaterialCatalogService) {
    super(db, authService, 'myMaterial');
    this.matCatService.data.subscribe(cat => {
      this._catalog = cat;
      this.processList();
    });
  }
}
