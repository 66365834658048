import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  ElementRef, EventEmitter,
  OnDestroy,
  OnInit,
  Output,
  ViewChild
} from '@angular/core';
import { addDays, addMonths, format, parse } from 'date-fns';
import { Subscription } from 'rxjs';
import { TotalStat } from '../model/stat';
import { TranslateService } from '@ngx-translate/core';
import { ChartConfiguration } from 'chart.js';
import { UserStatsService } from '../services/userstats.service';
import { Lead0 } from '../util/dateutil';

type ChartInterval = 'years' | 'months' | 'days' | 'weeks';

@Component({
  selector: 'app-barchart',
  templateUrl: './barchart.component.html',
  styleUrls: ['./barchart.component.scss']
})
export class BarchartComponent implements OnInit, OnDestroy, AfterViewInit {
  @ViewChild('topBox') topBox: ElementRef;
  @Output() chartHeight = new EventEmitter<number>();

  public barChartOptions: ChartConfiguration<'bar'>['options'] = {
    plugins: {
      title: {
        text: '',
        display: true,
        padding: 15,
        font: {
          family: 'Lato',
          size: 20,
          style: 'normal'
        }
      },
      legend: {
        position: 'bottom'
      },
      tooltip: {
        xAlign: 'right',
        yAlign: 'top'
      }
    },
    responsive: true,
    scales: {
      x: {},
      y: {suggestedMin: 0}
    }
  };
  public barChartData: ChartConfiguration<'bar'>['data'] = {
    labels: ['-'],
    datasets: [{data: [0], label: 'WAIT'}]
  };

  currentInterval: ChartInterval = 'weeks';
  start: { days: string; months: string; weeks: string; years: string};

  private _sub: Subscription;
  private _stats: TotalStat[];

  constructor(public userStatService: UserStatsService,
    private translate: TranslateService,
    private ref: ChangeDetectorRef) {
  }

  ngOnInit() {
    this._sub = this.userStatService.totals.subscribe(stats => {
      if (!!stats && stats.length > 0) {
        this._stats = stats;
        this.barChartOptions.plugins.title.text = this.translate.instant('Controllist completion count').toUpperCase();
        this.start = this.start ?? {...this.userStatService.max};
        this.createChartData(this.start[this.currentInterval], this.currentInterval);
        this.ref.markForCheck();
        this.ref.detectChanges();
      }
    });
  }

  ngOnDestroy() {
    this._sub?.unsubscribe();
  }

  ngAfterViewInit() {
    const obs = new ResizeObserver(entries => {
      for (const entry of entries) {
        this.chartHeight.emit(entry.contentRect.height);
      }
    });
    obs.observe(this.topBox.nativeElement);
  }

  createChartData(toDate: string, interval: ChartInterval) {
    if (toDate < this.userStatService.min[interval]) {
      return;
    }
    const labels: string[] = [];
    const datasets: { data: number[]; label: string }[] = [];
    for (let i = 9; i >= 0; i--) {
      const date = this.addInterval(toDate, -i, interval);
      if (date >= this.userStatService.min[interval]) {
        labels.push(date);
      }
    }

    this._stats.forEach(stat => {
      const data = [];
      const label = stat.name;
      if (!!label) {
        let total = 0;
        for (let i = 9; i >= 0; i--) {
          const date = this.addInterval(toDate, -i, interval);
          if (date >= this.userStatService.min[interval]) {
            const val = stat[interval][date] ?? 0;
            total += val;
            data.push(val);
          }
        }
        if (total > 0) {
          datasets.push({data, label});
        }
      }
    });
    this.barChartData = {datasets, labels};
    this.currentInterval = interval;
  }

  moveData(sign: number) {
    const move10 = this.addInterval(this.start[this.currentInterval], sign * 10, this.currentInterval);
    if (move10 < this.userStatService.min[this.currentInterval] || move10 > this.userStatService.max[this.currentInterval]) {
      return;
    }
    this.start[this.currentInterval] = move10;
    this.createChartData(move10, this.currentInterval);
  }

  private addInterval(date: string, n: number, interval: ChartInterval) {
    switch (interval) {
      case 'years':
        return `${ +date + n }`;
      case 'weeks':
        const [y, w] = date.split('-');
        const week = +w + n;
        if (week < 1) {
          return `${ +y - 1 }-${ 52 + week }`;
        } else if (week > 52) {
          return `${ +y + 1}-${ Lead0(week - 52) }`;
        }
        return y + `-${ Lead0(week) }`;
      case 'months':
        const m = parse(date, 'yyyy-MM', new Date());
        return format(addMonths(m, n), 'yyyy-MM');
      case 'days':
        const d = parse(date, 'yyyy-MM-dd', new Date());
        return format(addDays(d, n), 'yyyy-MM-dd');
    }
  }
}
