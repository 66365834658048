import { Injectable } from '@angular/core';
import { Database } from '@angular/fire/database';
import { AuthService } from '@medmonitor/authlib';
import { Tablet } from '../model/tablet';
import { UserDataService } from './user-data.service';

@Injectable({
  providedIn: 'root'
})
export class TabletService extends UserDataService<Tablet> {
  constructor(protected db: Database,
    protected authService: AuthService) {
    super(db, authService, 'tablets', true);
  }

  // if a list is removed, remove the list from all tablets as well.
  public removeList(lid: string) {
    this._theList.forEach(t => {
      if (!!t.listIds && t.listIds.length > 0) {
        const listIndex = t.listIds.findIndex(id => id === lid);
        if (listIndex >= 0) {
          t.listIds.splice(listIndex, 1);
          this.setData(t).then();
        }
      }
    });
  }
}
