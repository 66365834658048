import { Injectable } from '@angular/core';
import { AuthService, AuthUser } from '@medmonitor/authlib';
import { BehaviorSubject } from 'rxjs';
import { Doc, DocUtil, DocVersion } from '../model/document';
import { ShowAlert } from '../util/show-alert';
import {
  child,
  DatabaseReference,
  off,
  onChildAdded,
  onChildChanged,
  onChildRemoved,
  onValue,
  update,
  ref, push, remove, Database
} from '@angular/fire/database';
import { FileUploadService } from './file-upload.service';

@Injectable({
  providedIn: 'root'
})
export class DocumentService {
  private static DELAYED_EXEC: any;
  private _tagsArray: string[] = [];
  private _catalogArray: Doc[] = [];
  private _docArray: Doc[] = [];
  private _tags = new BehaviorSubject<string[]>([]);
  private _docs = new BehaviorSubject<Doc[]>([]);
  private _catalog = new BehaviorSubject<Doc[]>([]);
  private _user: AuthUser;
  private _docRef: DatabaseReference;
  private readonly _catRef: DatabaseReference;
  private _tagRef: DatabaseReference;

  constructor(private db: Database,
    private fileSvc: FileUploadService,
    private auth: AuthService) {
    this.auth.user.subscribe(u => {
      this._user = u;
      this.cancelListeners(); // remove any previous listener
      if (!!u) {
        this._docArray.length = 0;
        if (!!this._user.tenant) {
          const dbRef = ref(this.db, `/documents/${ this._user.tenant }`);
          this._docRef = child(dbRef, 'docs');
          this._tagRef = child(dbRef, 'tags');
          this.listen();
        } else {
          this._tagRef = ref(this.db, '/documentCatalogTags');
        }
        onValue(this._tagRef, (snap) => {
          if (snap.exists()) {
            this._tagsArray.length = 0;
            const value = snap.val();
            Object.keys(value).forEach(id => {
              this._tagsArray.push(value[id] as string);
            });
            this._tagsArray.sort((a, b) => a.localeCompare(b));
            this._tags.next(this._tagsArray);
          }
        });
      }
    });
    this._catRef = ref(this.db, '/documentCatalog');
    onValue(this._catRef, (snap) => {
      if (snap.exists()) {
        this._catalogArray.length = 0;
        Object.keys(snap.val()).forEach(id => {
          this._catalogArray.push(this.getDoc(id, snap.val()[id]));
        });
        this._catalog.next(this._catalogArray);
      }
    });
  }

  get tags() {
    return this._tags.asObservable();
  }

  get docs() {
    return this._docs.asObservable();
  }

  get catalog() {
    return this._catalog.asObservable();
  }

  private static PrepData(data: Doc): Doc {
    const setData = {...data};
    delete setData.id;
    delete setData.versions;
    if (!setData.description) {
      delete setData.description;
    }
    return setData;
  }

  public cancelListeners() {
    if (this._docRef) {
      off(this._docRef);
      this._docRef = null;
    }
    if (this._tagRef) {
      off(this._tagRef);
    }
  }

  update(doc: Doc) {
    if (!doc.id) {
      return;
    }
    const setData = DocumentService.PrepData(doc);
    if (doc.catalog) {
      update(child(this._catRef, `${ doc.id }`), setData).then();
    } else {
      update(child(this._docRef, `${ doc.id }`), setData).then();
    }
  }

  addDoc(data: Doc) {
    if (!!data) {
      const setData = DocumentService.PrepData(data);
      const newDoc = data.catalog ? push(this._catRef, setData) : push(this._docRef, setData);
      return newDoc.key;
    }
  }

  addVersion(id: string, version: DocVersion) {
    const v = push(child(this._docRef, `${ id }/versions`), version);
    return v.key;
  }

  addVersionCatalog(id: string, version: DocVersion) {
    const v = push(child(this._catRef, `${ id }/versions`), version);
    return v.key;
  }

  addTag(tag: string) {
    push(this._tagRef, tag);
  }

  updateVersion(id: string, vid: string, data: DocVersion) {
    const setData = {...data};
    delete setData.id;
    update(child(this._docRef, `${ id }/versions/${ vid }`), setData).then();
  }

  updateVersionCatalog(id: string, vid: string, data: DocVersion) {
    const setData = {...data};
    delete setData.id;
    update(child(this._catRef, `${ id }/versions/${ vid }`), setData).then();
  }

  delete(id: string) {
    if (!!id) {
      remove(child(this._docRef, id)).then();
    }
  }

  deleteFromCatalog(id: string) {
    if (!!id) {
      remove(child(this._catRef, id)).then();
    }
  }

  async copyFromCatalog(doc: Doc) {
    const newDoc = DocUtil.DeepCopy(doc, true);
    delete newDoc.id;
    delete newDoc.versions;
    const newVersion = new DocVersion();
    newVersion.created = Date.now();
    newVersion.createdBy = this._user.isSuperadmin ? 'ADMIN' : this._user.id;
    newVersion.modified = Date.now();
    newVersion.modifiedBy = this._user.isSuperadmin ? 'ADMIN' : this._user.id;

    if (newDoc.kind !== 'Link') {
      const names = await this.fileSvc.copyFiles([doc.versions[0].data], '', this._user.tenant, 'documents');
      newVersion.data = names[0];
    } else {
      newVersion.data = doc.versions[0].data.repeat(1);
    }

    // check if the tags on the document already exist for this tenant, create them if not. The doc will have them.
    if (newDoc.tags.length > 0) {
      const newTags: string[] = [];
      newDoc.tags.forEach(tag => {
        if (!this._tagsArray.includes(tag)) {
          newTags.push(tag);
        }
      });
      newTags.forEach(tag => {
        this.addTag(tag);
      });
    }
    const newRef = await push(this._docRef, newDoc);
    this.addVersion(newRef.key, newVersion);
    return newRef.key;
  }

  async newCatalogItem(id: string): Promise<string> {
    if (this._docArray.findIndex(d => d.catalogId === id) < 0) {
      const newCatItem = await push(this._docRef, {catalogId: id, catalog: true});
      return newCatItem.key;
    }
    return null;
  }

  async release(doc: Doc, released: number | null, showAlert: ShowAlert) {
    let result = released;
    if (doc.catalog) {
      return;
    }
    if (!released) {
      if (!this._user.release) {
        await showAlert.withHeader('Not Released')
        .withMessage('Please ask someone with authorization to release the document.')
        .addOkButton().show();
        return;
      }
      const choice = await showAlert.withHeader('Release')
      .withMessage('Release document? Editing this version will not be possible anymore.')
      .addYesButton().addNoButton().show();
      if (choice === 'yes') {
        doc.versions[0].released = Date.now();
        doc.versions[0].releasedBy = this._user.name;
        result = doc.versions[0].released;

        this.updateVersion(doc.id, doc.versions[0].id, doc.versions[0]);
      }
    }
    return result;
  }

  private getDoc(dataKey: string, dataValue) {
    const doc = Object.assign({id: dataKey}, dataValue);
    const versions = dataValue.versions;
    doc.versions = [];
    if (!!versions) {
      Object.keys(versions).forEach(key => {
        doc.versions.push(Object.assign({id: key}, versions[key]) as DocVersion);
      });
      doc.versions.sort((a, b) => b.created - a.created);
    } else {
      doc.versions.push(new DocVersion());
    }
    return doc as Doc;
  }

  private listen() {
    onChildAdded(this._docRef, (data) => {
      this._docArray.push(this.getDoc(data.key, data.val()));
      if (DocumentService.DELAYED_EXEC) {
        clearTimeout(DocumentService.DELAYED_EXEC);
      }
      DocumentService.DELAYED_EXEC = setTimeout(() => {
        this._docs.next(this._docArray);
        DocumentService.DELAYED_EXEC = null;
      }, 250);
    }, err => {
      console.log(Date.now(), err);
      this.cancelListeners();
    });
    onChildRemoved(this._docRef, (data) => {
      const i = this._docArray.findIndex(l => l.id === data.key);
      if (i >= 0) {
        this._docArray.splice(i, 1);
        this._docs.next(this._docArray);
      }
    }, err => {
      console.log(Date.now(), err);
      this.cancelListeners();
    });
    onChildChanged(this._docRef, (data) => {
      const i = this._docArray.findIndex(l => l.id === data.key);
      if (i >= 0) {
        this._docArray[i] = this.getDoc(data.key, data.val());
        this._docs.next(this._docArray);
      }
    }, err => {
      console.log(Date.now(), err);
      this.cancelListeners();
    });
  }
}
