import { ChangeDetectorRef, Component, OnDestroy, OnInit } from '@angular/core';
import { ListsService } from '../services/lists.service';
import { Subscription } from 'rxjs';
import { UserStatsService } from '../services/userstats.service';

@Component({
  selector: 'app-liststatus',
  templateUrl: './list-status.component.html',
  styleUrls: ['./list-status.component.scss']
})
export class ListStatusComponent implements OnInit, OnDestroy {
  userMonthStat: number;
  maintAlerts = 0;
  cleanAlerts = 0;
  sub: Subscription;

  constructor(private listsService: ListsService,
    private userStatService: UserStatsService,
    private ref: ChangeDetectorRef) {
  }

  ngOnInit() {
    this.sub = this.listsService.lists.subscribe(lists => {
      if (lists) {
        this.maintAlerts = 0;
        this.cleanAlerts = 0;
        lists.forEach(l => {
          if (l.alert) {
            if (l.type === 'maintenance') {
              this.maintAlerts += 1;
            } else {
              this.cleanAlerts += 1;
            }
          }
        });
        this.ref.markForCheck();
        this.ref.detectChanges();
      }
    });
    this.sub.add(this.userStatService.currentUserMonthTotal.subscribe(stat => {
      if (stat) {
        this.userMonthStat = stat;
        this.ref.markForCheck();
        this.ref.detectChanges();
      }
    }));
  }

  ngOnDestroy() {
    if (this.sub) {
      this.sub.unsubscribe();
    }
  }
}
