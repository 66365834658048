import { ChangeDetectorRef, Component, Input, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';

@Component({
  selector: 'app-selector-modal',
  templateUrl: './selector-modal.component.html',
  styleUrls: ['./selector-modal.component.scss']
})
export class SelectorModalComponent implements OnInit {
  @Input() items: { name: string; id: string }[];
  @Input() multi = false;
  @Input() firstOnEmpty = false;
  @Input() title: string;
  @Input() selected: string | string[];
  selection = new Map<string, boolean>();
  allSelected = false;

  constructor(private ref: ChangeDetectorRef,
    private modalCtrl: ModalController) { }

  ngOnInit() {
    this.items.forEach(item => {
      if (this.selected !== undefined) {
        const value = this.multi ?
                      (this.selected as string[]).includes(item.id) :
                      item.id === (this.selected as string);
        this.selection.set(item.id, value);
      } else {
        this.selection.set(item.id, false);
      }
    });
    if (!this.selected && this.firstOnEmpty && !this.multi) {
      this.selection.set(this.items[0].id, true);
    }
  }

  dismiss() {
    this.modalCtrl.dismiss().then();
  }

  save() {
    const keys = [];
    for (const key of this.selection.keys()) {
      if (this.selection.get(key)) {
        keys.push(key);
      }
    }
    let result = this.multi ? [] : '';
    if (keys.length > 0) {
      result = this.multi ? keys : keys[0];
    }
    this.modalCtrl.dismiss(result, 'ok').then();
  }

  toggle(id: string) {
    if (this.multi) {
      this.selection.set(id, !this.selection.get(id));
    } else {
      let hasSelection = false;
      for (const key of this.selection.keys()) {
        this.selection.set(key, key === id ? !this.selection.get(id) : false);
        hasSelection = hasSelection || this.selection.get(key);
      }
      if (!hasSelection && this.firstOnEmpty) {
        this.selection.set(this.items[0].id, true);
      }
    }
    this.ref.markForCheck();
    this.ref.detectChanges();
  }

  all() {
    this.allSelected = !this.allSelected;
    for (const key of this.selection.keys()) {
      this.selection.set(key, this.allSelected);
    }
  }
}
