import { Injectable } from '@angular/core';
import { Database, onValue, ref, remove, set, update } from '@angular/fire/database';
import { AuthService, AuthUser, NewUser } from '@medmonitor/authlib';
import { UserDataService } from './user-data.service';

@Injectable({
  providedIn: 'root'
})
export class TenantUserService extends UserDataService<AuthUser> {
  constructor(protected db: Database,
    protected authService: AuthService) {
    super(db, authService, 'users', true);
  }

  private static RandomId() {
    return Math.random().toString(36).substring(2, 7);
  }

  newUser(user: NewUser) {
    return new Promise<boolean>(resolve => {
      onValue(ref(this.db, `tenants/${ user.tenant }/admins`), dataSnap => {
        const admins = dataSnap.val();
        if (admins) {
          Object.keys(admins).forEach(k => {
            user.managers[k] = true;
          });
        }
        const path = `tenants/${ user.tenant }/newusers/new${ TenantUserService.RandomId() }`;
        set(ref(this.db, path), user).then(() => {
          resolve(true);
        }).catch(err => {
          console.log('got error', err);
          resolve(false);
        });
      }, {onlyOnce: true});
    });
  }

  async removeUser(user: AuthUser) {
    await remove(ref(this.db, `users/${user.id}`));
    await update(ref(this.db,`tenants/${user.tenant}/users/${user.id}`), {isActive: false, deleted: Date.now(), isAdmin: false});
    await remove(ref(this.db, `tenants/${user.tenant}/admins/${user.id}`)).catch();
  }

  setAdmin(user: AuthUser) {
    if (user.isAdmin) {
      set(ref(this.db, `tenants/${user.tenant}/admins/${user.id}`), true).then().catch(err => {
        console.log(err);
      });
    } else {
      remove(ref(this.db, `tenants/${user.tenant}/admins/${user.id}`)).then().catch(err => {
        console.log(err);
      });
    }

  }
}
