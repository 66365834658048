import { CatalogData } from './catalog-data';

export class MatDevBase implements CatalogData {
  id: string;
  name?: string;
  catalog?: boolean;
  catalogId?: string;
  description?: string;
  category?: number;
  manufacturer?: string;
  manuLink?: string;
  fileNames?: string[];
  fileDescs?: string[];
}
