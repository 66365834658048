import { ModalController, PickerController, PopoverController } from '@ionic/angular';
import { TranslateService } from '@ngx-translate/core';
import { ListUtil } from '../model/controllist';
import { MorebuttonsComponent } from '../morebuttons/morebuttons.component';

export const showMore = async (popoverController: PopoverController, event: Event, hasEdit: boolean) => {
  const pop = await popoverController.create({
    component: MorebuttonsComponent,
    event,
    componentProps: {
      hasDuplicate: true,
      hasEdit
    }
  });
  await pop.present();
  const retVal = await pop.onDidDismiss();
  return retVal.role;
};

export const showModal = async (modalController: ModalController, component, componentProps) => {
  const modal = await modalController.create({
    component,
    componentProps,
    canDismiss: true
  });
  await modal.present();
  return await modal.onDidDismiss();
};

export const selectMaintenanceInterval = async (pickerCtrl: PickerController, translate: TranslateService,
  interval, amount, withZero = false) => {
  const numbers = [...Array(32).keys(), 36, 48, 50, 52, 60, 72, 84, 96, 120].map(n => ({text: n.toString(), value: n}));
  if (!withZero) {
    numbers.shift();
  }
  const intopts = [...ListUtil.MAINT_INTERVAL[translate.currentLang]].map((text, index) => ({text, value: index + 1}));
  const lengthIndex = numbers.findIndex(n => n.value === amount);
  const pick = await pickerCtrl.create({
    columns: [
      {name: 'interval', options: intopts, selectedIndex: interval - 1},
      {name: 'length', options: numbers, selectedIndex: lengthIndex}
    ],
    buttons: [
      {text: translate.instant('Cancel'), role: 'cancel'},
      {text: 'OK', role: 'set'}
    ]
  });
  await pick.present();
  const result = await pick.onDidDismiss();
  if (result.role === 'set') {
    return result.data as { length: { text: string; value: number }; interval: { text: string; value: number } };
  } else {
    return null;
  }
};
