import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  SimpleChanges
} from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { ControlList, ListUtil } from '../../model/controllist';
import { DeviceCatalogService, MaterialCatalogService } from '../../services/data.service';
import { DeviceService } from '../../services/device.service';
import { ListsService } from '../../services/lists.service';
import { AlertController, ModalController, PopoverController } from '@ionic/angular';
import { MaterialService } from '../../services/material.service';
import { TenantQssService } from '../../services/tenant-qss.service';
import { ShowAlert } from '../../util/show-alert';
import { ViewComponent } from '../view/view.component';
import { MorebuttonsComponent } from '../../morebuttons/morebuttons.component';
import { EditList } from '../edit-list';
import { Subscription } from 'rxjs';
import { PrintingService } from '../../services/printing.service';
import { AdminListsService } from '../../services/admin-lists.service';
import { CommonUtils } from '../../model/common-utils';

@Component({
  selector: 'app-list',
  templateUrl: './list.component.html',
  styleUrls: ['./list.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ListComponent implements OnInit, OnDestroy, OnChanges {
  static SORT_ORDER = 1;
  static ICON_MAP = new Map<string, string>([
    ['maintenance', 'build'],
    ['open', 'lock-open-outline'],
    ['close', 'lock-closed'],
    ['request', 'sunny'],
    ['steril', 'water'],
    ['daily', 'repeat'],
    ['task', 'checkmark-circle']
  ]);
  static ORDER_OF_TYPE = new Map<string, string>([
    ['request', 'A'],
    ['steril', 'B'],
    ['maintenance', 'C'],
    ['task', 'D'],
    ['daily', 'E'],
    ['open', 'F'],
    ['close', 'G']
  ]);

  @Input() isAdmin = false;
  @Input() fromAdminInterface = false;
  @Input() tenant: string;
  @Input() tenantName: string;
  @Input() isCatalog = false;
  @Input() filterName = '';
  @Input() filter: {[key: string]: boolean} = {
    maintenance: true,
    request: true,
    steril: true,
    open: true,
    close: true,
    daily: true,
    task: true
  };
  @Input() editList;

  lists: ControlList[];
  private _sub: Subscription;
  private _sub2: Subscription;
  private _sub3: Subscription;
  private _editList: EditList;
  private _catalog = false;
  private _alert: ShowAlert;
  private _fullList: ControlList[];

  constructor(public translate: TranslateService,
    private listsService: ListsService,
    private adminListService: AdminListsService,
    private alertCtrl: AlertController,
    private modalCtrl: ModalController,
    private popoverController: PopoverController,
    private printService: PrintingService,
    private deviceService: DeviceService,
    private materialService: MaterialService,
    private deviceCatService: DeviceCatalogService,
    private matCatService: MaterialCatalogService,
    private qssService: TenantQssService,
    private ref: ChangeDetectorRef) {
    this._editList = new EditList(modalCtrl, listsService, deviceService, adminListService);
    this._alert = new ShowAlert(alertCtrl, translate);
  }

  private static SortByLastExecUser(a: ControlList, b: ControlList) {
    if (!!a.executions && !!b.executions) {
      let retval = ListComponent.SORT_ORDER * a.executions[0].user.localeCompare(b.executions[0].user);
      if (retval === 0) {
        retval = ListComponent.SortByLastExecTime(a, b);
      }
      return retval;
    } else if (!!a.executions) {
      return -100 * ListComponent.SORT_ORDER;
    } else {
      return 100 * ListComponent.SORT_ORDER;
    }
  }

  private static SortByLastExecTime(a: ControlList, b: ControlList) {
    if (!!a.last && !!b.last) {
      const lastA = Object.keys(a.last).sort().reverse()[0];
      const lastB = Object.keys(b.last).sort().reverse()[0];
      return ListComponent.SORT_ORDER * lastB.localeCompare(lastA);
    } else if (!!a.last) {
      return -100 * ListComponent.SORT_ORDER;
    }
    return 100 * ListComponent.SORT_ORDER;
  }

  ngOnInit() {
    if (this.fromAdminInterface) {
      this.initAdmin();
    } else {
      this.initUser();
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (this.fromAdminInterface) {
      if (!!changes.tenant?.currentValue) { // && this.tenant !== changes.tenant.currentValue) {
        this.tenant = changes.tenant.currentValue;
        this._sub?.unsubscribe();
        this.initAdmin();
      }
      if (!!changes.tenantName?.currentValue) {
        this.tenantName = changes.tenantName.currentValue;
      }
    } else {
      if (!!changes.filterName || !!changes.filter) {
        this.setFilteredList();
      }
      if (!!changes.editList) {
        if (this.editList !== '-') {
          const list = this.lists?.find(l => l.id === this.editList);
          if (!!list) {
            this.edit(list);
          }
        }
      }
    }
  }

  ngOnDestroy() {
    this._sub?.unsubscribe();
    this._sub2?.unsubscribe();
    this._sub3?.unsubscribe();
  }

  setFilteredList() {
    if (!!this.filterName) {
      this.setLists(this.filterList()?.filter(item => item.name.toLowerCase().indexOf(this.filterName.toLowerCase()) >= 0));
    } else {
      this.setLists(this.filterList());
    }
  }

  filterList() {
    if (!this.filter) {
      return this._fullList;
    }
    return this._fullList?.filter(item => this.filter[item.type]);
  }

  addList() {
    this._editList.showEditModal({
      list: null,
      tenant: this.tenant,
      lists: this.lists,
      isSuperUser: this.fromAdminInterface
    }).then();
  }

  edit(list: ControlList) {
    this._editList.showEditModal({
      list,
      tenant: this.tenant,
      lists: this.lists,
      isSuperUser: this.fromAdminInterface
    }).then();
  }

  async delete(list: ControlList) {
    const choice = await this._alert.withHeader('Delete list')
    .withMessage('Really delete control list?', list.name)
    .addYesDeleteButton().addNoButton().show();
    if (choice === 'yes') {
      if (this.fromAdminInterface) {
        this.adminListService.removeListBase(list.id);
      } else {
        this.listsService.removeList(list.id);
        if (!!list.deviceID) {
          this.deviceService.removeMaintList(list.deviceID, list.id).then();
        }
        this.qssService.removeControl(list.id);
      }
    }
  }

  view(list: ControlList) {
    this.modalCtrl.create({
      component: ViewComponent,
      canDismiss: true,
      componentProps: {tenant: this.tenant, tenantName: this.tenantName, list, isAdmin: this.isAdmin, lists: this.lists}
    }).then(el => {
      el.present().then();
      return el.onDidDismiss();
    }).then(res => {
      if (res.role === 'edit') {
        this.edit(list);
      }
    });
  }

  more(l: ControlList, ev) {
    this.popoverController.create({
      component: MorebuttonsComponent,
      event: ev
    }).then(popoverElement => {
      popoverElement.present().then();
      return popoverElement.onDidDismiss();
    }).then(retVal => {
      if (retVal.role === 'edit') {
        this.edit(l);
      } else if (retVal.role === 'delete') {
        this.delete(l);
      } else if (retVal.role === 'duplicate') {
        this.duplicate(l);
      }
    });
  }

  sortlist(n: number) {
    if (n !== Math.abs(ListComponent.SORT_ORDER)) {
      ListComponent.SORT_ORDER = n;
    } else {
      ListComponent.SORT_ORDER *= -1;
    }
    switch (n) {
      case 1:
        this.lists.sort((a, b) => ListComponent.SORT_ORDER * a.name?.localeCompare(b.name));
        break;
      case 2: // 'this' is used here for the translation... so it cannot go into a function
        // eslint-disable-next-line max-len
        this.lists.sort((a, b) => this.translate.instant('CONTROLLIST.' + a.type)
        .localeCompare(this.translate.instant('CONTROLLIST.' + b.type)) * ListComponent.SORT_ORDER);
        break;
      case 3:
        this.lists.sort(ListComponent.SortByLastExecTime);
        break;
      case 4:
        this.lists.sort(ListComponent.SortByLastExecUser);
        break;
    }
    this.lists.sort((a, b) => (b.alert ? 1 : 0) - (a.alert ? 1 : 0));
  }

  iconType(type: string) {
    return ListComponent.ICON_MAP.get(type);
  }

  async printPage() {
    this.printService.print.start();
    this.printService.print.material = this._editList.materials;
    this.printService.print.devices = this._editList.devices;
    await this.printService.setHeaderFooter(this.tenantName);
    this.printService.print.addHeading(this.translate.instant('Control-lists'), 1, false);
    await this.printService.printLists(this.lists);
    this.printService.print.print();
  }

  async addToCatalog(list: ControlList) {
    if (await this.adminListService.hasList(list.name)) {
      this._alert.withHeader('List Exists')
      .withMessage('List already exists in the Catalog. Add anyway?', list.name);
    } else {
      this._alert.withHeader('New List')
      .withMessage('Add List to Catalog?', list.name);
    }
    const choice = await this._alert.addYesButton().addNoButton().show();
    if (choice === 'yes') {
      const ret = await this.adminListService.addListToCatalog(list, this.tenant);
      if (ret.removeDevice) {
        await this._alert.withHeader('Corrupted Data!').withMessage('ErrorTextWrongDevice').addOkButton().show();
      }
      for (const name of ret.addMaterialNames) {
        await this._alert.withHeader('Substance added')
        .withMessage('Substance referenced by list added from catalog', name)
        .addOkButton().show();
      }
      for (const name of ret.addDeviceNames) {
        await this._alert.withHeader('Device added').withMessage('Device referenced by list added from catalog', name)
        .addOkButton().show();
      }
    }
  }

  async addFromCatalog(list: ControlList) {
    await this.listsService.addListFromCatalogWithInteractions(list);
  }

  private initAdmin() {
    if (this.tenant !== '--') {
      this.adminListService.setTenant(this.tenant);
    } else {
      this.adminListService.setCatalog();
    }
    this._sub = this.adminListService.lists.subscribe(lists => {
      if (!!this.filterName) {
        lists = lists.filter(item => item.name.toLowerCase().indexOf(this.filterName.toLowerCase()) >= 0);
      }
      this.setLists(lists);
    });
    this._sub.add(this.deviceCatService.data.subscribe(data => {
      if (!!data) {
        this._editList.devices = data;
      }
    }));
    this._sub.add(this.matCatService.data.subscribe(data => {
      if (!!data) {
        this._editList.materials = data;
      }
    }));
  }

  private setLists(lists: ControlList[]) {
    this.lists = lists;
    if (!!this.lists) {
      this.lists.sort((a, b) => ListComponent.SORT_ORDER * a.name?.localeCompare(b.name));
      this.lists.sort((a, b) => (b.alert ? 1 : 0) - (a.alert ? 1 : 0));
      this.ref.markForCheck();
      this.ref.detectChanges();
    }
  }

  private initUser() {
    this._catalog = this.tenant === '--';
    if (this._catalog) {
      this.listsService.setCatalog();
    } else {
      this.listsService.setRegular();
    }
    this._sub = this.listsService.lists.subscribe(lists => {
      if (!!lists && lists.length > 0) {
        this._fullList = lists;
        this.setFilteredList();
      }
    });
    this._sub.add(this.deviceService.data.subscribe((data) => {
      if (!!data) {
        this._editList.devices = data;
      }
    }));
    this._sub.add(this.materialService.data.subscribe((data) => {
      if (!!data) {
        this._editList.materials = data;
      }
    }));
  }

  private duplicate(l: ControlList) {
    const newList = ListUtil.DeepCopy(l);
    newList.name = CommonUtils.CountOnExistingName(newList.name, this.lists.map(x => x.name));
    delete newList.last;
    delete newList.id;
    if (this.fromAdminInterface) {
      this.adminListService.addList(newList).then();
    } else {
      this.listsService.addList(newList).then();
    }
  }
}
