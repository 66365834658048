import { ChangeDetectorRef, Component, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import {
  AlertController, IonModal,
  IonPopover, ItemReorderEventDetail,
  LoadingController,
  ModalController,
  PickerController,
  PopoverController
} from '@ionic/angular';
import ShortUid from 'short-uuid';
import { ControlList, ListItem, ListUtil } from '../../model/controllist';
import { Device } from '../../model/device';
import { Material } from '../../model/material';
import { FileUploadService } from '../../services/file-upload.service';
import { selectMaintenanceInterval } from '../../util/viewutil';
import { NewItemComponent } from '../newitem/newitem.component';
import { TranslateService } from '@ngx-translate/core';
import { Subscription } from 'rxjs';
import { DateUtil } from '../../util/dateutil';
import { TimeSelectorComponent } from '../time-selector/time-selector.component';
import { addDays, addMinutes, addYears, format, parseISO } from 'date-fns';
import { DateSelectorComponent } from '../../date-selector/date-selector.component';

class DaySelector {
  static INIT_DATE = new Date(1970, 0, 1, 9, 0);
  static OFFSET = DaySelector.INIT_DATE.getTimezoneOffset();
  days: Array<{ name: string; day: boolean }>;
  times: Array<number>;
  three = [0, 1, 2];
  four = [3, 4, 5, 6];
  timeStrings: Array<string>;

  constructor(private translate: TranslateService, private ref: ChangeDetectorRef, prevDays: number[], prevTimes: number[]) {
    this.days = Array.from({length: 7}, (v, i) =>
      ({name: DateUtil.WEEKDAYS[translate.currentLang][i], day: !!prevDays ? prevDays.includes(i) : i < 5}));
    this.times = !!prevTimes ? [...prevTimes] : [];
    this.timeStrings = this.times.map(d => new Date(d).toISOString());
  }

  add() {
    const d = new Date(DaySelector.INIT_DATE);
    if (this.times.length > 0) {
      const lastTime = new Date(this.times[this.times.length - 1]);
      d.setHours(lastTime.getHours(), lastTime.getMinutes());
    }
    this.times.push(d.getTime());
    this.timeStrings.push(d.toISOString());
    this.ref.markForCheck();
  }

  remove(i: number) {
    this.times.splice(i, 1);
    this.timeStrings.splice(i, 1);
    this.ref.markForCheck();
  }

  update() {
    this.times = this.timeStrings.map(t => parseISO(t).getTime());
  }

  valid() {
    return !!this.days.find(d => d.day === true) && this.timeStrings.length > 0;
  }

  updateItem(data: string, i: number) {
    this.times[i] = parseISO(data).getTime(); // parseISO(data.substring(0, data.indexOf('+'))+'Z').getTime();
    this.timeStrings[i] = new Date(this.times[i]).toISOString();
  }
}

@Component({
  selector: 'app-newlist',
  templateUrl: './newlist.component.html',
  styleUrls: ['./newlist.component.scss']
})
export class NewListComponent implements OnInit, OnDestroy {
  @Input() list: ControlList = null;
  @Input() lists: ControlList[] = [];
  @Input() tenant: string;
  @Input() deviceID: string = null;
  @Input() deviceName: string = null;
  @Input() materials: Material[];
  @Input() devices: Device[];
  @Input() devs: Device[];
  @ViewChild('popDate') popDate: IonPopover;
  @ViewChild('listSelect') listModal: IonModal;
  newList: ControlList;
  editing = false;
  fixedDevice = false;
  maintenanceButtonText: string;
  nextMaintenance: Date;
  nextMaintenanceStr: string;
  emptyMaintenance: boolean;
  today: string;
  maxDate: string;
  sub: Subscription;
  sub2: Subscription;
  daySelect: DaySelector;
  test: string;
  listSelection = new Map<string, boolean>();
  nextListInvalid = false;

  constructor(
    private modalCtrl: ModalController,
    private pickerCtrl: PickerController,
    private popoverCtrl: PopoverController,
    public translate: TranslateService,
    private alertCtrl: AlertController,
    private loadingCtrl: LoadingController,
    private fileUploadService: FileUploadService,
    private ref: ChangeDetectorRef) {}

  ngOnInit() {
    if (this.list) {
      // copy original, this is cumbersome. But because
      // we use the two-way binding, and esp chrome only keeps string pointers,
      // we would end up changing the original incoming list as well even on abort (cancel)
      // without this deep copy.
      this.newList = ListUtil.DeepCopy(this.list);
      this.editing = true;
      if (this.validMaintenance()) {
        this.maintenanceButtonText =
          `${ this.newList.maint.toString() } ${ ListUtil.MAINT_INTERVAL[this.translate.currentLang][this.newList.maintInterval - 1] }`;
        this.nextMaintenance = new Date(!!this.newList.nextMaintenance ? this.newList.nextMaintenance : Date.now());
        this.nextMaintenanceStr = this.nextMaintenance.toISOString();
        this.emptyMaintenance = false;
        this.setMinMaxDate();
      } else {
        this.initMaintenance();
      }
      this.checkMaterialsAndDevices();
    } else {
      this.newList = new ControlList();
      this.newList.items = [];
      this.newList.type = 'request';
      this.newList.alert = false;
      this.newList.alertNextList = false;
      this.newList.sequential = false;
      this.initMaintenance();
      if (!!this.deviceID) {
        this.newList.type = 'maintenance';
        this.newList.deviceID = this.deviceID.repeat(1);
        this.newList.deviceName = this.deviceName.repeat(1);
        this.fixedDevice = true;
      }
    }
    this.lists?.forEach(l => {
      this.listSelection.set(l.id, false);
    });
    if (!!this.newList.alertNextList && !!this.newList.nextList) {
      this.listSelection.set(this.newList.nextList, true);
    }
    this.daySelect = new DaySelector(this.translate, this.ref, this.newList.dailyWeekdays, this.newList.dailyTimes);
    this.test = new Date(0, 0, 0, 9, 0).toISOString();

    const noData = new Device();
    noData.name = this.translate.instant('(No Device)');
    noData.id = null;
    this.devices = [noData, ...this.devs];
  }

  public ngOnDestroy() {
    if (this.sub) {
      this.sub.unsubscribe();
    }
    if (this.sub2) {
      this.sub2.unsubscribe();
    }
  }

  onCancel() {
    this.modalCtrl.dismiss(null, 'cancel').then();
  }

  onAddList() {
    if (!!this.newList.alertNextList && !this.newList.nextList) {
      this.nextListInvalid = true;
      return;
    }
    if (this.newList.type === 'maintenance') {
      this.newList.nextMaintenance = new Date(this.nextMaintenanceStr).getTime();
      if (!!this.newList.deviceID) {
        this.newList.deviceName = this.devices.find(d => d.id === this.newList.deviceID).name;
      } else {
        this.newList.deviceName = null;
      }
    } else if (this.newList.type === 'steril') {
      if (!this.checkZoneOrder()) {
        this.zoneAlert();
        return;
      }
      this.newList.sequential = true; // force sequential on steri lists.
    } else if (this.newList.type === 'daily') {
      this.daySelect.update();
      this.newList.dailyTimes = this.daySelect.times;
      this.newList.dailyWeekdays = this.daySelect.days.map((d, i) =>
        ({i, day: d.day})).filter(d => d.day).map(d => d.i);
    }
    this.uploadInfoPics().then(() => {
      this.modalCtrl.dismiss(this.newList, this.editing ? 'edit' : 'save').then();
    });
  }

  valid() {
    return (this.newList.name && this.newList.name.length > 0) && this.newList.description &&
      (this.newList.type && this.newList.type.length > 0) && !!this.newList.items && this.newList.items.length > 0 &&
      (this.newList.type !== 'maintenance' || this.validMaintenance()) && (this.newList.type !== 'daily' || this.daySelect.valid());
  }

  validMaintenance() {
    return this.newList.type === 'maintenance' && !!this.newList.maint && !!this.newList.maintInterval;
  }

  showItemModal(item, index = -1) {
    const itemNames = this.newList.items.map(i => i.name);
    const componentProps = {
      item,
      ltype: this.newList.type,
      prevZone: 0,
      itemNames,
      currentIndex: index,
      materials: this.materials,
      devices: this.devs
    };
    if (this.newList.type === 'steril') {
      if (!!item && index > 0) {
        componentProps.prevZone = this.newList.items[index - 1].zone;
      } else if (!item && this.newList.items.length > 0) {
        componentProps.prevZone = this.newList.items[this.newList.items.length - 1].zone;
      }
    }
    this.modalCtrl.create({
      component: NewItemComponent,
      canDismiss: true,
      componentProps
    }).then(el => {
      el.present().then();
      return el.onDidDismiss();
    }).then(result => {
      const toSave = result.data as ListItem;
      if (result.role === 'save') {
        this.newList.items.push(toSave);
      } else if (result.role === 'edit') {
        this.newList.items[index] = toSave;
      } else if (result.role === 'delete') {
        this.newList.items.splice(index, 1);
      }
    })
    .catch(err => {
      console.log('ERROR', err);
    });
  }

  newItem() {
    this.showItemModal(null);
  }

  editItem(i: number) {
    this.showItemModal(this.newList.items[i], i);
  }

  timedText(timed: number) {
    return ListUtil.TimedText(timed);
  }


  getDeviceName(id: string, mainDevice: boolean) {
    if (!!id) {
      const item = this.devices?.find(d => d.id === id);
      return item ? item.name : this.translate.instant('(No Device)');
    } else {
      if (!!this.devices && mainDevice) {
        return this.devices[0].name;
      } else {
        return this.translate.instant('(No Device)');
      }
    }
  }

  async uploadInfoPics() {
    for (const f of this.newList.items) {
      if (!!f.infoPicFile) {
        f.infoPicLink = await this.handleUploadFile(f.infoPicFile);
        delete f.infoPicFile;
        if (!f.infoPicLink) {  // if the link is empty, there was some problem.
          delete f.infoPicLink;
          delete f.infoPic;
        }
      }
    }
    return Promise.resolve();
  }

  async showUploadSpinner(fileName: string) {
    const el = await this.loadingCtrl.create({
      message: this.translate.instant('Uploading File ') + fileName
    });
    await el.present();
    return Promise.resolve(el);
  }

  async handleUploadFile(file: File): Promise<string> {
    const el = await this.showUploadSpinner(file.name);
    let url = '';

    const fileName = this.getUploadFileName(file.name);
    try {
      url = await this.doUpload(file, fileName);
      await el.dismiss();
    } catch (err) {
      await el.dismiss();
      const alert = await this.alertCtrl.create({
        header: 'Error',
        message: err.message,
        buttons: [{text: 'OK', role: 'cancel'}]
      });
      await alert.present();
      await alert.onDidDismiss();
    }
    return Promise.resolve(url);
  }

  async doUpload(file: File, fileName: string): Promise<string> {
    return this.fileUploadService.uploadFile(this, file, fileName, this.progressUpload);
  }

  progressUpload(obj: any, progress: number) {
    console.log(progress);
  }

  selectMaintInterval() {
    selectMaintenanceInterval(this.pickerCtrl, this.translate,
      this.newList.maintInterval, this.newList.maint).then(result => {
      if (result) {
        this.maintenanceButtonText = `${ result.length.text } ${ result.interval.text }`;
        this.newList.maint = result.length.value;
        this.newList.maintInterval = result.interval.value;
        this.emptyMaintenance = false;
      }
    });
  }

  selectTime($event: MouseEvent, time: string, index: number) {
    const d = addMinutes(parseISO(time), -1 * DaySelector.OFFSET); // we need this as ion-datetime works on local time,
                                                                   // not UTC
    this.popoverCtrl.create({
      component: TimeSelectorComponent,
      componentProps: {
        time: d.toISOString()
      },
      backdropDismiss: true,
      event: $event
    }).then(pop => {
      pop.present().then();
      return pop.onDidDismiss();
    }).then(result => {
      if (result.role === 'ok') {
        this.daySelect.updateItem(result.data, index);
      }
    });
  }

  getTime(t: string) {
    return format(parseISO(t), 'HH:mm');
  }

  toDate() {
    return format(new Date(this.nextMaintenanceStr), 'do MMM yyyy');
  }

  selectNextMaintenance(event: MouseEvent) {
    this.popoverCtrl.create({
      component: DateSelectorComponent,
      componentProps: {
        date: this.nextMaintenanceStr,
        min: this.today,
        max: this.maxDate
      },
      event,
      backdropDismiss: true
    }).then(pop => {
      pop.present().then();
      return pop.onDidDismiss();
    }).then(result => {
      if (result.role === 'ok') {
        this.nextMaintenanceStr = result.data;
      }
    });
  }

  typeChange() {
    if (this.newList.type === 'steril') {
      if (this.newList.items.length > 0 && !this.newList.items[0].zone) {
        for (const item of this.newList.items) {
          item.zone = 1;
        }
      }
    } else {
      if (this.newList.items.length > 0 && !!this.newList.items[0].zone) {
        for (const item of this.newList.items) {
          delete item.zone;
        }
      }
    }
  }

  cancelListSelection = () => this.listModal.dismiss();

  setListSelection() {
    delete this.newList.nextList;
    for (const id of this.listSelection.keys()) {
      if (this.listSelection.get(id)) {
        this.newList.nextList = id;
        this.nextListInvalid = false;
      }
    }
    this.cancelListSelection().then();
  }

  listName = (nextList: string) => this.lists.find(l => l.id === nextList)?.name;

  orderSteps(ev: CustomEvent<ItemReorderEventDetail>) {
    ev.detail.complete();
    [this.newList.items[ev.detail.to], this.newList.items[ev.detail.from]] =
      [this.newList.items[ev.detail.from], this.newList.items[ev.detail.to]];
  }

  getMatName = (matID: string) => this.materials.find(m => m.id === matID)?.name;

  devicesForSelection = () => this.devices.map(d => ({name: d.name, id: d.id}));

  setDevice($event: string | string[]) {
    this.newList.deviceID = $event as string;
  }

  private initMaintenance() {
    this.maintenanceButtonText = this.translate.instant('Set Maintenance Interval');
    this.emptyMaintenance = true;
    this.newList.maint = 0;
    this.newList.maintInterval = 1;
    this.newList.changeMaintenance = true;
    this.nextMaintenance = new Date();
    this.newList.nextMaintenance = this.nextMaintenance.getTime();
    this.nextMaintenanceStr = this.nextMaintenance.toISOString();
    this.setMinMaxDate();
  }

  private setMinMaxDate() {
    const now = new Date();
    this.today = `${ addDays(now, 1).toISOString() }`;
    this.maxDate = `${ addYears(now, 20).toISOString() }`;
  }

  private zoneAlert() {
    this.alertCtrl.create({
      header: this.translate.instant('Bad Zone Order'),
      message: this.translate.instant('ZoneOrder'),
      buttons: [{text: 'OK', role: 'cancel'}]
    }).then(el => {
      el.present().then();
    });
  }

  private checkZoneOrder(): boolean {
    let zone = -1;
    let retval = true;
    this.newList.items.forEach(item => {
      if (item.zone >= zone) {
        zone = item.zone;
      } else {
        retval = false;
      }
    });
    return retval;
  }

  private getUploadFileName(name: string) {
    const random = ShortUid.generate();
    const pathName = this.newList.name.replace(/\//g,'_');
    return `${ this.tenant }/listPics/${ pathName }/${ random }_${ name }`;
  }

  private checkMaterialsAndDevices() {
    this.newList.items.forEach(step => {
      if (!!step.matID) {
        if (!this.materials.find(m => m.id === step.matID)) {
          delete step.matID;
          delete step.amount;
          delete step.unit;
        }
      }
      if (!!step.devID) {
        if (!this.devs.find(d => d.id === step.devID)) {
          delete step.devID;
          delete step.program;
        }
      }
    });
  }
}
