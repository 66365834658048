import { Injectable } from '@angular/core';
import { Doc } from '../model/document';
import { FirebaseApp } from '@angular/fire/app';
import { getFunctions, Functions, httpsCallable } from '@angular/fire/functions';
import { deleteObject, getDownloadURL, ref, Storage, uploadString } from '@angular/fire/storage';

@Injectable({
  providedIn: 'root'
})
export class FileShowService {
  doc: Doc;
  newDoc: boolean;
  version = 0;
  empty = true;
  duplicatePath: string;
  private readonly _afs: Functions;

  constructor(private firebaseApp: FirebaseApp, private fbStorage: Storage) {
    this._afs = getFunctions(this.firebaseApp, 'europe-west6');
  }

  getUrl(path: string): Promise<string> {
    return httpsCallable(this._afs, 'getDocument')({path})
      .then(data => (data.data as any).url[0]);
  }

  async getHtml(path: string) {
    const url = await getDownloadURL(ref(this.fbStorage, path));
    const result = await fetch(url);
    return result.text();
  }

  delete(path: string) {
    deleteObject(ref(this.fbStorage, path)).then();
  }

  save(path: string, html: string): Promise<number> {
    return uploadString(ref(this.fbStorage, path), html).then(result => result.metadata.size);
  }

  async getTenantsUsingCatalogItem(id: string, type: string) {
    let data;
    try {
      data = await httpsCallable(this._afs, 'getTenantsUsingCatalogItem')({id, type});
    } catch (err) {
      console.log('get tenants using failed', type, 'id', id);
      console.log(err);
    }
    return data.data;
  }

  sendFeedback(feedback: string, email: string, tenant: string): Promise<string> {
    return httpsCallable(this._afs, 'sendFeedback')({feedback, email, tenant})
    .then();
  }
}
