import { Injectable } from '@angular/core';
import { Database } from '@angular/fire/database';
import { AuthService, AuthUser } from '@medmonitor/authlib';
import { format } from 'date-fns';
import { of } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';
import { Stat, TotalStat } from '../model/stat';
import { Lead0 } from '../util/dateutil';
import { TenantUserService } from './tenant-user.service';
import { UserDataService } from './user-data.service';

const LabelDash = (one: number, two: number) => `${ one }-${ Lead0(two) }`;
const LabelDash3 = (one: number, two: number, three: number) => `${ one }-${ Lead0(two) }-${ Lead0(three) }`;

@Injectable({
  providedIn: 'root'
})
export class UserStatsService extends UserDataService<Stat> {
  public max: { days: string; months: string; weeks: string; years: string};
  public min: { days: string; months: string; weeks: string; years: string};

  private _users: AuthUser[];

  constructor(protected db: Database,
    protected authService: AuthService,
    private usersService: TenantUserService) {
    super(db, authService, 'userstats', true);
    this.usersService.data.subscribe(users => {
      if (!!users && users.length > 0) {
        this._users = users; //.filter(u => u.isActive && !u.deleted);
        this.processList();
      }
    });
  }

  public get totals() {
    return this.data.pipe(map(data => {
      const result: TotalStat[] = [];
      data.forEach(stat => {
        const totalStat = new TotalStat();
        totalStat.id = stat.id;
        totalStat.name = stat.name;
        totalStat.days = {};
        totalStat.months = {};
        totalStat.weeks = {};
        totalStat.years = {};
        Object.keys(stat.days).forEach(day => {
          totalStat.days[day] = this.getTotal(stat.days[day]);
        });
        Object.keys(stat.months).forEach(month => {
          totalStat.months[month] = this.getTotal(stat.months[month]);
        });
        Object.keys(stat.weeks).forEach(week => {
          totalStat.weeks[week] = this.getTotal(stat.weeks[week]);
        });
        Object.keys(stat.years).forEach(year => {
          totalStat.years[year] = this.getTotal(stat.years[year]);
        });
        result.push(totalStat);
      });
      return result;
    }));
  }

  public get currentUserMonthTotal() {
    return this.totals.pipe(
      switchMap(stats => {
        let total = 0;
        if (stats) {
          const d = new Date();
          const months = stats.find(s => s.id === this._user.id)?.months;
          if (!!months) {
            total = months[`${LabelDash(d.getFullYear(), d.getMonth() + 1)}`] ?? 0;
          }
        }
        return of(total);
      })
    );
  }

  protected processList() {
    const today = new Date();
    const old = new Date(2000, 1, 1);
    this.min = {
      days: format(today, 'yyyy-MM-dd'),
      months: format(today, 'yyyy-MM'),
      weeks: format(today, 'yyyy-ww'),
      years: format(today, 'yyyy')
    };
    this.max = {
      days: format(old, 'yyyy-MM-dd'),
      months: format(old, 'yyyy-MM'),
      weeks: format(old, 'yyyy-ww'),
      years: format(old, 'yyyy')
    };
    this._theList.forEach(stat => {
      this.adjustItem(stat);
    });
  }

  protected processItem(index: number, obj: Stat) {
    this.adjustItem(obj);
    super.processItem(index, obj);
  }

  private getTotal(obj: {[p: string]: number}) {
    let total = 0;
    Object.keys(obj).forEach(list => {
      total += obj[list];
    });
    return total;
  }

  private adjustItem(stat: Stat) {
    const name = this._users?.find(u => u.id === stat.id)?.name;
    if (!!name) {
      stat.name = name;
      Object.keys(stat.days).forEach(day => {
        const val = stat.days[day];
        delete stat.days[day];
        const [y, m, d] = day.split('-');
        const key = LabelDash3(+y, +m, +d);
        stat.days[key] = val;
        this.min.days = key < this.min.days ? key : this.min.days;
        this.max.days = key > this.max.days ? key : this.max.days;
      });
      Object.keys(stat.months).forEach(month => {
        const val = stat.months[month];
        delete stat.months [month];
        const [y, m] = month.split('-');
        const key = LabelDash(+y, +m);
        stat.months[key] = val;
        this.min.months = key < this.min.months ? key : this.min.months;
        this.max.months = key > this.max.months ? key : this.max.months;
      });
      Object.keys(stat.weeks).forEach(week => {
        const val = stat.weeks[week];
        delete stat.weeks[week];
        const [y, w] = week.split('-');
        const key = LabelDash(+y, +w);
        stat.weeks[key] = val;
        this.min.weeks = key < this.min.weeks ? key : this.min.weeks;
        this.max.weeks = key > this.max.weeks ? key : this.max.weeks;
      });
      Object.keys(stat.years).forEach(year => {
        this.min.years = year < this.min.years ? year : this.min.years;
        this.max.years = year > this.max.years ? year : this.max.years;
      });
    }
  }
}
