import { MatDevBase } from './mat-dev-base';

export class Device extends MatDevBase {
  program?: string[];
  deviceInfo?: DeviceMetadata;
  maintLists?: string[];
  maintListNames?: string[];
}

export class DeviceMetadata {
  acquiredYear: number;
  serialNumber: string;
  maintContract: boolean;
  maintCompany: string;
  maintContact: string;
}
