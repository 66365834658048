import { Component, Input, OnInit } from '@angular/core';
import { CategoryData } from '../model/category-data';
import { AlertController, ModalController } from '@ionic/angular';
import { CommonUtils } from '../model/common-utils';

@Component({
  selector: 'app-category-new',
  templateUrl: './category-new.component.html',
  styleUrls: ['./category-new.component.scss']
})
export class CategoryNewComponent implements OnInit {
  @Input() item: CategoryData;
  @Input() allItems: CategoryData[] = null;
  role: string;
  newitem: CategoryData;

  constructor(private modalCtrl: ModalController,
    private alertCtrl: AlertController) { }

  ngOnInit() {
    if (!!this.item) {
      this.role = 'edit';
      this.newitem = CommonUtils.DeepCopyCategoryData(this.item);
    } else {
      this.role = 'save';
      this.newitem = new CategoryData();
      this.newitem.category = this.allItems.reduce((a, b) => a.category > b.category ? a : b).category + 1;
      this.newitem.order = this.allItems.reduce((a, b) => a.order > b.order ? a : b).order + 1;
    }
  }

  onCancel() {
    this.modalCtrl.dismiss(null, 'cancel').then();
  }

  onAddItem() {
    this.modalCtrl.dismiss(this.newitem, this.role).then();
  }

  onDeleteItem() {
    this.alertCtrl.create({
      header: 'Kategorie löschen',
      message: 'Kategorie ' + this.newitem.name +
        ' löschen? Alle existierenden Elemente in dieser Kategorie werden der Default Kategorie zugewiesen',
      buttons: [
        {text: 'Abbrechen', role: 'cancel'},
        {
          text: 'Löschen!', cssClass: 'btnalert', handler: () => {
            this.modalCtrl.dismiss(this.newitem, 'delete').then();
          }
        }
      ]
    }).then(alertEl => alertEl.present()).then();
  }

  isValid() {
    return !!this.newitem.name && !!this.newitem.de && !!this.newitem.en;
  }
}
